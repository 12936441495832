import React, { useState } from 'react';
import { AllowedRoles, hasAccess } from 'lib/security';
import useUser from 'hooks/useUser';
import { changeTabsContext, tabsContext } from './tabsContext';
import defaultStyles from './tabs.module.css';
import Button from './button';
import restyle, { TStylesheet } from '../../restyler';

interface iProps {
  children: React.ReactNode;
  config: TabConfig[];
  styles?: TStylesheet;
  defaultTab: string;
}

export interface TabConfig {
  id: string;
  title: string;
  roles: AllowedRoles;
}

const Tabs: React.FC<iProps> = (props) => {
  const { children, config, defaultTab, styles: overriddenStyles } = props;
  const styles = restyle(defaultStyles, overriddenStyles);
  const [state, setState] = useState(defaultTab);
  const { role } = useUser();
  return (
    <tabsContext.Provider value={state}>
      <changeTabsContext.Provider value={setState}>
        <div className={styles.header} role="tablist">
          {config
            .filter(({ roles }) => hasAccess(roles, role))
            .map(({ title, id }) => (
              <Button
                aria-selected={state === id}
                key={title}
                title={title}
                id={id}
                role="tab"
                aria-controls={id}
              />
            ))}
        </div>
        {children}
      </changeTabsContext.Provider>
    </tabsContext.Provider>
  );
};

export default Tabs;
