import { FormInputProps } from 'components/Forms';
import React, { InputHTMLAttributes } from 'react';
import restyle, { TStylesheet } from 'components/../restyler';
import defaultStyles from './input.module.css';

export interface InputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    FormInputProps {
  label?: string;
  styles?: TStylesheet;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    value = '',
    setField = () => '',
    message = '',
    label = '',
    type = 'text',
    name = '',
    touched = false,
    styles: overriddenStyles,
    ...restInputProps
  } = props;
  const styles = restyle(defaultStyles, overriddenStyles);
  return (
    <div className={styles.root}>
      <label className={styles.label}>
        {label ? <span className={styles.label__span}>{label}</span> : null}
        <input
          {...restInputProps}
          className={styles.input}
          name={name}
          type={type}
          value={value}
          onChange={(e) => {
            const { value } = e.currentTarget;
            setField(value);
          }}
          data-error={message && touched ? true : undefined}
        />
      </label>
      <span className={styles.message}>{touched && message}</span>
    </div>
  );
};

export default Input;
