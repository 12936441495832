import React from 'react';
import ControlledInput from 'components/Forms/ControlledField';
import Icon from 'components/Icon';
import PopupWrapper, { PopupButton } from 'components/Popups';
import ButtonPrimary from 'components/ButtonPrimary';
import ButtonSecondary from 'components/ButtonSecondary';
import Form from 'components/Forms';
import useCompanies from 'hooks/useCompanies';
import styles from '../companiesList.module.css';

const UserDelete: React.FC<{ id: number; name: string; reload: () => void }> =
  ({ id, name, reload }) => {
    const { deleteCompany } = useCompanies();

    return (
      <>
        <PopupButton
          title="Delete company"
          className={styles.actionButton}
          unique={`popup-delete-user-${id}`}
          open
        >
          <Icon name="trash" attributes={{ width: 30, height: 30 }} />
        </PopupButton>

        <PopupWrapper unique={`popup-delete-user-${id}`}>
          {({ cancelPopup }) => (
            <Form
              data-testid={'deleteCompanyConfirmationForm'}
              onSubmitSuccess={async (formCtx) => {
                const { password } = formCtx.values;
                await deleteCompany(id, password as string);
                formCtx.methods.clearForm();
                cancelPopup();
                reload();
              }}
            >
              <div className="popup__content">
                <div className="popup__info">
                  You are about to delete company:{' '}
                </div>
                <div className="popup__info_stressed">{name}</div>
                <div className="popup__info">To proceed, enter password:</div>
                <ControlledInput
                  name="password"
                  type="password"
                  validate={(val) =>
                    typeof val === 'string' && val.length
                      ? ''
                      : 'Field is required'
                  }
                  {...{
                    placeholder: 'Password',
                  }}
                />
                <div className="popup__actions">
                  <ButtonPrimary type="submit">Proceed</ButtonPrimary>
                  <ButtonSecondary onClick={cancelPopup}>
                    Cancel
                  </ButtonSecondary>
                </div>
              </div>
            </Form>
          )}
        </PopupWrapper>
      </>
    );
  };

export default UserDelete;
