import React, { useState } from 'react';
import ControlledInput from 'components/Forms/ControlledField';
import { IUser } from 'api/accounts/usersGet';
import Icon from 'components/Icon';
import PopupWrapper, { PopupButton } from 'components/Popups';
import ButtonPrimary from 'components/ButtonPrimary';
import ButtonSecondary from 'components/ButtonSecondary';
import useUserEditDelete from 'hooks/useUserEditDelete';
import Form, { TFieldValue } from 'components/Forms';
import EditedRow from 'components/UsersList/UserEdit/editedRow';
import editUserStructure from './userEdit.structure';
import styles from '../usersList.module.css';

const UserEdit: React.FC<IUser & { reload: () => void }> = (props) => {
  const [safetyStage, setSafetyStage] = useState<Record<string, TFieldValue>>();

  const {
    id,
    username,
    email,
    is_superuser,
    company,
    is_active,
    is_staff,
    reload,
  } = props;

  const { editUser } = useUserEditDelete({ userId: id });

  const cancelSafetyStage = () => {
    setSafetyStage(undefined);
  };

  return (
    <>
      <PopupButton
        title="Edit user"
        className={styles.actionButton}
        unique={`popup-edit-user-${id}`}
        open
      >
        <Icon name="edit" attributes={{ width: 30, height: 30 }} />
      </PopupButton>

      <PopupWrapper unique={`popup-edit-user-${id}`}>
        {({ cancelPopup }) => (
          <Form
            data-testid="editUserForm"
            noValidateOnSubmit
            onSubmitSuccess={async (formCtx) => {
              const { touched, values } = formCtx;

              const fieldsToEdit = {} as Record<string, TFieldValue>;
              let atLeastOneFieldEdited = false;
              Object.entries(touched).forEach((touchedEntry) => {
                const [fieldName, isTouched] = touchedEntry;
                if (isTouched) {
                  fieldsToEdit[fieldName] = values[fieldName];
                  atLeastOneFieldEdited = true;
                }
              });

              if (atLeastOneFieldEdited) setSafetyStage(fieldsToEdit);
            }}
          >
            {safetyStage ? (
              <div className="popup__content">
                <div className="popup__info">Fields will be edited:</div>
                <div className="popup__info_stressed">
                  {Object.entries(safetyStage).map(
                    ([fieldName, fieldValue]) => (
                      <EditedRow
                        key={fieldName}
                        fieldName={fieldName}
                        fieldValue={fieldValue}
                      />
                    ),
                  )}
                </div>
                <div className="popup__actions">
                  <ButtonPrimary
                    onClick={async () => {
                      const success = await editUser(safetyStage);
                      if (success) {
                        reload();
                        cancelPopup();
                      }
                    }}
                  >
                    Proceed
                  </ButtonPrimary>
                  <ButtonSecondary onClick={cancelSafetyStage}>
                    Back
                  </ButtonSecondary>
                </div>
              </div>
            ) : (
              <div className="popup__content">
                <div className="popup__info">You are about to edit user: </div>
                <div className="popup__info_stressed">{username}</div>
                {editUserStructure.map((field) => {
                  const existingFields: Record<string, TFieldValue> = {
                    username,
                    email,
                    company,
                    is_active,
                    is_staff,
                    is_superuser,
                  };

                  let initialValue = existingFields[field.name];

                  if (initialValue === null) initialValue = '';

                  return (
                    <ControlledInput
                      key={field.name}
                      initialValue={initialValue}
                      initialTouched={false}
                      {...field}
                    />
                  );
                })}
                <div className="popup__actions">
                  <ButtonPrimary type="submit">Proceed</ButtonPrimary>
                  <ButtonSecondary onClick={cancelPopup}>
                    Cancel
                  </ButtonSecondary>
                </div>
              </div>
            )}
          </Form>
        )}
      </PopupWrapper>
    </>
  );
};

export default UserEdit;
