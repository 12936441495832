import React, { useCallback, useEffect, useState } from 'react';
import { UserStats } from 'api/statistics/statsUsersGet';
import { useFilterableDataList } from 'components/FilteralbeDataList/filteralbeDataList';
import useUsersList from 'hooks/useUsersList';
import StatisticsSummary from 'components/StatisticsSummary';
import MultiselectField from 'components/Forms/StyledMultiselectField';
import { IOption } from 'components/Forms/StyledSelect/input';
import { TOnInputChange } from 'components/Forms';
import styles from './statisticsDashboard.module.css';

const UsersStatistics: React.FC = () => {
  const { data, loading, error } = useFilterableDataList<UserStats[]>();
  const { users, usersLoading } = useUsersList();
  const [selectedUsers, setSelectedUsers] = useState<IOption[]>([]);
  const [usersOptions, setUsersOptions] = useState<IOption[]>([]);
  const [filteredData, setFilteredData] = useState<UserStats[]>([]);

  useEffect(() => {
    const options = users.map(({ username, id }) => ({
      value: id,
      label: username,
    }));
    setUsersOptions(options);
    setSelectedUsers(options);
  }, [users]);

  const setFiled: TOnInputChange = useCallback((value) => {
    const parsedValue = JSON.parse(`${value || '[]'}`) as IOption[];
    setSelectedUsers(parsedValue);
  }, []);

  useEffect(() => {
    const selectedIds = selectedUsers.map(({ value }) => value);
    setFilteredData(
      data?.filter(({ user }) => selectedIds.includes(user)) || [],
    );
  }, [selectedUsers, data]);

  let content;
  if (loading || !filteredData) {
    content = <div className={styles.message}>Loading...</div>;
  } else if (error || !data?.length || !filteredData?.length) {
    content = <div className={styles.message}>No data...</div>;
  } else {
    content = (
      <StatisticsSummary
        data={filteredData}
        users={usersLoading ? undefined : users}
      />
    );
  }

  return (
    <div>
      <div className={styles.users__filters}>
        <MultiselectField
          label="Filter Users"
          value={JSON.stringify(selectedUsers)}
          name="users"
          setField={setFiled}
          options={usersOptions}
        />
      </div>
      {content}
    </div>
  );
};

export default UsersStatistics;
