import userStorage from 'lib/persistentStorage/storage.user';
import { actionUserData, actionUserLogout } from 'reduxStore/actions/user';
import { store } from 'reduxStore/store';
import { getUserDataFromToken } from './tokenDecode';

interface iRefreshToken {
  access: string;
}

function refreshStoredUser(payload: iRefreshToken): boolean {
  const { item } = userStorage.getItem('currentUser');
  const userData = getUserDataFromToken(
    payload.access || '',
    item?.refresh as string,
  );

  if (userData) {
    userStorage.setItem({
      id: 'currentUser',
      ...userData,
    });

    store.dispatch(actionUserData(userData));
    return true;
  }
  userStorage.delItem('currentUser');
  store.dispatch(actionUserLogout());
  return false;
}

export default refreshStoredUser;
