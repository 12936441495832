import { TFieldValue } from 'components/Forms';
import { ControlledInputProps } from 'components/Forms/ControlledField/input';
import styles from './createCompany.module.css';

const inputStyles = {
  root: styles.inputRoot,
  label__span: styles.inputLabel,
};

const createUserStructure: ControlledInputProps[] = [
  {
    name: 'name',
    validate: (str: TFieldValue): string =>
      str && (str as string).length > 3 ? '' : 'Incorrect value.',
    type: 'text',
    label: 'Company name',
    styles: inputStyles,
    placeholder: 'Enter company name',
  },
];

export default createUserStructure;
