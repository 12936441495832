export function calculateResetDate(): [string, string] {
  const prevDate = new Date(new Date().setDate(0));
  const prevMonth = prevDate.getMonth();
  const prevYear = prevDate.getFullYear();

  const currDate = new Date();
  const currMonth = currDate.getMonth();
  const currYear = currDate.getFullYear();
  const currDay = currDate.getDate();

  const formatNum = (strOrNumber: string | number) =>
    `${strOrNumber}`.length === 1 ? `0${strOrNumber}` : strOrNumber;
  const dateFromInputValue = `${prevYear}-${formatNum(prevMonth + 1)}-01`;
  const dateToInputValue = `${currYear}-${formatNum(currMonth + 1)}-${formatNum(
    currDay,
  )}`;

  return [dateFromInputValue, dateToInputValue];
}

const statisticsFormStructure = [
  {
    name: 'start_date',
    initialValue: calculateResetDate()[0],
    type: 'date',
    inputAttributes: {
      class: 'date',
      label: 'Date from',
    },
  },
  {
    name: 'end_date',
    initialValue: calculateResetDate()[1],
    type: 'date',
    inputAttributes: {
      class: 'date',
      label: 'Date to',
    },
  },
  {
    name: 'company',
    type: 'companies_select',
    inputAttributes: {
      class: 'select',
      label: 'Company',
    },
  },
];

export default statisticsFormStructure;
