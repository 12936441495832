import { FormInputProps } from 'components/Forms';
import React, { InputHTMLAttributes } from 'react';
import styles from './simpleCheckbox.module.css';

interface ISimpleCheckbox
  extends InputHTMLAttributes<HTMLInputElement>,
    FormInputProps {
  label?: string;
  title?: string;
}

const SimpleCheckbox: React.FC<ISimpleCheckbox> = (props) => {
  const { label, value, setField, title } = props;
  const className = value ? styles.root_checked : styles.root_unchecked;

  return (
    <div className={`${className} ${styles.root}`}>
      {title ? <div className={styles.title}>{title}</div> : null}
      <label className={styles.wrapper}>
        <input
          checked={!!value}
          type="checkbox"
          className={styles.input}
          onChange={(e) => {
            const { checked } = e.target;
            setField(checked);
          }}
        />
        <div className={styles.switch} />
        <div className={styles.label}>{label}</div>
      </label>
    </div>
  );
};

export default SimpleCheckbox;
