import React from 'react';
import useMassActions from 'hooks/useMassActions';
import ButtonTertiary from 'components/ButtonTertiary';
import packagesSwapPositions from 'api/packages/packagesSwapPositions';
import useForm from 'hooks/useForm';
import useNotices from 'hooks/useNotices';
import { useFilterableDataList } from 'components/FilteralbeDataList/filteralbeDataList';
import { IPackagesData } from 'api/packages/packagesGet';
import styles from './massActions.module.css';

const MassActions: React.FC = () => {
  const { reload } = useFilterableDataList<IPackagesData>();
  const { selected, unselectAll } = useMassActions();
  const { setNotice } = useNotices();
  const { methods } = useForm();
  const empty = selected.length === 0;

  const move = async (direction: 'up' | 'down') => {
    const response = await packagesSwapPositions({
      direction,
      packages: selected,
    });
    if (!response.success) {
      setNotice({
        message: response.message,
        type: 'failure',
      });
      return;
    }
    reload();
  };

  const resetFilters = () => {
    methods.setManyFields([
      { name: 'id', value: '' },
      { name: 'name', value: '' },
      { name: 'status', value: '' },
    ]);
  };

  return (
    <div className={styles.root}>
      <span>Mass Actions:</span>
      <ButtonTertiary type="button" disabled={empty} onClick={unselectAll}>
        Unselect All
      </ButtonTertiary>
      <ButtonTertiary
        type="button"
        disabled={empty}
        onClick={() => move('down')}
      >
        Move Up
      </ButtonTertiary>
      <ButtonTertiary type="button" disabled={empty} onClick={() => move('up')}>
        Move Down
      </ButtonTertiary>
      <ButtonTertiary type="button" onClick={resetFilters}>
        Reset filters
      </ButtonTertiary>
    </div>
  );
};

export default MassActions;
