import audiodbPackageImportIt from 'api/audiodb/audiodbPostImport';
import { IAudiodbPackage } from 'api/audiodb/_types';
import { useMemo, useReducer } from 'react';
import useNotices from './useNotices';

type tState = {
  loading: boolean;
  error: string;
  data: null | IAudiodbPackage;
};

const initialState: tState = {
  loading: false,
  error: '',
  data: null,
};

function reducer(state: tState, action: Partial<tState>) {
  return {
    ...state,
    ...action,
  };
}

const useAudiodb = (): tState & {
  packageImportIt: (params: {
    packageId: number;
    packageData: IAudiodbPackage;
  }) => Promise<void>;
} => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setNotice } = useNotices();

  const packageImportIt = async (params: {
    packageId: number;
    packageData: IAudiodbPackage;
  }) => {
    dispatch({ loading: true });
    try {
      const response = await audiodbPackageImportIt(params);
      if (response.success) {
        setNotice({
          type: 'success',
          message: `Package ${params.packageData.name} imported.`,
        });
      } else {
        setNotice({
          type: 'failure',
          message: response.message || 'Something went wrong.',
        });
      }
    } catch (e) {
      if (typeof e === 'string') {
        dispatch({ error: e });
        setNotice({ type: 'failure', message: e });
      }
    }
    dispatch({ loading: false });
  };

  return useMemo(
    () => ({
      loading: state.loading,
      error: state.error,
      data: state.data,
      packageImportIt,
    }),
    [state, dispatch],
  );
};

export default useAudiodb;
