import config from 'api/config';
import fetchApi from 'api/fetchApi';
import { IAudiodbPackagesData } from './_types';

export type TPackagesGETPayload = {
  params: string;
};

const audiodbGet = async (
  args: TPackagesGETPayload,
): Promise<IAudiodbPackagesData> => {
  const response = await fetchApi<IAudiodbPackagesData>({
    method: 'GET',
    path: `${config.audiodbPackages}?${args.params}`,
  });

  const { payload } = response;

  return {
    count: payload?.count || 0,
    next: payload?.next || '',
    previous: payload?.previous || '',
    results: payload?.results || [],
  };
};

export default audiodbGet;
