import React from 'react';
import ButtonPrimary, { TButtonProps } from 'components/ButtonPrimary';
import restyle from 'components/../restyler';
import defaultStyles from './buttonSecondary.module.css';

const ButtonSecondary: React.FC<TButtonProps> = ({ styles, ...props }) => (
  <ButtonPrimary {...props} styles={restyle(defaultStyles, styles)} />
);

export default ButtonSecondary;
