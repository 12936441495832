import config from 'api/config';
import fetchApi from 'api/fetchApi';

interface ActionArguments {
  packages: number[];
  direction: 'up' | 'down';
}

interface ResponseSuccess {
  success: true;
}

interface ResponseError {
  success: false;
  message: string;
}

const packagesSwapPositions = async (
  args: ActionArguments,
): Promise<ResponseSuccess | ResponseError> => {
  const { success } = await fetchApi<null>({
    method: 'PATCH',
    path: `${config.packages}swap_positions/`,
    payload: args,
  });

  if (success) {
    return { success: true };
  }

  return {
    success: false,
    message: 'Failed to update priority.',
  };
};

export default packagesSwapPositions;
