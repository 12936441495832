import React, { useCallback } from 'react';
import btnTertiaryStyles from 'components/ButtonTertiary/buttonTertiary.module.css';
import useForgottenPass from 'hooks/useForgottenPass';
import PopupWrapper, { PopupButton } from 'components/Popups';
import Form from 'components/Forms';
import ControlledInput from 'components/Forms/ControlledField';
import ButtonPrimary from 'components/ButtonPrimary';
import ButtonSecondary from 'components/ButtonSecondary';
import emailValidator from 'validators/email';
import usePopups from 'hooks/usePopups';
import styles from './forgotPass.module.css';

const ForgotPass: React.FC = () => {
  const { submitResetPassword } = useForgottenPass();
  const { cancelPopup } = usePopups();

  const onSubmitResetPassword = useCallback(
    async (formData) => {
      const { email } = formData.values;
      const success = await submitResetPassword(email);
      if (success) cancelPopup('popup-forgot-password');
      else {
        formData.methods.clearForm();
      }
    },
    [submitResetPassword],
  );

  return (
    <div className={styles.root}>
      <PopupButton
        open
        unique="popup-forgot-password"
        className={btnTertiaryStyles.root}
      >
        Forgot password?
      </PopupButton>
      <PopupWrapper unique="popup-forgot-password">
        {({ cancelPopup: cancelMethodReceivedFromRenderProp }) => (
          <div className={styles.popup}>
            <div className={styles.popup__info}>
              Please confirm your e-mail. Verification link will be send there.
            </div>
            <Form
              data-testid="confirmEmailForm"
              onSubmitSuccess={onSubmitResetPassword}
              className={styles.form}
            >
              {({ methods }) => (
                <>
                  <div className={styles.input}>
                    <ControlledInput
                      name="email"
                      type="text"
                      validate={emailValidator}
                      placeholder="Enter verification email"
                    />
                  </div>
                  <div className={styles.footer}>
                    <ButtonPrimary
                      type="button"
                      onClick={methods.handleOnSubmit}
                    >
                      Confirm email
                    </ButtonPrimary>
                    <ButtonSecondary
                      onClick={cancelMethodReceivedFromRenderProp}
                    >
                      Cancel
                    </ButtonSecondary>
                  </div>
                </>
              )}
            </Form>
          </div>
        )}
      </PopupWrapper>
    </div>
  );
};

export default ForgotPass;
