import TitledSection from 'components/TitledSection';
import React from 'react';
import Tabs, { Tab } from 'components/Tabs';
import PackagesManage from 'components/PackagesManage';
import PackagesCreate from 'components/PackagesCreate';
import PackagesCompleted from 'components/PackagesCompleted';
import { TabConfig } from 'components/Tabs/tabs';
import styles from './packages.module.css';
import ButtonPrimary from 'components/ButtonPrimary';
import { hasAccess } from 'lib/security';
import useUser from 'hooks/useUser';
import useAudiodbImport from 'hooks/useAudiodbImport';

const tabsConfig: TabConfig[] = [
  {
    id: 'manage',
    title: 'Manage',
    roles: ['superuser', 'staff'],
  },
  {
    id: 'create',
    title: 'Create',
    roles: ['superuser'],
  },
  {
    id: 'completed',
    title: 'Completed',
    roles: ['superuser', 'staff'],
  },
];

const Packages: React.FC = () => {
  const { role } = useUser();
  const { importRanking, isLoading } = useAudiodbImport();
  return (
    <TitledSection title="Packages" className={styles.root}>
      {hasAccess(['superuser'], role) ? (
        <ButtonPrimary
          className={styles.import__button}
          onClick={importRanking}
          disabled={isLoading}
        >
          Import ranking packages
        </ButtonPrimary>
      ) : null}
      <Tabs config={tabsConfig} defaultTab="manage">
        <Tab id="manage">
          <PackagesManage />
        </Tab>
        <Tab id="create">
          <PackagesCreate />
        </Tab>
        <Tab id="completed">
          <PackagesCompleted />
        </Tab>
      </Tabs>
    </TitledSection>
  );
};

export default Packages;
