import config from 'api/config';
import fetchApi from 'api/fetchApi';

export interface ICompany {
  name: string;
  id: number;
}

export interface ICompaniesData {
  success: boolean;
  count?: number;
  next?: string;
  previous?: string;
  results?: ICompany[];
}

export type TCompaniesGetPayload = {
  params: string;
};

const companiesGet = async (
  args: TCompaniesGetPayload,
): Promise<ICompaniesData> => {
  const response = await fetchApi<ICompaniesData>({
    method: 'GET',
    path: `${config.companies}?${args.params}`,
  });

  const { success, payload } = response;

  return {
    success: !!success,
    count: payload?.count || 0,
    next: payload?.next || '',
    previous: payload?.previous || '',
    results: payload?.results || [],
  };
};

export default companiesGet;
