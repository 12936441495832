import React, { useCallback } from 'react';
import Form, { TFormSubmitData } from 'components/Forms';

import ButtonPrimary from 'components/ButtonPrimary';
import TitledSection from 'components/TitledSection';
import ControlledInput from 'components/Forms/ControlledField';
import usersCreate, { IUser } from 'api/accounts/usersPost';
import useNotices from 'hooks/useNotices';
import useLoading from 'hooks/useLoading';
import useAreaRefresher from 'hooks/useAreaRefresher';
import createUserStructure from './createUser.structure';
import styles from './createUser.module.css';

const CreateUser: React.FC = () => {
  const notices = useNotices();
  const loading = useLoading();
  const forceRefresh = useAreaRefresher();
  const onSubmit = async (formData: TFormSubmitData) => {
    const { values, methods } = formData;
    loading.setLoading(true);
    const response = await usersCreate(values as unknown as IUser);
    if (response.success) {
      methods.clearForm();
      notices.setNotice({
        type: 'success',
        message: `User created. ID: ${response.id}, name: ${response.username}`,
      });
      forceRefresh();
    } else {
      notices.setNotice({
        type: 'failure',
        message: response.message || 'Something went wrong.',
      });
    }
    loading.setLoading(false);
  };

  const onSubmitFailure = () => {
    notices.setNotice({
      type: 'failure',
      message: 'Form is not filled correctly.',
    });
  };

  return (
    <TitledSection title="Create a user">
      <Form
        onSubmitFailure={useCallback(onSubmitFailure, [])}
        onSubmitSuccess={useCallback(onSubmit, [])}
        className={styles.form}
        data-testid="createUserForm"
      >
        {({ form }) => {
          return (
            <>
              <div className={styles.passwordNotice}>
                If the password field is left blank, user will receive an e-mail
                with a link to set password
              </div>
              <div className={styles.formContent}>
                {createUserStructure.map((field) => (
                  <ControlledInput key={field.name} {...field} />
                ))}
              </div>
              <div className={styles.submit}>
                <ButtonPrimary type="submit" disabled={form.isSubmitting}>
                  Create user
                </ButtonPrimary>
              </div>
            </>
          );
        }}
      </Form>
    </TitledSection>
  );
};

export default CreateUser;
