import React, { useState } from 'react';
import ControlledInput from 'components/Forms/ControlledField';
import Icon from 'components/Icon';
import PopupWrapper, { PopupButton } from 'components/Popups';
import ButtonPrimary from 'components/ButtonPrimary';
import ButtonSecondary from 'components/ButtonSecondary';
import Form, { TFieldValue } from 'components/Forms';
import { ICompany } from 'api/companies/companiesGET';
import useCompanies from 'hooks/useCompanies';
import editUserStructure from './companyEdit.structure';
import styles from '../companiesList.module.css';

const CompanyEdit: React.FC<ICompany & { reload: () => void }> = (props) => {
  const [safetyStage, setSafetyStage] = useState<Record<string, TFieldValue>>();
  const { name, id, reload } = props;
  const { editCompany } = useCompanies();

  const cancelSafetyStage = () => {
    setSafetyStage(undefined);
  };

  return (
    <>
      <PopupButton
        title="Edit company"
        className={styles.actionButton}
        unique={`popup-edit-company-${id}`}
        open
      >
        <Icon name="edit" attributes={{ width: 30, height: 30 }} />
      </PopupButton>

      <PopupWrapper unique={`popup-edit-company-${id}`}>
        {({ cancelPopup }) => (
          <Form
            data-testid="editCompanyForm"
            noValidateOnSubmit
            onSubmitSuccess={async (formCtx) => {
              const { touched, values } = formCtx;

              const fieldsToEdit = {} as Record<string, TFieldValue>;
              let atLeastOneFieldEdited = false;
              Object.entries(touched).forEach((touchedEntry) => {
                const [fieldName, isTouched] = touchedEntry;
                if (isTouched) {
                  fieldsToEdit[fieldName] = values[fieldName];
                  atLeastOneFieldEdited = true;
                }
              });

              if (atLeastOneFieldEdited) setSafetyStage(fieldsToEdit);
            }}
          >
            {safetyStage ? (
              <div className="popup__content">
                <div className="popup__info">Fields will be edited:</div>
                <div className="popup__info_stressed">
                  {Object.entries(safetyStage).map((entry) => {
                    const [fieldName, fieldValue] = entry;
                    return (
                      <div key={fieldName}>
                        {fieldName}:{JSON.stringify(fieldValue)}
                      </div>
                    );
                  })}
                </div>
                <div className="popup__actions">
                  <ButtonPrimary
                    onClick={async () => {
                      await editCompany(id, safetyStage);
                      reload();
                      cancelPopup();
                    }}
                  >
                    Proceed
                  </ButtonPrimary>
                  <ButtonSecondary onClick={cancelSafetyStage}>
                    Back
                  </ButtonSecondary>
                </div>
              </div>
            ) : (
              <div className="popup__content">
                <div className="popup__info">
                  You are about to edit company:{' '}
                </div>
                <div className="popup__info_stressed">{name}</div>
                {editUserStructure.map((field) => {
                  const existingFields: Record<string, TFieldValue> = {
                    name,
                  };

                  let initialValue = existingFields[field.name];

                  if (initialValue === null) initialValue = '';

                  return (
                    <ControlledInput
                      key={field.name}
                      initialTouched={false}
                      initialValue={initialValue}
                      {...field}
                    />
                  );
                })}
                <div className="popup__actions">
                  <ButtonPrimary type="submit">Proceed</ButtonPrimary>
                  <ButtonSecondary onClick={cancelPopup}>
                    Cancel
                  </ButtonSecondary>
                </div>
              </div>
            )}
          </Form>
        )}
      </PopupWrapper>
    </>
  );
};

export default CompanyEdit;
