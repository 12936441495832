import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './wavesurfer.module.css';
import WaveSurfer from 'wavesurfer.js';
import {
  Region,
  RegionsPlugin,
  TimelinePlugin,
  CursorPlugin,
  SpectogramPlugin,
} from './wavesurfer.plugins';
import { getRegionColor } from './wavesurfer.helpers';
import COLORMAP from './colormap';
import useAnnotatorSound from 'hooks/useAnnotatorSound';
import { WavesurferContextWrapper } from 'components/WavesurferJS/wavesurfer.context';
import cx from 'classnames';
import Icon from 'components/Icon';

const createSpectrogram = () =>
  SpectogramPlugin.create({
    container: '#wave-spectrogram',
    colorMap: COLORMAP,
    noverlap: 0,
  });
const createRegions = () =>
  RegionsPlugin.create({
    dragSelection: true,
    regions: [],
  });
const createWaveSurfer = () =>
  WaveSurfer.create({
    height: 128,
    container: '#waveform',
    waveColor: 'violet',
    progressColor: 'purple',
    cursorColor: 'navy',
    hideScrollbar: false,
    scrollParent: true,
    minPxPerSec: 200,
    plugins: [
      createSpectrogram(),
      createRegions(),
      TimelinePlugin.create({
        container: '#wave-timeline',
      }),
      CursorPlugin.create({}),
    ],
  });

const Wavesurfer: React.FC = ({ children }) => {
  const [regions, setRegions] = useState<Record<string, Region>>({});
  const [zoomValue, setZoomValue] = useState(1);
  const [info, setInfo] = useState(false);
  const [soundLoaded, setSoundLoaded] = useState(false);
  const wavesurferRef = useRef<WaveSurfer>();
  const { current: wavesurfer } = wavesurferRef;

  const { soundDetails, nextSound, soundLoading, reload } = useAnnotatorSound();
  const disableAddingRegions = !!(
    soundDetails?.settings.limit_resize || soundDetails?.settings.disable_resize
  );
  useEffect(() => {
    if (soundDetails && wavesurfer?.isReady) {
      if (disableAddingRegions) {
        wavesurfer.regions.disableDragSelection();
      } else {
        wavesurfer.enableDragSelection({
          color: getRegionColor(),
        });
      }
      wavesurfer?.regions.clear();
      soundDetails.sound.annotations.forEach((item) => {
        wavesurfer?.regions.add({
          end: item.end,
          start: item.start,
          id: item.id,
          color: getRegionColor(),
        });
      });
      setRegions({ ...wavesurfer.regions.list });
    }
  }, [soundDetails, wavesurfer?.isReady, disableAddingRegions]);

  const playWave = () => {
    wavesurfer?.playPause();
  };

  const changeZoom = (value: number) => {
    setZoomValue(value);
  };

  const playRegion = (region: Region) => {
    region?.play(0);
  };

  const addRegion = () => {
    if (wavesurfer) {
      if (!disableAddingRegions) {
        wavesurfer.enableDragSelection({
          color: getRegionColor(),
        });
      }
      setRegions({ ...wavesurfer.regions.list });
    }
  };

  const removeRegion = (region: Region) => {
    if (wavesurfer) {
      region.remove();
      setRegions({ ...wavesurfer.regions.list });
    }
  };

  const updateRegion = (region: Region, fields: Partial<Region>) => {
    if (wavesurfer) {
      // const newRegion = Object.assign({ ...region }, fields);
      const newRegion = { ...region, ...fields };
      region.remove();
      wavesurfer.addRegion(newRegion);
      setRegions({ ...wavesurfer.regions.list });
    }
  };

  useEffect(() => {
    const DOMprepared = !!document.querySelector('#waveform');
    let newInstance: WaveSurfer;
    if (DOMprepared) {
      newInstance = createWaveSurfer();
      newInstance.on('ready', () => {
        newInstance.regions.clear();
        setRegions({});
        setSoundLoaded(true);
      });
      newInstance.on('zoom', () => {
        if (newInstance?.isReady) {
          newInstance?.spectrogram?.destroy();
          newInstance.addPlugin(createSpectrogram());
          newInstance?.spectrogram?.init();
        }
      });
      wavesurferRef.current = newInstance;
    }
    return () => {
      newInstance?.destroy();
    };
  }, []);

  useEffect(() => {
    if (!soundLoading) {
      if (soundDetails) {
        setSoundLoaded(true);
        wavesurfer?.load(soundDetails.sound_entry_file);
      } else {
        setSoundLoaded(false);
        wavesurfer?.destroy();
      }
    }
  }, [soundLoading, soundDetails, wavesurfer]);

  useEffect(() => {
    if (wavesurfer?.isReady) {
      const timeout = setTimeout(() => {
        wavesurfer?.zoom(zoomValue * 400);
      }, 100);

      return () => clearTimeout(timeout);
    }
  }, [wavesurfer, zoomValue]);

  useEffect(() => {
    wavesurfer?.on('region-update-end', addRegion);
    return () => {
      wavesurfer?.un('region-update-end', addRegion);
    };
  }, [wavesurfer, setRegions, regions]);

  const regionsToArray = useMemo(() => {
    const newRegions = Object.entries(regions).map(([, value]) => {
      return value;
    });

    return newRegions.sort((a, b) => {
      return a.start - b.start;
    });
  }, [regions]);
  const soundProps = {
    playRegion,
    removeRegion,
    playWave,
    regions: regionsToArray,
    changeZoom,
    zoomValue,
    ready: soundLoaded,
    reload,
    updateRegion,
    nextSound,
    soundDetails,
  };

  const noSound = !soundDetails && !soundLoading;
  return (
    <div className={styles.root}>
      <div className={styles.wavearea}>
        {soundDetails?.settings.title &&
          <div
            className={cx(styles.sound__description, {
              [styles.sound__description__annotation]:
                soundDetails.settings.type === 'annotation',
              [styles.sound__description__validation]:
                soundDetails.settings.type === 'validation',
              [styles.sound__description__last_validation]:
                soundDetails.settings.type === 'last_validation',
            })}
          >
            <h3 onClick={() => setInfo(i => !i)}>{soundDetails.package.description} <Icon name="info" attributes={{width: 16, height: 16}} /></h3>
            {info && <p>{soundDetails.settings.title}</p>}
          </div>
        }
        <div
          className="spectrogram-wrapper"
          style={!wavesurfer?.isReady ? { height: '256px' } : undefined}
        >
          <div id="wave-spectrogram" />
        </div>
        <div className="wave-wrapper">
          <div id="waveform" />
        </div>
        <div id="wave-timeline" />
      </div>
      {noSound ?
        <div>No sounds to annotate...</div>
        :
        <WavesurferContextWrapper data={soundProps}>
          {children}
        </WavesurferContextWrapper>
      }
      {!(wavesurfer?.isReady || noSound) &&
        <div data-testid="waveLoader" className={styles.spinner}>
          <div></div>
        </div>
      }
      <style
        dangerouslySetInnerHTML={{
          __html: `
                        #waveform > wave {
                            height: 400px!important;
                        }

                        #waveform > wave wave {
                            height: 128px!important;
                            top: unset!important;
                            bottom: 0px!important;
                        }

                        #waveform > wave canvas {
                            height: 128px!important;
                            top: unset!important;
                            bottom: 0px!important;
                        }

                        .wavesurfer-region {
                            z-index: 4!important;
                        }

                        .wave-wrapper {
                            position: relative;
                            z-index: 4;
                            margin-top: -256px
                        }
                        #wave-spectrogram canvas {
                            height: 100%
                        }
                        #wave-spectrogram spectrogram {
                            height: 256px !important
                        }
                        #waveform > ::-webkit-scrollbar {
                            -webkit-appearance: none;
                        }
                        #waveform > ::-webkit-scrollbar:horizontal {
                            height: 11px;
                        }
                        #waveform > ::-webkit-scrollbar-thumb {
                            border-radius: 8px;
                            border: 2px solid white; /* should match background, can't be transparent */
                            background-color: rgba(0, 0, 0, .5);
                        }
                        #waveform > ::-webkit-scrollbar-track {
                            background-color: #fff;
                            border-radius: 8px;
                        }
                `,
        }}
      />
    </div>
  );
};

export default Wavesurfer;
