import config from 'api/config';
import fetchApi from 'api/fetchApi';

export interface IUser {
  email: string;
  username: string;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  company: number;
  password?: string;
}

export type TUsersPOSTPayload = {
  params: string;
};

interface IUsersPOSTReturnType {
  success: boolean;
  message?: string;
  company?: number;
  email?: string;
  id?: number;
  is_active?: boolean;
  is_staff?: boolean;
  is_superuser?: boolean;
  username?: string;
}

const usersPOST = async (args: IUser): Promise<IUsersPOSTReturnType> => {
  const response = await fetchApi<IUser & { id: number; detail?: string }>({
    method: 'POST',
    path: `${config.accountsUsers}`,
    payload: {
      ...args,
      password: args.password || undefined,
    },
  });

  const { success, status, payload } = response;

  if (success) {
    return {
      success: true,
      company: payload?.company,
      email: payload?.email,
      id: payload?.id,
      is_active: payload?.is_active,
      is_staff: payload?.is_staff,
      is_superuser: payload?.is_superuser,
      username: payload?.username,
    };
  }
  if (status === 400 && payload) {
    let message = '';
    Object.entries(payload).forEach((entry) => {
      const [name, val] = entry;
      message += `${name.toUpperCase()} - ${
        val.join ? val.join('') : JSON.stringify(val)
      } `;
    });
    message = message ? `Errors! ${message}` : 'Something went wrong';
    return {
      success: false,
      message,
    };
  }
  return {
    success: false,
    message:
      typeof payload?.detail === 'string'
        ? payload.detail
        : 'Something went wrong',
  };
};

export default usersPOST;
