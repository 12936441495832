import { TFieldValue } from 'components/Forms';
import emailValidator from 'validators/email';
import passwordValidator from 'validators/password';
import usernameValidator from 'validators/username';
import { ControlledInputProps } from 'components/Forms/ControlledField/input';
import styles from 'components/CreateUser/createUser.module.css';

const inputStyles = {
  root: styles.inputRoot,
  label__span: styles.inputLabel,
};

const createUserStructure: ControlledInputProps[] = [
  {
    type: 'text',
    name: 'username',
    styles: inputStyles,
    validate: usernameValidator,
    label: 'Username',
    placeholder: 'Username',
  },
  {
    type: 'text',
    name: 'password',
    styles: inputStyles,
    validate: (val: TFieldValue): string => {
      if (!val) return '';
      return passwordValidator(val);
    },
    label: 'Password',
    placeholder: 'Password',
  },
  {
    type: 'text',
    name: 'email',
    styles: inputStyles,
    validate: emailValidator,
    label: 'Email',
    placeholder: 'Email',
  },
  {
    name: 'company',
    type: 'companies_select',
    styles: inputStyles,
    label: 'Company',
  },
  {
    name: 'is_active',
    type: 'checkbox',
    title: 'Is active user',
    styles: inputStyles,
    label: 'Active',
    labelFalse: 'Inactive',
  },
  {
    name: 'is_staff',
    type: 'checkbox',
    title: 'Is staff member',
    styles: inputStyles,
    label: 'Member',
    labelFalse: 'Not a member',
  },
  {
    name: 'is_superuser',
    type: 'checkbox',
    title: 'Is admin',
    styles: inputStyles,
    label: 'Admin',
    labelFalse: 'Not an admin',
  },
];

export default createUserStructure;
