import ButtonPrimary from 'components/ButtonPrimary';
import ButtonSecondary from 'components/ButtonSecondary';
import PopupWrapper, { PopupButton } from 'components/Popups';
import useUser from 'hooks/useUser';
import React, { useEffect, useRef, useState } from 'react';
import styles from './logout.module.css';

function msToTime(duration: number) {
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  const displayhours = hours < 10 ? `0${hours}` : `${hours}`;
  const displayminutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const displayseconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${displayhours}:${displayminutes}:${displayseconds}`;
}

const TimerComponent: React.FC<{
  timestamp: number;
  userLogOut: () => void;
}> = ({ timestamp, userLogOut }) => {
  const [left, setLeft] = useState<number | null>(null);
  const timerRef = useRef<NodeJS.Timeout>();

  const timerFunc = () => {
    const currentTime = Date.now();
    const userIsLoggedToThisDate = timestamp;
    if (currentTime >= userIsLoggedToThisDate) {
      userLogOut();
    } else {
      const timeLeft = userIsLoggedToThisDate - currentTime;
      setLeft(timeLeft);
    }
  };

  useEffect(() => {
    timerRef.current = setInterval(timerFunc, 1000);
    return () => {
      if (timerRef.current) clearInterval(timerRef.current);
    };
  }, [timestamp, left, setLeft]);

  const leftFullString = left !== null ? msToTime(left) : null;

  return <span className={styles.logout__time}>{leftFullString}</span>;
};

const LogoutButton: React.FC = () => {
  const { token, timestamp, login, userLogOut } = useUser();

  return token ? (
    <div className={styles.logout}>
      <div className={styles.logout__label}>Until logout: </div>
      <TimerComponent timestamp={timestamp || 0} userLogOut={userLogOut} />
      <PopupButton
        unique="logout-popup"
        open
        className={styles.logout__name}
        data-testid="logoutButton"
      >
        {login}
      </PopupButton>
      <PopupWrapper unique="logout-popup">
        {({ cancelPopup }) => (
          <div className={styles.popup}>
            <div className={styles.popup__message}>
              You are about to logout.
            </div>
            <div className={styles.popup__actions}>
              <ButtonPrimary
                onClick={() => {
                  userLogOut();
                  cancelPopup();
                }}
              >
                Proceed
              </ButtonPrimary>
              <ButtonSecondary onClick={cancelPopup}>Cancel</ButtonSecondary>
            </div>
          </div>
        )}
      </PopupWrapper>
    </div>
  ) : null;
};

export default LogoutButton;
