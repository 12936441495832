import { useEffect, useState } from 'react';
import usersGET, { IUser } from 'api/accounts/usersGet';

const useUsersList = (): { users: IUser[]; usersLoading: boolean } => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [usersLoading, setLoading] = useState(true);

  const fetchUsers = async () => {
    setLoading(true);
    const receivedUsers = await usersGET({ params: 'limit=1000' });
    if (receivedUsers) {
      setUsers(receivedUsers.results || []);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return { users, usersLoading };
};

export default useUsersList;
