import packagedDelete from 'api/packages/packagesDelete';
import packagesGetById from 'api/packages/packagesGetById';
import packagesOPTIONS from 'api/packages/packagesOptions';
import packagesPatch, { IPackageToPatch } from 'api/packages/packagesPatch';
import packagesPost from 'api/packages/packagesPost';
import packagesPostSetUploaded from 'api/packages/packagesSetUpload';
import {
  IPackage,
  IPackageEditableFields,
  IPackagesOptionsData,
} from 'api/packages/_types';
import { useMemo, useState } from 'react';
import useNotices from './useNotices';

export interface IReturnType<DataType> {
  loading: boolean;
  error: string;
  data: DataType;
}

type tInitialState = {
  loading?: boolean;
  error?: string;
  data?: unknown;
};

const initialState: tInitialState = {
  loading: false,
  error: '',
  data: null,
};

const usePackages = (): {
  options: IReturnType<IPackagesOptionsData>;
  posted: IReturnType<IPackage>;
  fetchOptions: () => Promise<void>;
  postPackage: (fields: IPackageEditableFields) => Promise<void>;
  patched: IReturnType<IPackage>;
  patchPackage: (id: number, fields: IPackageEditableFields) => Promise<void>;
  packageById: IReturnType<IPackage>;
  fetchPackageById: (id: number) => Promise<void>;
  deleted: IReturnType<number>;
  deletePackage: (id: number) => Promise<void>;
  uploaded: IReturnType<IPackage>;
  setPackageUploaded: (args: {
    packageId: number;
    packageData: IPackage;
  }) => Promise<void>;
} => {
  const { setNotice } = useNotices();
  const [options, setOptions] = useState(initialState);
  const [posted, setPosted] = useState(initialState);
  const [patched, setPatched] = useState(initialState);
  const [packageById, setPackageById] = useState(initialState);
  const [deleted, setDeleted] = useState(initialState);
  const [uploaded, setUploaded] = useState(initialState);

  const mergeState = (
    oldState: typeof initialState,
    newState: typeof initialState,
    setState: typeof setOptions,
  ) => {
    setState({
      ...oldState,
      ...newState,
    });
  };

  const fetchOptions = async () => {
    mergeState(options, { loading: true }, setOptions);
    const packagesOptions = await packagesOPTIONS();
    if (packagesOptions)
      mergeState(
        options,
        { loading: false, data: packagesOptions },
        setOptions,
      );
    else
      mergeState(
        options,
        { loading: false, error: 'Something went wrong' },
        setOptions,
      );
  };

  const postPackage = async (fields: IPackageEditableFields) => {
    mergeState(posted, { loading: true }, setPosted);
    const packagePosted = await packagesPost(fields);
    const { success, payload, message } = packagePosted;
    const noticeType = success && payload ? 'success' : 'failure';
    setNotice({ type: noticeType, message });
    mergeState(posted, { loading: false, data: payload }, setPosted);
  };

  const patchPackage = async (id: number, fieldsToPatch: IPackageToPatch) => {
    mergeState(patched, { loading: true }, setPatched);
    const { success, payload, message } = await packagesPatch(
      id,
      fieldsToPatch,
    );
    const noticeType = success && payload ? 'success' : 'failure';
    mergeState(patched, { loading: false, data: payload }, setPatched);
    setNotice({ type: noticeType, message });
  };

  const fetchPackageById = async (id: number) => {
    mergeState(packageById, { loading: true }, setPackageById);
    const fetchedPackage = await packagesGetById({ packageId: id });
    mergeState(
      packageById,
      { loading: false, data: fetchedPackage },
      setPackageById,
    );
  };

  const deletePackage = async (id: number) => {
    mergeState(deleted, { loading: true }, setDeleted);
    const response = await packagedDelete({ packageId: id });
    mergeState(deleted, { loading: false, data: response.success }, setDeleted);
    if (response.success) {
      setNotice({ type: 'success', message: `Package ${id} deleted.` });
    } else {
      setNotice({
        type: 'failure',
        message: response.message || `Package ${id} NOT deleted.`,
      });
    }
  };

  const setPackageUploaded = async ({
    packageId,
    packageData,
  }: {
    packageId: number;
    packageData: IPackage;
  }) => {
    mergeState(uploaded, { loading: true }, setUploaded);
    const isUploaded = await packagesPostSetUploaded({
      packageId,
      packageData,
    });
    mergeState(uploaded, { loading: false, data: isUploaded }, setUploaded);
    const noticeType = isUploaded?.success ? 'success' : 'failure';
    const noticeMsg = isUploaded?.message
      ? isUploaded.message
      : 'Something went wrong.';
    setNotice({ type: noticeType, message: noticeMsg });
  };

  return useMemo(
    () => ({
      fetchOptions,
      options: options as IReturnType<IPackagesOptionsData>,
      postPackage,
      posted: posted as IReturnType<IPackage>,
      patchPackage,
      patched: patched as IReturnType<IPackage>,
      fetchPackageById,
      packageById: packageById as IReturnType<IPackage>,
      deleted: deleted as IReturnType<number>,
      deletePackage,
      setPackageUploaded,
      uploaded: uploaded as IReturnType<IPackage>,
    }),
    [
      options,
      setOptions,
      patched,
      setPatched,
      posted,
      setPosted,
      packageById,
      fetchPackageById,
      uploaded,
      setUploaded,
    ],
  );
};

export default usePackages;
