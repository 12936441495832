import React, { ReactNode } from 'react';
import { IPackage } from 'api/packages/_types';
import useMassActions from 'hooks/useMassActions';
import useCompaniesOptionsFromContext from 'hooks/useCompaniesOptionsFromContext';
import useCompanyName from 'hooks/useCompanyName';
import styles from '../packagesManage.module.css';

const PackagesRow: React.FC<
  IPackage & {
    reload: () => void;
    children: ReactNode;
  }
> = ({
  name,
  id,
  priority,
  status,
  statistics,
  uploaded_by,
  company,
  skipped,
  children,
  description,
}) => {
  const { update, isSelected } = useMassActions();
  const { options: companyOptions } = useCompaniesOptionsFromContext();
  const companyName = useCompanyName(companyOptions, company);

  return (
    <li className={styles.row} title={description}>
      <div className={styles.id}>
        <input
          type="checkbox"
          name={`massAction-${id}`}
          value={id}
          checked={isSelected(id)}
          onChange={() => update(id)}
        />
      </div>
      <div className={styles.id}>
        {id}
      </div>
      <div className={styles.name}>
        {name}
      </div>
      <div className={styles.status}>
        {status}
      </div>
      <div className={styles.skipped}>
        {skipped}
      </div>
      <div className={styles.priority}>
        {priority}
      </div>
      <div className={styles.uploadedBy}>
        {uploaded_by}
      </div>
      <div className={styles.company}>
        {companyName}
      </div>
      <div className={styles.statistics}>
        {statistics}
      </div>
      <div className={styles.actions}>{children}</div>
    </li>
  );
};

export default PackagesRow;
