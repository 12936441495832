import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reduxStore/_types';
import styles from './overlay.module.css';

const Overlay: React.FC = () => {
  const isLoading = useSelector((state: RootState) => state.loading);
  return isLoading ? (
    <div className={styles.spinner}>
      <div />
    </div>
  ) : null;
};

export default Overlay;
