import { useCallback, useState } from 'react';
import useNotices from './useNotices';
import audidbImportPost from 'api/audiodb/audiodbImportPost';

const useAudiodbImport = (): {
  isLoading: boolean;
  importRanking: () => void;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const { setNotice } = useNotices();

  const importRanking = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await audidbImportPost();
      if (!response.success) {
        setNotice({ type: 'failure', message: response.message });
        return;
      }
      setNotice({ type: 'message', message: 'Importing started' });
    } catch (e) {
      setNotice({ type: 'failure', message: 'Something went wrong' });
    } finally {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    importRanking,
  };
};

export default useAudiodbImport;
