import ControlledInput from 'components/Forms/ControlledField';
import Icon from 'components/Icon';
import PopupWrapper, { PopupButton } from 'components/Popups';
import ButtonPrimary from 'components/ButtonPrimary';
import ButtonSecondary from 'components/ButtonSecondary';
import Form, { TFieldValue } from 'components/Forms';
import usePackages from 'hooks/usePackages';
import { IPackage } from 'api/packages/_types';
import styles from '../packagesManage.module.css';

const PackageEdit = (params: { props: IPackage, reload: () => void }) => {
  const { name, id, description, company } = params.props;
  const { patchPackage } = usePackages();

  return (
    <>
      <PopupButton
        title="Edit package"
        className={styles.popup__open}
        unique={`popup-edit-company-${id}`}
        open
      >
        <Icon name="edit" attributes={{ width: 25, height: 25 }} />
      </PopupButton>

      <PopupWrapper unique={`popup-edit-company-${id}`}>
        {({ cancelPopup }) => (
          <Form
            data-testid="packageEditForm"
            onSubmitSuccess={async formCtx => {
              const { touched, values } = formCtx;

              const changed: Record<string, TFieldValue> = {};
              for (const [key, value] of Object.entries(touched)) {
                if (value)
                  changed[key] = values[key];
              }

              patchPackage(id, changed);
              cancelPopup();
            }}
          >
            <div className="popup__content">
              <div className="popup__info">
                You are about to edit package: {name}
              </div>
              <ControlledInput
                name="description"
                type="text"
                initialValue={description}
              />
              <ControlledInput
                name="company"
                type="companies_select"
                initialValue={company}
              />
              <div className="popup__actions">
                <ButtonPrimary type="submit">Proceed</ButtonPrimary>
                <ButtonSecondary onClick={cancelPopup}>
                  Cancel
                </ButtonSecondary>
              </div>
            </div>
          </Form>
        )}
      </PopupWrapper>
    </>
  );
};

export default PackageEdit;
