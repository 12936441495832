import config from 'api/config';
import fetchApi from 'api/fetchApi';
import { TFieldValue } from 'components/Forms';

export interface IUserParams {
  userId: number;
  fields: Record<string, TFieldValue>;
}

type TUsersPATCHReturnType = {
  success: boolean;
  message: string;
};

const usersPATCH = async (
  args: IUserParams,
): Promise<TUsersPATCHReturnType> => {
  const response = await fetchApi({
    method: 'PATCH',
    path: `${config.accountsUsers}${args.userId}/`,
    payload: { ...args.fields },
  });

  const { success, status, payload } = response;

  if (success && status === 200) {
    return {
      success: true,
      message: 'User edited.',
    };
  }
  if (payload instanceof Object) {
    let message = '';
    Object.entries(payload).forEach((entry) => {
      const [name, val] = entry as [string, string | string[]];
      message += `${name.toUpperCase()} - ${
        val instanceof Array ? val.join('') : JSON.stringify(val)
      } `;
    });
    message = message ? `Errors! ${message}` : 'Something went wrong';
    return {
      success: false,
      message,
    };
  }
  return {
    success: false,
    message: 'Something went wrong',
  };
};

export default usersPATCH;
