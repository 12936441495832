import { useEffect } from 'react';

const useKeyboardNavigation = () => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const playBtn = document.getElementById('play-button');
      const reloadBtn = document.getElementById('reload-button');
      const unusableBtn = document.getElementById('unusable-button');

      if ((e.target as HTMLElement)?.tagName.toUpperCase() === 'INPUT') {
        return;
      }

      //if (
      //  e.key === 'Enter' &&
      //  (e.target as HTMLElement)?.tagName.toUpperCase() !== 'BUTTON'
      //) {
      //  e.preventDefault();
      //  e.stopPropagation();
      //  document.getElementById('annotation-confirm')?.click();
      //}

      if (e.key.toUpperCase() === 'U') {
        unusableBtn?.click();
      }

      if (e.key.toUpperCase() === 'R') {
        reloadBtn?.click();
      }

      if (e.key.toUpperCase() === 'SPACEBAR' || e.key === ' ') {
        e.preventDefault();
        e.stopPropagation();
        playBtn?.click();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};

export default useKeyboardNavigation;
