import config from 'api/config';
import fetchApi from 'api/fetchApi';

interface SuccessResponse {
  success: true;
}

interface ErrorResponse {
  success: false;
  message?: string;
}

type tPackagedDel = (args: {
  packageId: number;
}) => Promise<SuccessResponse | ErrorResponse>;

const packagedDelete: tPackagedDel = async (args) => {
  const { success, payload } = await fetchApi<{ detail?: string }>({
    method: 'DELETE',
    path: `${config.packages}${args.packageId}/`,
  });

  if (success) {
    return { success: true };
  }
  return {
    success: false,
    message: payload?.detail,
  };
};

export default packagedDelete;
