import React, { useRef } from 'react';
import { IChartItem } from 'components/Chart/chart';
import styles from './chart.module.css';

const RenderLegend: React.FC<{
  chartProps: Array<{ value: string; color: string; payload: IChartItem }>;
  setActiveElement: (id: number) => void;
  activeId: number | null;
}> = ({ chartProps: payload, setActiveElement, activeId }) => {
  const scrollListRef = useRef(null);

  const handleScroll = (direction: number) => {
    const listElement = scrollListRef.current as unknown as HTMLUListElement;

    try {
      listElement.scrollBy({
        top: listElement.offsetHeight * direction,
        left: 0,
        behavior: 'smooth',
      });
    } catch {
      listElement.scrollBy(listElement.offsetHeight * direction, 0);
    }
  };
  const withScrolling = payload.length > 7;

  return (
    <div className={styles.legendWrapper}>
      {withScrolling ? (
        <div className={styles.legend__buttons}>
          <button
            className={styles.legend__button_up}
            onClick={() => handleScroll(-1)}
          />
        </div>
      ) : null}
      <ul ref={scrollListRef} className={styles.legend} aria-label="Legend">
        {payload.map((entry, idx: number) => {
          const { id } = entry.payload;
          const active = id === activeId;
          return (
            <li
              onClick={() => setActiveElement(id)}
              key={idx}
              className={`recharts-legend-item legend-item-0 ${styles.legend_item}`}
            >
              <svg
                className={`recharts-surface ${styles.legend__square}`}
                width="10"
                height="10"
                viewBox="0 0 32 32"
                version="1.1"
              >
                <path
                  stroke="none"
                  fill={entry.color}
                  d="M0,4h32v24h-32z"
                  className="recharts-legend-icon"
                />
              </svg>
              <span
                className="recharts-legend-item-text"
                style={{
                  color: entry.color,
                  textDecoration: active ? 'underline' : 'inherit',
                }}
              >
                {entry.value} ({entry.payload.value})
              </span>
            </li>
          );
        })}
      </ul>
      {withScrolling ? (
        <div className={styles.legend__buttons}>
          <button
            className={styles.legend__button_down}
            onClick={() => handleScroll(1)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default RenderLegend;
