export type TStylesheet = {
  readonly [key: string]: string;
};

function restyle(
  defaultStyles: TStylesheet,
  styles?: TStylesheet,
): TStylesheet {
  return { ...defaultStyles, ...(styles || {}) };
}

export default restyle;
