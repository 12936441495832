import React from 'react';
import CreateCompany from 'components/CreateCompany';
import CompaniesList from 'components/CompaniesList';
import { AreaRefresher } from 'hooks/useAreaRefresher';

const Users: React.FC = () => (
  <AreaRefresher>
    <CreateCompany />
    <CompaniesList />
  </AreaRefresher>
);

export default Users;
