import React, { useEffect, useState } from 'react';
import Icon from 'components/Icon';
import PopupWrapper, { PopupButton } from 'components/Popups';
import ButtonSecondary from 'components/ButtonSecondary';
import { IGroup } from 'api/groups/_types';
import useUsersList from 'hooks/useUsersList';
import usePackageList from 'hooks/usePackagesList';
import { IUser } from 'api/accounts/usersGet';
import { IPackage } from 'api/packages/_types';
import styles from './groupDetails.module.css';
import listingStyles from '../groupsList.module.css';

interface GroupDetailsProps {
  group: IGroup;
}

const GroupDetails: React.FC<GroupDetailsProps> = ({ group }) => {
  const { id } = group;
  return (
    <>
      <PopupButton
        className={listingStyles.actionButton}
        unique={`popup-edit-group-${id}`}
        open
        title="Show details"
      >
        <Icon name="info" attributes={{ width: 30, height: 30 }} />
      </PopupButton>

      <PopupWrapper unique={`popup-edit-group-${id}`}>
        {({ cancelPopup }) => (
          <GroupDetailsPopup cancelPopup={cancelPopup} group={group} />
        )}
      </PopupWrapper>
    </>
  );
};

const GroupDetailsPopup: React.FC<{ cancelPopup: () => void; group: IGroup }> =
  ({ cancelPopup, group }) => {
    const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
    const [filteredPackages, setFilteredPackages] = useState<IPackage[]>([]);
    const { usersLoading, users } = useUsersList();
    const { packagesLoading, packages } = usePackageList();
    const { users: usersIds, packages: packagesIds, name } = group;

    useEffect(() => {
      setFilteredUsers(users.filter(({ id }) => usersIds.includes(id)));
    }, [usersIds, users]);
    useEffect(() => {
      if (!packages?.results) {
        setFilteredPackages([]);
        return;
      }
      setFilteredPackages(
        packages?.results.filter(({ id }) => packagesIds.includes(id)) || [],
      );
    }, [packagesIds, packages]);

    const loading = usersLoading || packagesLoading;
    return (
      <div className="popup__content" data-testid="groupDetailsPopup">
        <div className="popup__info_stressed">
          Details about group
          {name}:
        </div>
        <div className="popup__info">
          {loading ? (
            'Loading...'
          ) : (
            <div className={styles.details__wrapper}>
              <div className={styles.details__list__wrapper}>
                Users:
                <ul className={styles.details__list} aria-label="Users list">
                  {filteredUsers.map(({ username, id }) => (
                    <li key={id}>{username}</li>
                  ))}
                </ul>
              </div>
              <div className={styles.details__list__wrapper}>
                Packages:
                <ul className={styles.details__list} aria-label="Packages list">
                  {filteredPackages.map(({ name, id }) => (
                    <li key={id}>{name}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className="popup__actions">
          <ButtonSecondary onClick={cancelPopup}>Back</ButtonSecondary>
        </div>
      </div>
    );
  };

export default GroupDetails;
