export interface IEnhancedDecodedToken {
  token: string;
  refresh: string;
  timestamp: number;
  user_id: number;
  login: string;
  is_staff: boolean;
  is_superuser: boolean;
}

export interface IDecodedToken {
  exp: number;
  jti: string;
  token_type: string;
  user_id: number;
  username: string;
  is_staff: boolean;
  is_superuser: boolean;
}

function b64DecodeUnicode(str: string) {
  return decodeURIComponent(
    atob(str).replace(/(.)/g, (m, p) => {
      let code = p.charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2) {
        code = `0${code}`;
      }
      return `%${code}`;
    }),
  );
}

function base64urlDecode(str: string) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += '==';
      break;
    case 3:
      output += '=';
      break;
    default:
      throw 'Illegal base64url string!';
  }

  try {
    return b64DecodeUnicode(output);
  } catch (err) {
    return atob(output);
  }
}

export function tokenDecode(token: string): IDecodedToken | null {
  try {
    return JSON.parse(base64urlDecode(token.split('.')[1]));
  } catch {
    return null;
  }
}

export function getUserDataFromToken(
  token: string,
  refreshToken: string,
): IEnhancedDecodedToken | null {
  try {
    const decodedToken = tokenDecode(token);
    if (!decodedToken) {
      return null;
    }
    const { exp, user_id, username, is_staff, is_superuser } = decodedToken;
    return {
      token: token,
      refresh: refreshToken,
      login: username,
      timestamp: typeof exp === 'number' ? exp * 1000 : 0,
      is_staff,
      user_id,
      is_superuser,
    };
  } catch {
    return null;
  }
}

export default tokenDecode;
