import ButtonPrimary from 'components/ButtonPrimary';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import styles from './annotationActions.module.css';
import reloadButtonStyles from 'components/ButtonSecondary/buttonSecondary.module.css';
import ButtonDanger from 'components/ButtonDanger';
import { useWavesurfer } from 'components/WavesurferJS/wavesurfer.context';
import useForm from 'hooks/useForm';

const reloadBtnAttributes = {
  className: reloadButtonStyles.root,
  id: 'reload-button',
};

const unusableButtonAttributes = {
  id: 'unusable-button',
};

const playButtonAttributes = {
  id: 'play-button',
};

const AnnotationActions: React.FC = () => {
  const {
    playWave,
    changeZoom,
    reload,
    zoomValue: initValue,
  } = useWavesurfer();
  const [zoomValue, setZoomValue] = useState(initValue);
  const {
    methods: formMethods,
    form: { values: formValues },
  } = useForm();

  const onRangeChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setZoomValue(+value);
  };
  const { unusableFile } = formValues;
  const toggleFileAsUnusable = () => {
    formMethods.setManyFields([{ name: 'unusableFile', value: !unusableFile }]);
  };

  useEffect(() => {
    changeZoom(+zoomValue);
  }, [zoomValue]);

  return (
    <div className={styles.root}>
      <div className={styles.primaries}>
        <ButtonPrimary onClick={playWave} {...playButtonAttributes}>
          Play [SPACE]
        </ButtonPrimary>

        <label className={styles.zoom}>
          <span className={styles.zoom__label}>ZOOM ({zoomValue || 1})</span>
          <input
            onInput={onRangeChange}
            className={styles.zoom__range}
            type="range"
            max="2"
            min="0.1"
            step="0.1"
            value={zoomValue || 1}
          />
        </label>
      </div>
      <div className={styles.secondaries}>
        <ButtonPrimary {...reloadBtnAttributes} onClick={reload}>
          Reload [R]
        </ButtonPrimary>
        <ButtonDanger
          {...unusableButtonAttributes}
          className={styles.unusable_button}
          onClick={toggleFileAsUnusable}
        >
          {unusableFile ? 'Usable file [U]' : 'Unusable file [U]'}
        </ButtonDanger>
      </div>
    </div>
  );
};

export default AnnotationActions;
