import { initialState } from './initialState';
import { TReducer } from '../../_types';
import { SET_LOADING, actionSetLoading } from '../../actions/loading';

type TActionsAvailable = ReturnType<typeof actionSetLoading>;

const reducerSetLoading: TReducer<boolean, typeof SET_LOADING, boolean> = (
  state,
  action,
) => !!action.payload;

const loadingReducer = (
  state: boolean = initialState,
  action: TActionsAvailable,
): boolean =>
  action.type === SET_LOADING ? reducerSetLoading(state, action) : state;

export default loadingReducer;
