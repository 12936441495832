import React from 'react';
import { ISoundFile } from 'api/ranking/_types';
import styles from './soundRanking.module.css';

const SoundRanking: React.FC<{
  selectFile: (arg: ISoundFile) => void;
  markAsListened: (file: ISoundFile) => void;
  markAsPlaying: (id: number | null) => void;
  soundFile: ISoundFile;
  isDisabled: boolean;
  isSelected?: boolean;
}> = ({
  selectFile,
  soundFile,
  isSelected,
  markAsListened,
  isDisabled,
  markAsPlaying,
}) => (
  <div className={styles.root}>
    <div key={soundFile.file} className={styles.item}>
      <label
        className={`${styles.label} ${isSelected ? styles.label_checked : ''}`}
      >
        <input
          className={styles.input}
          onChange={() => selectFile(soundFile)}
          type="radio"
          value={soundFile.file}
          checked={isSelected}
        />
      </label>
      <div className={styles.audioWrapper}>
        <audio
          controls
          controlsList="nodownload noplaybackrate"
          src={soundFile.file}
          style={{
            pointerEvents: isDisabled ? 'none' : 'auto',
            opacity: isDisabled ? 0.5 : 1,
          }}
          onEnded={() => markAsListened(soundFile)}
          onPlaying={() => markAsPlaying(soundFile.id ?? null)}
          onPause={() => markAsPlaying(null)}
        />
      </div>
    </div>
  </div>
);

export default SoundRanking;
