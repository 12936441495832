import { ControlledInputProps } from 'components/Forms/ControlledField/input';

const editUserStructure: ControlledInputProps[] = [
  {
    type: 'text',
    name: 'username',
    label: 'Username',
    placeholder: 'Username',
    disabled: true,
  },
  {
    type: 'text',
    name: 'email',
    label: 'Email',
    placeholder: 'Email',
  },
  {
    name: 'company',
    type: 'companies_select',
    label: 'Company',
  },
  {
    name: 'is_active',
    type: 'checkbox',
    title: 'Is active user',
    label: 'Enabled',
    labelFalse: 'Disabled',
  },
  {
    name: 'is_staff',
    type: 'checkbox',
    title: 'Is staff member',
    label: 'Member',
    labelFalse: 'Not a member',
  },
  {
    name: 'is_superuser',
    type: 'checkbox',
    title: 'Is admin',
    label: 'Admin',
    labelFalse: 'Not an admin',
  },
];

export default editUserStructure;
