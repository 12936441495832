import { ControlledInputProps } from 'components/Forms/ControlledField/input';

const usersListStructure: ControlledInputProps[] = [
  {
    name: 'name',
    type: 'text',
    placeholder: 'Company name',
  },
];

export default usersListStructure;
