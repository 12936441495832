import fetchApiNoAuth from 'api/fetchApiNoAuth';
import config from 'api/config';

type TUserPayload = {
  email: string;
};

interface IErrorResponse {
  email: string[];
}

const resetPassword = async (
  args: TUserPayload,
): Promise<{ success: boolean; detail: string }> => {
  const { success, status, payload } = await fetchApiNoAuth({
    method: 'POST',
    payload: { email: args.email },
    path: config.resetPass,
  });

  const toReturn = { success: false, detail: 'Something went wrong.' };

  if (success && status === 204) {
    toReturn.success = true;
    toReturn.detail = `Email has been sent: ${args.email}.`;
  } else {
    toReturn.success = false;
    toReturn.detail = '';
    Object.entries((payload as IErrorResponse) || {}).forEach((entry) => {
      const [fieldName, fieldValue] = entry;
      toReturn.detail += `${fieldName}: ${fieldValue} `;
    });
  }
  return toReturn;
};

export default resetPassword;
