import groupPost from 'api/groups/groupPost';

export type TGroupsCreationPayload = {
  name: string;
  users: number[];
  packages: number[];
};

const groupsCreate = async (args: TGroupsCreationPayload) => {
  return await groupPost(args);
};

export default groupsCreate;
