import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import ResetPasswordForm from 'components/ResetPasswordForm';

const ResetPassword: React.FC<RouteComponentProps> = ({ location }) => {
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');
  const uid = searchParams.get('uid');

  if (!token || !uid) {
    return <Redirect to="/statistics" />;
  }

  return <ResetPasswordForm token={token || ''} uid={uid || ''} />;
};

export default ResetPassword;
