import { useCallback, useEffect, useState } from 'react';
import rankingCandidatesGet from 'api/ranking/rankingCandidatesGet';
import soundFileGet from 'api/ranking/soundFileGet';
import { ISoundFile } from 'api/ranking/_types';

const initialFilesValue: [ISoundFile, ISoundFile] = [
  { file: '', id: 0 },
  { file: '', id: 0 },
];

interface HookResult {
  loading: boolean;
  error: string;
  nextCandidates: () => void;
  markAsListened: (fileToMark: ISoundFile) => void;
  files: [ISoundFile, ISoundFile];
}

const useSoundsRanking = (): HookResult => {
  const [files, setFiles] =
    useState<[ISoundFile, ISoundFile]>(initialFilesValue);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(true);

  const fetchCandidates = useCallback(async () => {
    setLoading(true);

    const receivedSound = await rankingCandidatesGet();

    if (
      receivedSound &&
      receivedSound.payload &&
      receivedSound.payload.annotation0 &&
      receivedSound.payload.annotation1
    ) {
      const filesFetching = [
        soundFileGet(receivedSound?.payload.annotation0),
        soundFileGet(receivedSound?.payload.annotation1),
      ];
      const [file1, file2] = await Promise.all(filesFetching);

      const receivedFile0 = {
        ...file1,
        id: receivedSound?.payload.annotation0,
      };
      const receivedFile1 = {
        ...file2,
        id: receivedSound?.payload.annotation1,
      };

      if (receivedFile0 && receivedFile1) {
        setFiles([receivedFile0, receivedFile1]);
      }
    } else if (receivedSound?.payload?.detail) {
      setError(receivedSound?.payload?.detail);
    }
    setLoading(false);
  }, []);

  const nextCandidates = useCallback(() => {
    setError('');
    setFiles(initialFilesValue);
  }, []);

  const markAsListened = useCallback(
    (fileToMark: ISoundFile) => {
      setFiles(
        (files) =>
          files.map((file) =>
            fileToMark === file ? { ...file, listened: true } : file,
          ) as [ISoundFile, ISoundFile],
      );
    },
    [files],
  );

  useEffect(() => {
    if (files === initialFilesValue) {
      fetchCandidates();
    }
  }, [fetchCandidates, files]);

  return {
    loading,
    files,
    error,
    nextCandidates,
    markAsListened,
  };
};

export default useSoundsRanking;
