import config from 'api/config';
import fetchApi from 'api/fetchApi';
import { IPackagesOptionsData } from './_types';

const packagesOPTIONS = async (): Promise<IPackagesOptionsData | null> => {
  const { success, payload } = await fetchApi<IPackagesOptionsData>({
    method: 'OPTIONS',
    path: `${config.packages}`,
  });

  if (success) return payload;
  return null;
};

export default packagesOPTIONS;
