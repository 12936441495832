import React, { useCallback } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';
import { ContentType } from 'recharts/types/component/DefaultLegendContent';
import styles from './chart.module.css';
import ChartLegend from './chart.legend';
import ChartActiveEl from './chart.activeEl';

const COLORS = [
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
];

export interface IChartItem {
  value: number;
  name: string;
  id: number;
}
export type TChartData = IChartItem[];

export type ChartElementProps = { payload: IChartItem };

const Chart: React.FC<{
  data: TChartData;
  title: string;
  activeId: number | null;
  setActiveElement: (id: number) => void;
}> = ({ data, activeId, setActiveElement, title }) => {
  data.sort((item, next) => next.value - item.value);

  const renderLegend: ContentType = useCallback(
    ({ payload }) => (
      <ChartLegend
        chartProps={payload}
        setActiveElement={setActiveElement}
        activeId={activeId}
      />
    ),
    [setActiveElement, activeId],
  );

  const activeElement = activeId
    ? data.find(({ id }) => activeId === id)
    : undefined;
  const activeIndex = activeElement ? data.indexOf(activeElement) : undefined;

  const onClickPie = useCallback(
    (item: ChartElementProps) => {
      setActiveElement(item.payload.id);
    },
    [setActiveElement],
  );

  return (
    <div className={styles.root} role="figure" aria-label={title}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Pie
            activeIndex={activeIndex}
            activeShape={(data) => <ChartActiveEl {...data} />}
            onClick={onClickPie}
            data={data}
            cx="50%"
            cy="50%"
            className={styles.pie}
            outerRadius={80}
            innerRadius={60}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                name={entry.name}
              />
            ))}
          </Pie>
          <Legend
            iconSize={10}
            layout="vertical"
            align="right"
            wrapperStyle={{
              height: '100%',
              padding: '0 0 0 15px',
              right: 0,
              top: 0,
            }}
            verticalAlign="top"
            content={renderLegend}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
