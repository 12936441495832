import { ICandidates } from 'api/ranking/_types';
import fetchApi from 'api/fetchApi';
import config from 'api/config';
import extendSession from 'api/extendSession';

interface SuccessResponse {
  success: true;
  candidates: ICandidates;
}

interface SuccessError {
  success: false;
  message: string;
}

const candidatesComparisonPost = async (comparison: {
  result_annotation: number;
  annotation0: number;
  annotation1: number;
}): Promise<SuccessResponse | SuccessError> => {
  const extended = await extendSession();
  if (extended) {
    const response = await fetchApi<ICandidates>({
      method: 'POST',
      path: `${config.ranking}comparison/`,
      payload: comparison,
    });
    if (response.success && response.payload) {
      return {
        success: true,
        candidates: response.payload,
      };
    }
  }

  return {
    success: false,
    message: 'Failed to vote.',
  };
};

export default candidatesComparisonPost;
