import config from 'api/config';
import fetchApi from 'api/fetchApi';

export interface IUser {
  id: number;
  email: string;
  username: string;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  company: number | null;
}

export interface IUsersData {
  success: boolean;
  count?: number;
  next?: string;
  previous?: string;
  results?: IUser[];
}

export type TUsersGETPayload = {
  params: string;
};

type TUsersGETReturnType = IUsersData;

const usersGET = async (
  args: TUsersGETPayload,
): Promise<TUsersGETReturnType> => {
  const response = await fetchApi<IUsersData>({
    method: 'GET',
    path: `${config.accountsUsers}?${args.params}`,
  });

  const { success, payload } = response;

  return {
    success: !!success,
    count: payload?.count || 0,
    next: payload?.next || '',
    previous: payload?.previous || '',
    results: payload?.results || [],
  };
};

export default usersGET;
