import React from 'react';
import styles from 'components/StatisticsDashboard/statisticsDashboard.module.css';
import StatisticsForm from 'components/StatisticsForm';
import { useFilterableDataList } from 'components/FilteralbeDataList/filteralbeDataList';
import { UserStats } from 'api/statistics/statsUsersGet';
import StatisticsUser from 'components/StatisticsUser';
import useUser from 'hooks/useUser';
import ControlledInput from 'components/Forms/ControlledField';

const UserDashboard: React.FC = () => {
  const { data, error, loading } = useFilterableDataList<UserStats[]>();
  const { login, userId } = useUser();
  let content;
  if (loading) {
    content = <div className={styles.message}>Loading...</div>;
  } else if (error || !data || !data[0]) {
    content = <div className={styles.message}>No data...</div>;
  } else {
    content = <StatisticsUser data={data[0]} username={login} />;
  }

  return (
    <div className={styles.root}>
      <StatisticsForm />
      <ControlledInput initialValue={userId} name="user" type="hidden" />
      {content}
    </div>
  );
};

export default UserDashboard;
