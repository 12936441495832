import config from 'api/config';
import fetchApi from 'api/fetchApi';
import { IGroup, IGroupError } from './_types';

type TGroupsCreationPayload = {
  name: string;
  users: number[];
  packages: number[];
};

type tGroupPost = (args: TGroupsCreationPayload) => Promise<
  | {
      payload: IGroup;
      success: true;
      message: string;
    }
  | {
      success: false;
      message: string;
    }
>;

const isCreated = (payload: null | IGroup | IGroupError): payload is IGroup =>
  payload !== null && 'id' in payload;

const groupPost: tGroupPost = async (args) => {
  const { payload, success } = await fetchApi<IGroup | IGroupError>({
    method: 'POST',
    path: `${config.groups}`,
    payload: args,
  });
  if (success && isCreated(payload)) {
    return {
      success: true,
      payload,
      message: `Group ${payload.name} created.`,
    };
  }
  const message = Array.isArray(payload?.name) ? payload?.name[0] : undefined;
  return {
    success: false,
    message: message || 'Something went wrong',
  };
};

export default groupPost;
