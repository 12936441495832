import styles from 'components/AnnotationRegions/annotationRegions.module.css';
import { useEffect, useState } from 'react';
import useSuggestions from 'components/AnnotationRegions/AnnotationRow/useSuggestions';
import cx from 'classnames';

const Suggestions = (params: { inputValue: string, active: boolean, onSelect: (value: string) => void, blacklist: string[], whitelist: string[] }) => {
  const [selected, setSelected] = useState(0);
  const { suggestions, loadingSuggestions } = useSuggestions(params.active ? params.inputValue : '', params.blacklist, params.whitelist);
  useEffect(() => {
    if (!params.active) {
      return;
    }
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'ArrowUp') {
        if (selected > 0) {
          e.preventDefault();
          e.stopPropagation();
          setSelected(selected - 1);
        }
        return;
      }
      if (e.key === 'ArrowDown') {
        if (selected < suggestions.length - 1) {
          e.preventDefault();
          e.stopPropagation();
          setSelected(selected + 1);
        }
        return;
      }
      if (e.key === 'Enter') {
        e.preventDefault();
        e.stopPropagation();
        const suggestion = suggestions[selected];
        if (suggestion) {
          params.onSelect(suggestion);
        }
        return;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [params.active, suggestions, selected]);

  useEffect(() => {
    if (!params.active) {
      setSelected(0);
    }
  }, [params.active]);

  return params.active && params.inputValue.length >= 2 ? (
    <ul className={styles.region__input__suggestions}>
      {suggestions.length > 0 &&
        suggestions.map((item, index) => (
          <li
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onClick={() => {
              params.onSelect(item);
            }}
            onMouseEnter={() => setSelected(index)}
            className={cx(styles.region__input__suggestions__item, {
              [styles.region__input__suggestions__item__hovered]:
                index === selected,
            })}
            key={item}
          >
            {item}
          </li>
        ))}
      {suggestions.length === 0 && (
        <li className={styles.region__input__suggestions__item}>
          {loadingSuggestions ? 'Loading...' : 'No suggestions found'}
        </li>
      )}
    </ul>
  ) : null;
};

export default Suggestions;
