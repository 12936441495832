import React from 'react';
import { ReactComponent as IconTrash } from 'icons/heroicons/trash.svg';
import { ReactComponent as IconEdit } from 'icons/heroicons/pencil.svg';
import { ReactComponent as IconCheck } from 'icons/heroicons/check-circle.svg';
import { ReactComponent as IconArrowUp } from 'icons/heroicons/arrow-up.svg';
import { ReactComponent as IconArrowDown } from 'icons/heroicons/arrow-down.svg';
import { ReactComponent as IconStatistics } from 'icons/heroicons/trending-up.svg';
import { ReactComponent as IconRanking } from 'icons/heroicons/chart-bar.svg';
import { ReactComponent as IconAnnotations } from 'icons/heroicons/volume-up.svg';
import { ReactComponent as IconGroups } from 'icons/heroicons/user-group.svg';
import { ReactComponent as IconUsers } from 'icons/heroicons/users.svg';
import { ReactComponent as IconPackages } from 'icons/heroicons/archive.svg';
import { ReactComponent as IconHamburger } from 'icons/heroicons/menu.svg';
import { ReactComponent as IconExport } from 'icons/heroicons/external-link.svg';
import { ReactComponent as IconCompany } from 'icons/heroicons/office-building.svg';
import { ReactComponent as IconFilterRemove } from 'icons/heroicons/x.svg';
import { ReactComponent as IconInfo } from 'icons/heroicons/information-circle.svg';
import { ReactComponent as IconUpload } from 'icons/heroicons/cloud-upload.svg';
import { ReactComponent as IconX } from 'icons/heroicons/x-circle.svg';
import { ReactComponent as Play } from 'icons/heroicons/play.svg';

const ICONS_MAP = {
  trash: IconTrash,
  edit: IconEdit,
  check: IconCheck,
  'arrow-up': IconArrowUp,
  'arrow-down': IconArrowDown,
  statistics: IconStatistics,
  ranking: IconRanking,
  annotations: IconAnnotations,
  groups: IconGroups,
  users: IconUsers,
  packages: IconPackages,
  hamburger: IconHamburger,
  export: IconExport,
  upload: IconUpload,
  'remove-filters': IconFilterRemove,
  info: IconInfo,
  company: IconCompany,
  play: Play,
  x: IconX,
};

const Icon: React.FC<{
  name: keyof typeof ICONS_MAP;
  attributes?: { [key: string]: unknown };
}> = ({ name, attributes = {} }) => {
  const IconComponent = ICONS_MAP[name] || null;

  const defaultAttributes = {
    width: 50,
    height: 50,
  };

  return IconComponent ? (
    <IconComponent stroke="#000" {...defaultAttributes} {...attributes} />
  ) : null;
};

export default Icon;
