import React from 'react';
import finishingButtonStyles from 'components/ButtonConfirm/buttonConfirm.module.css';
import ButtonConfirm from 'components/ButtonConfirm';
import styles from '../annotationRegions.module.css';
import useForm from 'hooks/useForm';
import { useWavesurfer } from 'components/WavesurferJS/wavesurfer.context';
import { getFieldName } from 'components/AnnotationRegions/AnnotationRow/annotationRow';

const finishBtnAttributes = {
  className: finishingButtonStyles.root,
  id: 'annotation-confirm',
};

interface iProps {
  handleOnSubmit: () => void;
}

const AnnotationSubmitButton: React.FC<iProps> = ({ handleOnSubmit }) => {
  const { isAllowed, message } = useSubmittingIsAllowed();
  const {
    form: { isSubmitting },
  } = useForm();
  return (
    <>
      <div className={styles.submit__button_wrapper}>
        <ButtonConfirm
          onClick={handleOnSubmit}
          {...finishBtnAttributes}
          disabled={!isAllowed || isSubmitting}
        >
          Finish and confirm - cannot be undone
        </ButtonConfirm>
        {message ? (
          <span className={styles.submit__button_warning}>{message}</span>
        ) : null}
      </div>
    </>
  );
};

const useSubmittingIsAllowed = () => {
  const { form } = useForm();
  const { soundDetails, regions } = useWavesurfer();

  if (!soundDetails?.settings.can_be_accepted) {
    const invalid = regions.find((region) => {
      return (
        !form.values[getFieldName(region, 'label')] &&
        !form.values[getFieldName(region, 'unusable')]
      );
    });
    return {
      isAllowed: !invalid && form.valid,
      message: invalid
        ? 'All annotations have to have label or be marked as unusable.'
        : null,
    };
  }
  const invalid = regions.find((region) => {
    const unusable = form.values[getFieldName(region, 'unusable')];
    const accepted = form.values[getFieldName(region, 'accepted')];
    const label = form.values[getFieldName(region, 'label')];
    return (!accepted && !unusable) || (!label && !unusable);
  });
  return {
    isAllowed: !invalid && form.valid,
    message: invalid
      ? 'All annotations need to be accepted or marked as unusable.'
      : null,
  };
};

export default AnnotationSubmitButton;
