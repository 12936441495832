import React from 'react';
import Field from 'components/Forms/ControllerForField';
import styles from './paging.module.css';
import cx from 'classnames';

const Paging: React.FC<{
  max: number;
  loading?: boolean;
  callback?: () => void;
}> = ({ max = 1, loading = false, callback }) => (
  <Field name="_page" type="custom" initialValue={1}>
    {({ value, setField }) => {
      const receivedValue = value as number;
      const lower = receivedValue - 1 > 0 ? receivedValue - 1 : 1;
      const upper = receivedValue + 1 <= max ? receivedValue + 1 : max;
      const pages = getPagesToDisplay(value ? +value : 1, max);
      return (
        <div className={styles.root}>
          <div className={styles.positioner}>
            <button
              type="button"
              className={cx(styles.button__step, styles.button__step__previous)}
              disabled={!value || value <= 1}
              onClick={() => {
                if (!loading) setField(lower);
                if (typeof callback === 'function') callback();
              }}
            >
              -
            </button>
            {pages.map((page, index) => {
              if (page === EmptyButton) {
                return (
                  <span key={`empty-${index}`} className={styles.result}>
                    ...
                  </span>
                );
              }
              const isCurrent = page === value;
              return (
                <button
                  onClick={() => {
                    setField(page);
                    if (typeof callback === 'function') callback();
                  }}
                  disabled={isCurrent}
                  key={page}
                  className={isCurrent ? styles.result_active : styles.result}
                >
                  {page}
                </button>
              );
            })}
            <button
              type="button"
              className={cx(styles.button__step, styles.button__step__next)}
              disabled={!!value && value === max}
              onClick={() => {
                if (!loading) setField(upper);
                if (typeof callback === 'function') callback();
              }}
            >
              +
            </button>
            {loading ? <div className={styles.loading} /> : null}
          </div>
        </div>
      );
    }}
  </Field>
);
const EmptyButton = 0;
const getPagesToDisplay = (page: number, max: number) => {
  if (max <= 5) {
    return new Array(max).fill('').map((_, id) => id + 1);
  }
  if (page <= 2) {
    return [1, 2, 3, EmptyButton, max];
  }
  if (page >= max - 1) {
    return [1, EmptyButton, max - 2, max - 1, max];
  }
  if (page === 3) {
    return [1, 2, 3, 4, EmptyButton, max];
  }
  if (page === max - 2) {
    return [1, EmptyButton, max - 3, max - 2, max - 1, max];
  }
  return [1, EmptyButton, page - 1, page, page + 1, EmptyButton, max];
};

export default Paging;
