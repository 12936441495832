import { useState, useEffect } from 'react';
import { CompanyOption } from 'hooks/useCompaniesOptions';
import { TFieldValue } from 'components/Forms';

const useCompanyName = (
  companiesOptions: CompanyOption[],
  id: TFieldValue,
): string | null => {
  const [result, setResult] = useState<string>('');

  useEffect(() => {
    if (id === null) {
      setResult('');
      return;
    }
    const matchedOption = companiesOptions.find(({ value }) => value === id);
    setResult(matchedOption !== undefined ? matchedOption.label : '');
  }, [id, companiesOptions]);

  return result;
};

export default useCompanyName;
