import React from 'react';
import logoSrc from 'components/../assets/logo--white.png';
import LogoutButton from 'components/Logout';
import Icon from 'components/Icon';
import styles from './header.module.css';

const iconAttribs = {
  width: 30,
  height: 30,
  stroke: 'white',
};
const Header: React.FC<{
  toggleAsideExpanded: () => void;
  asideExpanded: boolean;
}> = ({ toggleAsideExpanded }) => (
  <div className={styles.root}>
    <button onClick={toggleAsideExpanded} className={styles.hamburger}>
      <Icon attributes={iconAttribs} name="hamburger" />
    </button>
    <h1 className={styles.title}>Web Annotation Tool</h1>
    <div className={styles.logoContainer}>
      <img className={styles.logo} alt="Sound Intelligence" src={logoSrc} />
    </div>
    <LogoutButton />
  </div>
);

export default Header;
