import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { init as sentryInit } from '@sentry/react';

const root = document.querySelector('#root');

if (process.env.NODE_ENV === 'production') {
  sentryInit({
    dsn: 'https://5152140c27d84c6eafc5c3eaa763b358@sentry.amsdard.io/348',
  });
}

const renderApp = (Component: React.FC) => {
  ReactDOM.render(
    <React.StrictMode>
      <Component />
    </React.StrictMode>,
    root,
  );
};
renderApp(App);

if (module?.hot) {
  module.hot.accept('./app', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require('./app').default;
    renderApp(NextApp);
  });
}
