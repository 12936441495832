import config from 'api/config';
import fetchApi from 'api/fetchApi';

type tAudidbImportPost = () => Promise<
  | {
      success: true;
    }
  | {
      success: false;
      message: string;
    }
>;

const audidbImportPost: tAudidbImportPost = async () => {
  const { payload, success } = await fetchApi<{ detail?: string }>({
    method: 'POST',
    path: `${config.audiodbPackagesImport}`,
  });
  if (success) {
    return {
      success: true,
    };
  }
  return {
    success: false,
    message: payload?.detail || 'Something went wrong',
  };
};

export default audidbImportPost;
