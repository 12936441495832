import { ControlledInputProps } from 'components/Forms/ControlledField/input';

const editUserStructure: ControlledInputProps[] = [
  {
    name: 'name',
    type: 'text',
    label: 'Company name',
    placeholder: 'Company name',
  },
];

export default editUserStructure;
