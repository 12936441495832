import React from 'react';
import styles from './packagesManage.module.css';
import FilterInput from 'components/FilteralbeDataList/filter';

const PackagesRowFilters: React.FC<{
  title: string;
  fieldIsFinishedEqualTo: boolean;
}> = ({ title, fieldIsFinishedEqualTo }) => (
  <>
    <div className={styles.filters}>
      <div className={styles.filters__header}>
        <div className={styles.filters__headerInner}>{title}</div>
      </div>
      <div className={styles.filters__byId}>
        <FilterInput name="id" type="text" placeholder="Id" label="Id" />
      </div>
      <div className={styles.filters__search}>
        <FilterInput
          name="name"
          type="text"
          placeholder="Search by name"
          label="Search by name"
        />
      </div>
      <div className={styles.filters__byStatus}>
        <FilterInput
          name="status"
          type="select"
          label="Search by status"
          options={statusOptions}
        />
      </div>
      <div className={styles.filters__byStatus}>
        <FilterInput
          name="o"
          type="select"
          initialValue="priority"
          label="Order"
          options={sortOptions}
        />
      </div>
      <div className={styles.filters__hiddenField}>
        <FilterInput
          initialValue={fieldIsFinishedEqualTo}
          name="is_finished"
          type="hidden"
          placeholder="Search by id"
          disabled
          hidden
        />
      </div>
    </div>
  </>
);

const sortOptions = [
  { label: 'id', value: 'id' },
  { label: 'id (descending)', value: '-id' },
  { label: 'name', value: 'name' },
  { label: 'name (descending)', value: '-name' },
  { label: 'status', value: 'status' },
  { label: 'status (descending)', value: '-status' },
  { label: 'priority', value: 'priority' },
  { label: 'priority (descending)', value: '-priority' },
];

const statusOptions = [
  { label: 'new', value: 'new' },
  { label: 'uploaded', value: 'uploaded' },
  { label: 'validated', value: 'validated' },
  { label: 'annotated', value: 'annotated' },
  { label: 'sent', value: 'sent' },
  { label: 'none', value: '' },
];

export default PackagesRowFilters;
