import AnnotationActions from 'components/AnnotationActions';
import Wavesurfer from 'components/WavesurferJS';
import AnnotationRegions from 'components/AnnotationRegions';
import React, { useEffect, useState } from 'react';
import useKeyboardNavigation from 'pages/AnnotationTool/useKeyboardNavigation';
import AnnotationForm from 'components/AnnotationForm';
import fetchApi from 'api/fetchApi';
import config from 'api/config';

const AnnotationTool: React.FC = () => {
  const [blacklist, setBlacklist] = useState<string[]>([]);
  const [whitelist, setWhitelist] = useState<string[]>([]);

  async function fetch() {
    for (const { path, set } of [{ path: config.labelsBlacklist, set: setBlacklist }, { path: config.labelsWhitelist, set: setWhitelist }]) {
      const response = await fetchApi<[{ label: string }]>({
        method: 'GET',
        path,
      });
      if (response.success && response.payload)
        set(response.payload.map(l => l.label));
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  useKeyboardNavigation();
  return (
    <Wavesurfer>
      <AnnotationForm>
        <AnnotationActions />
        <AnnotationRegions blacklist={blacklist} whitelist={whitelist} />
      </AnnotationForm>
    </Wavesurfer>
  );
};

export default AnnotationTool;
