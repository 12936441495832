import { FormInputProps } from 'components/Forms';
import React, { SelectHTMLAttributes } from 'react';
import restyle, { TStylesheet } from 'components/../restyler';
import defaultStyles from './input.module.css';

export interface IOption {
  label: string;
  value: string | number | undefined;
}

export interface SelectProps
  extends SelectHTMLAttributes<HTMLSelectElement>,
    FormInputProps {
  label?: string;
  styles?: TStylesheet;
  options: IOption[];
}

const Input: React.FC<SelectProps> = (props) => {
  const {
    value = '',
    setField = () => '',
    message = '',
    label = '',
    options = [],
    touched = false,
    styles: overriddenStyles,
    ...restSelectProps
  } = props;
  const styles = restyle(defaultStyles, overriddenStyles);

  return (
    <div className={styles.root}>
      <label className={styles.label}>
        <span className={styles.label__span}>{label}</span>
        <select
          {...restSelectProps}
          className={styles.input}
          onChange={(e) => {
            const { value } = e.currentTarget;
            setField(value);
          }}
          value={value}
          data-error={message && touched ? true : undefined}
        >
          <option hidden disabled value="" />
          {options.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </select>
      </label>
      <span className={styles.message}>{touched && message}</span>
    </div>
  );
};

export default Input;
