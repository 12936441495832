const mergeState = function <StateType>(
  state: StateType,
  newState: StateType,
  setState: (arg: StateType) => void,
): void {
  setState({
    ...state,
    ...newState,
  });
};

export default mergeState;
