import config from 'api/config';
import fetchApi from 'api/fetchApi';
import { IPackage } from './_types';

type tPackagesPost = (args: {
  packageId: IPackage['id'];
  packageData: IPackage;
}) => Promise<{
  payload?: IPackage | null;
  success: boolean;
  message: string;
}>;

const packagesPostSetUploaded: tPackagesPost = async (args) => {
  const urlStr = config.packagesSetUploaded.replace(
    /\{(.+?)\}/g,
    () => `${args.packageId}`,
  );

  const { success, payload, status } = await fetchApi<
    IPackage & { detail?: string }
  >({
    method: 'POST',
    path: urlStr,
    payload: args.packageData,
  });

  if (success) {
    return {
      success: true,
      payload,
      message: `Package ${args.packageData.name} uploaded.`,
    };
  }
  if ((status || 400) > 399 && payload) {
    let message = '';
    Object.entries(payload).forEach((entry) => {
      const [name, val] = entry;
      message += `${name.toUpperCase()} - ${
        val.join ? val.join('') : JSON.stringify(val)
      } `;
    });
    message = message ? `Errors! ${message}` : 'Something went wrong';
    return {
      success: false,
      message,
    };
  }
  return {
    success: false,
    message:
      typeof payload?.detail === 'string'
        ? payload.detail
        : 'Something went wrong',
  };
};

export default packagesPostSetUploaded;
