import React from 'react';
import logoSrc from 'components/../assets/logo.png';
import styles from '../loginForm.module.css';

const Header: React.FC = () => (
  <>
    <img className={styles.logo} alt="Sound Intel Logo" src={logoSrc} />
    <div className={styles.subtitle}>Web Annotation Tool</div>
  </>
);

export default Header;
