import packagesGet, { IPackagesData } from 'api/packages/packagesGet';
import Paging from 'components/FilteralbeDataList/paging';
import TitledSection from 'components/TitledSection';
import React from 'react';
import FilterableDataList, {
  DEFAULT_PAGE_SIZE,
  useFilterableDataList,
} from 'components/FilteralbeDataList/filteralbeDataList';
import styles from './packagesCompleted.module.css';
import PackagesRowFilters from '../PackagesManage/packagesRowFilters';

const fetchPackagesList = async (url: string) =>
  await packagesGet({ params: url });

const headers = (
  <li className={styles.list__item}>
    <div className={styles.list__itemID}>Id</div>
    <div className={styles.list__package}>Name</div>
    <div className={styles.list__status}>Status</div>
    <div className={styles.list__uploaded}>Uploaded by</div>
    <div className={styles.list__completed}>Completed on</div>
    <div className={styles.list__statistics}>Statistics</div>
  </li>
);

const PackagesCompleted: React.FC = () => (
  <TitledSection title="Packages completed" className={styles.root}>
    <FilterableDataList
      pagination
      fetchData={fetchPackagesList}
      name="packagesCompletedList"
    >
      <PackagesRowFilters
        title="Filter completed packages"
        fieldIsFinishedEqualTo
      />
      <List />
      <Pagination />
    </FilterableDataList>
  </TitledSection>
);

const List: React.FC = () => {
  const { loading, error, data } = useFilterableDataList<IPackagesData>();

  const getContent = () => {
    if (loading) {
      return <span>Loading...</span>;
    }
    if (error || !data || data.count === 0) {
      return <span>No data found...</span>;
    }
    return data.results?.map((item) => (
      <li key={item.id} className={styles.list__item}>
        <div className={styles.list__itemID}>{item.id}</div>
        <div className={styles.list__package}>{item.name}</div>
        <div className={styles.list__status}>{item.status}</div>
        <div className={styles.list__uploaded}>{item.uploaded_by}</div>
        <div className={styles.list__completed}>{item.completed_on}</div>
        <div className={styles.list__statistics}>{item.statistics}</div>
      </li>
    ));
  };

  const content = getContent();

  return (
    <ul className={styles.list}>
      {headers}
      {content}
    </ul>
  );
};

const Pagination = () => {
  const { loading, data } = useFilterableDataList<IPackagesData>();
  const pagesAmount = Math.ceil((data?.count || 0) / DEFAULT_PAGE_SIZE) || 0;
  return (
    <div className={styles.footer}>
      <Paging max={pagesAmount} loading={loading} />
    </div>
  );
};

export default PackagesCompleted;
