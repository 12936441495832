import config from 'api/config';
import fetchApi from 'api/fetchApi';
import extendSession from 'api/extendSession';
type ErrorsType = Record<string, string | Record<string, string>[] | string[]>;

export interface IAnnotatorSoundData {
  sound_entry: string;
  sound_unusable?: boolean;
  annotations: {
    annotation?: string;
    start: number;
    end: number;
    unusable?: boolean;
    accepted: boolean;
    label: string;
  }[];
}

type Response =
  | {
      success: true;
    }
  | {
      success: false;
      message: string;
    };

const saveAnnotatePost = async (
  soundData: IAnnotatorSoundData,
): Promise<Response> => {
  try {
    const extended = await extendSession();
    if (!extended) {
      return {
        success: false,
        message: 'Failed To save.',
      };
    }

    const response = await fetchApi<ErrorsType>({
      method: 'POST',
      path: `${config.saveAnnotate}`,
      payload: soundData,
    });
    if (response.success) {
      return { success: true };
    }
    let errorMessage = 'Failed To save.';
    if (response.payload) {
      const errors = parseErrors(response.payload).join(', ');
      errorMessage = `${errorMessage} ${errors}`;
    }

    return {
      success: false,
      message: errorMessage,
    };
  } catch (e) {
    return {
      success: false,
      message: 'Failed To save.',
    };
  }
};

const parseErrors = (errors: ErrorsType): string[] => {
  return Object.keys(errors).map((field) => {
    const fieldErrors = errors[field] || '';
    if (typeof fieldErrors === 'string') {
      return `${field}: ${fieldErrors}`;
    } else {
      const errors = fieldErrors
        .map((fieldError) => {
          if (typeof fieldError === 'string') {
            return fieldError;
          }
          return parseErrors(fieldError).join(', ');
        })
        .join(', ');
      return `${field}: ${errors}`;
    }
  });
};

export default saveAnnotatePost;
