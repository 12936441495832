import React, { useEffect, useState } from 'react';
import TitledSection from 'components/TitledSection';
import { UserStats } from 'api/statistics/statsUsersGet';
import styles from './statisticsUser.module.css';

interface TableRow {
  count: number;
  time: string;
}

interface TableData {
  annotated: TableRow;
  validated: TableRow;
  unusable: TableRow;
  total: TableRow;
}

const forceTwoDigits = (number: number): string =>
  number > 9 ? `${number}` : `0${number}`;

const parseTime = (time: number): string => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  const seconds = Math.floor(time - (hours * 3600 + minutes * 60));
  return `${forceTwoDigits(hours)}:${forceTwoDigits(minutes)}:${forceTwoDigits(
    seconds,
  )}`;
};

interface StatisticsUserProps {
  data: UserStats | undefined;
  username: string | null;
}

const StatisticsUser: React.FC<StatisticsUserProps> = ({ data, username }) => {
  const [tableData, setTableData] = useState<TableData>();
  useEffect(() => {
    setTableData(
      data
        ? {
          annotated: {
            count: data.annotated_count,
            time: parseTime(data.annotated_time),
          },
          validated: {
            count: data.validated_count,
            time: parseTime(data.validated_time),
          },
          unusable: {
            count: data.unusable_count,
            time: parseTime(data.unusable_time),
          },
          total: {
            count:
                data.annotated_count +
                data.validated_count +
                data.unusable_count,
            time: parseTime(
              data.annotated_time + data.validated_time + data.unusable_time,
            ),
          },
        }
        : undefined,
    );
  }, [data]);
  if (!tableData) {
    return null;
  }

  return (
    <div className={styles.root} data-testid="userStatsDetails">
      <TitledSection title={username} className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              <th>Files</th>
              <th>Labels</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Annotated</td>
              <td>{tableData.annotated.count}</td>
              <td>{tableData.annotated.time}</td>
            </tr>
            <tr>
              <td>Validated</td>
              <td>{tableData.validated.count}</td>
              <td>{tableData.validated.time}</td>
            </tr>
            <tr>
              <td>Unusable</td>
              <td>{tableData.unusable.count}</td>
              <td>{tableData.unusable.time}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <th>{tableData.total.count}</th>
              <th>{tableData.total.time}</th>
            </tr>
          </tfoot>
        </table>
      </TitledSection>
    </div>
  );
};

export default StatisticsUser;
