import React, { SetStateAction, useEffect, useState } from 'react';

const useLocalStorageValue = <T>(
  storageKey: string,
  defaultValue: T,
): [T, React.Dispatch<SetStateAction<T>>] => {
  const initValue = safelyParseJson<T>(localStorage.getItem(storageKey) || '');
  const [value, setValue] = useState<T>(
    initValue === null ? defaultValue : initValue,
  );

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [value === null ? defaultValue : value, setValue];
};

const safelyParseJson = <T>(parseString: string): T | null => {
  try {
    return JSON.parse(parseString);
  } catch (e) {
    return null;
  }
};

export default useLocalStorageValue;
