import styles from './groupsList.module.css';
import { ControlledInputProps } from 'components/Forms/ControlledField/input';

interface GroupsListStructureInterface {
  wrapperClass: string;
  field: ControlledInputProps;
}

const groupsListStructure: GroupsListStructureInterface[] = [
  {
    wrapperClass: styles.row__user,
    field: {
      name: 'name',
      type: 'text',
      placeholder: 'Group name',
    },
  },
];

export default groupsListStructure;
