import { TActionCreator } from '../_types';

export const SET_NOTICE = 'SET_NOTICE';
export const CANCEL_NOTICE = 'CANCEL_NOTICE';

export type TNoticeType = {
  message: string;
  type?: 'failure' | 'success' | 'message';
  id?: number;
};
export type TRemoveNotice = { id: number };

export const actionSetNotice: TActionCreator<typeof SET_NOTICE, TNoticeType> = (
  payload,
) => ({
  type: SET_NOTICE,
  payload,
});

export const actionCancelNotice: TActionCreator<
  typeof CANCEL_NOTICE,
  TRemoveNotice
> = (payload) => ({
  type: CANCEL_NOTICE,
  payload,
});
