import React, { useContext, useEffect } from 'react';
import FormContext from '../form.context';
import {
  TFormContext,
  TFieldValue,
  TFieldTouched,
  TFieldValidator,
  TFieldValid,
  TOnInputChange,
} from '../_types';

type TRenderProp = {
  name: string;
  value: TFieldValue;
  touched: TFieldTouched;
  errorMessage: TFieldValid;
  setField: (value: TFieldValue) => void;
};

const Field: React.FC<{
  children: (renderProp: TRenderProp) => JSX.Element;
  name: string;
  initialValue?: TFieldValue;
  initialTouched?: TFieldTouched;
  validate?: TFieldValidator | null;
  type: string;
}> = ({ name, initialValue, initialTouched, validate, children, type }) => {
  const formDataAndMethods = useContext(FormContext) as TFormContext;
  const { form, methods } = formDataAndMethods;

  const fieldValidMessage = form.valid[name];
  const fieldTouched = form.touched[name];
  const fieldValue = form.values[name];

  const isRegistered = !!(name in form.values);

  const wrapSetField: TOnInputChange = (value) => {
    methods.setField({ name, value });
  };

  const dataForRenderProp = {
    name,
    value: isRegistered ? fieldValue : initialValue,
    touched: isRegistered ? fieldTouched : Boolean(initialTouched),
    errorMessage: isRegistered ? fieldValidMessage : '',
    setField: wrapSetField,
  };

  useEffect(() => {
    if (isRegistered) return;

    const initialTypes = {
      checkbox: false,
      text: '',
      select: '',
      custom: undefined,
      password: '',
      date: '',
    };

    methods.registerField({
      name,
      value:
        initialValue === undefined
          ? initialTypes[type as keyof typeof initialTypes]
          : initialValue,
      touched: !!initialTouched,
      validate: typeof validate === 'function' ? validate : null,
      initialValue: initialValue,
    });
  }, [methods.registerField]);

  return children(dataForRenderProp);
};

export default Field;
