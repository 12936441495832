import React, { useContext } from 'react';
import { Region } from 'components/WavesurferJS/wavesurfer.plugins';
import { AnnotatePayload } from 'api/annotationTool/annotatePost';

interface IWavesurferContextData {
  playRegion: (region: Region) => void;
  removeRegion: (region: Region) => void;
  playWave: () => void;
  zoomValue: number;
  regions: Region[];
  changeZoom: (arg: number) => void;
  updateRegion: (region: Region, fields: Partial<Region>) => void;
  soundDetails?: AnnotatePayload;
  nextSound: () => void;
  reload: () => void;
  ready: boolean;
}

const WavesurferContext = React.createContext<
  IWavesurferContextData | undefined
>(undefined);

export const useWavesurfer = (): IWavesurferContextData => {
  const context = useContext(WavesurferContext);
  if (context === undefined) {
    throw Error('called useWavesurfer outside WavesurferContext');
  }
  return context;
};

export const WavesurferContextWrapper: React.FC<{
  data: IWavesurferContextData;
}> = ({ data, children }) => {
  return (
    <WavesurferContext.Provider value={data}>
      {children}
    </WavesurferContext.Provider>
  );
};
