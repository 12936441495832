import React, { useEffect, useRef, useState } from 'react';

interface RequiredHtmlElement {
  offsetWidth: number;
  offsetHeight: number;
}

const useElementSize = <T extends RequiredHtmlElement>(): {
  ref: React.RefObject<T> | undefined;
  width: number;
  height: number;
} => {
  const ref = useRef<T>(null);
  const [state, setState] = useState({ width: 0, height: 0 });
  useEffect(() => {
    setState({
      width: ref.current ? ref.current.offsetWidth : 0,
      height: ref.current ? ref.current.offsetHeight : 0,
    });
  }, [ref.current]);
  return {
    ref,
    ...state,
  };
};

export default useElementSize;
