import {
  TFieldValid,
  TForm,
  RegisterFieldPayload,
  SetFieldPayload,
  UnregisterFieldsPayload,
} from './_types';

type ReducerAction<T = void> = (state: TForm, payload: T) => TForm;

export function reducerSetClear(): TForm {
  return {
    initialValues: {},
    isSubmitting: false,
    touched: {},
    valid: {},
    validators: {},
    values: {},
  };
}

export const setSubmit: ReducerAction = (state) => {
  const allTouched: { [key: string]: boolean } = {};
  const allValid: { [key: string]: TFieldValid } = {};

  Object.keys(state.values).forEach((fieldName) => {
    const fieldValue = state.values[fieldName];
    const isValid = state.validators[fieldName]?.(fieldValue, state) || '';
    allTouched[fieldName] = true;
    allValid[fieldName] = isValid;
  });

  return {
    ...state,
    touched: allTouched,
    valid: allValid,
  };
};
export const reducerStartSubmit: ReducerAction = (state) => {
  return {
    ...state,
    isSubmitting: true,
  };
};

export const reducerStopSubmit: ReducerAction = (state) => {
  return {
    ...state,
    isSubmitting: false,
  };
};

export const reducerRegisterField: ReducerAction<RegisterFieldPayload> = (
  state,
  payload,
) => {
  const { values, touched, valid, validators, initialValues } = state;
  const { name, value, touched: isTouched, validate, initialValue } = payload;

  const fieldName = name;
  const fieldValue = value;

  const validateFunc = typeof validate === 'function' ? validate : () => '';

  const isValid =
    typeof validateFunc === 'function' ? validateFunc(value, state) : '';

  return {
    ...state,
    values: {
      ...values,
      [fieldName]: fieldValue,
    },
    touched: {
      ...touched,
      [fieldName]: !!isTouched,
    },
    valid: {
      ...valid,
      [fieldName]: isValid,
    },
    validators: {
      ...validators,
      [fieldName]: validateFunc,
    },
    initialValues: {
      ...initialValues,
      [fieldName]: initialValue,
    },
  };
};

export const reducerSetField: ReducerAction<SetFieldPayload> = (
  state,
  payload,
) => {
  const { values, touched, valid, validators } = state;
  const { name, value } = payload;
  const fieldName = name;
  const fieldValue = value;
  const isValid = validators[fieldName]?.(fieldValue, state) || '';

  return {
    ...state,
    values: {
      ...values,
      [fieldName]: fieldValue,
    },
    touched: {
      ...touched,
      [fieldName]: true,
    },
    valid: {
      ...valid,
      [fieldName]: isValid,
    },
    validators,
  };
};

export const reducerSetManyFields: ReducerAction<SetFieldPayload[]> = (
  state,
  payload,
) => {
  const { values, touched, valid, validators } = state;
  const newState = {
    ...state,
    values: { ...values },
    touched: { ...touched },
    valid: { ...valid },
    validators: { ...validators },
  };

  payload.forEach((field) => {
    const { name, value } = field;
    const fieldName = name;
    const fieldValue = value;
    const isValid = validators[fieldName]?.(fieldValue, state) || '';
    newState.values[fieldName] = fieldValue;
    newState.touched[fieldName] = true;
    newState.valid[fieldName] = isValid;
  });

  return newState;
};

export const reducerUnregisterManyFields: ReducerAction<UnregisterFieldsPayload> =
  (state, payload) => {
    const { values, touched, valid, validators } = state;
    const newState = {
      ...state,
      values: { ...values },
      touched: { ...touched },
      valid: { ...valid },
      validators: { ...validators },
    };

    payload.forEach((fieldName) => {
      delete newState.values[fieldName];
      delete newState.touched[fieldName];
      delete newState.valid[fieldName];
      delete newState.validators[fieldName];
    });

    return newState;
  };
