import React, { useContext } from 'react';
import restyle, { TStylesheet } from '../../restyler';
import { tabsContext } from './tabsContext';
import defaultStyles from './tabs.module.css';

interface iProps {
  children: JSX.Element;
  id: string;
  styles?: TStylesheet;
}
const Tab: React.FC<iProps> = (props) => {
  const { children, id, styles: overriddenStyles } = props;
  const styles = restyle(defaultStyles, overriddenStyles);
  const state = useContext(tabsContext);
  if (state === undefined) {
    throw Error('called Tab outside Tabs');
  }

  return state === id ? (
    <div className={styles.tab} aria-labelledby={id}>
      {children}
    </div>
  ) : null;
};

export default Tab;
