import React from 'react';
import GroupsList from 'components/GroupsList';
import GroupCreate from 'components/GroupCreate';
import { AreaRefresher } from 'hooks/useAreaRefresher';

const Groups: React.FC = () => (
  <AreaRefresher>
    <GroupCreate />
    <GroupsList />
  </AreaRefresher>
);

export default Groups;
