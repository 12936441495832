import React from 'react';
import cx from 'classnames';
import restyle from 'components/../restyler';
import defaultStyles from './buttonTertiary.module.css';
import { TButtonProps } from 'components/ButtonPrimary';

type TButtonTertiaryComponent = React.FC<TButtonProps>;

const ButtonTertiary: TButtonTertiaryComponent = (props: TButtonProps) => {
  const {
    onClick = () => null,
    type = 'button',
    className,
    children,
    styles: overriddenStyles,
    ...rest
  } = props;

  const styles = restyle(defaultStyles, overriddenStyles);

  return (
    <button
      {...rest}
      type={type}
      className={cx(styles.root, className)}
      onClick={onClick}
      disabled={props.disabled}
    >
      {children}
    </button>
  );
};

export default ButtonTertiary;
