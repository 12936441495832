import React, { useEffect, useRef } from 'react';
import useNotices from 'hooks/useNotices';
import { actionCancelNotice, TRemoveNotice } from 'reduxStore/actions/notices';
import { store } from 'reduxStore/store';
import styles from './notices.module.css';

const PopupsAndNoticesWrapper: React.FC = () => {
  const { notices, cancelNotice } = useNotices();
  return (
    <div className={styles.root}>
      {notices.map((item) => (
        <Notices {...item} key={item.id} cancelNotice={cancelNotice} />
      ))}
    </div>
  );
};

const Notices: React.FC<{
  type?: 'message' | 'failure' | 'success';
  message: string;
  id?: number | undefined;
  toRemoveAnimatably?: boolean;
  cancelNotice: (toCancel: TRemoveNotice) => void;
}> = ({ type = 'message', message, id, toRemoveAnimatably, cancelNotice }) => {
  let bgClass = styles.message;

  switch (true) {
    case type === 'failure': {
      bgClass = styles.fail;
      break;
    }
    case type === 'success': {
      bgClass = styles.success;
      break;
    }
    default: {
      bgClass = styles.message;
    }
  }

  const noticeRef = useRef<HTMLDivElement>(null);

  const timerDelayAnimationAutostart = useRef<ReturnType<typeof setTimeout>>();
  const timerAnimationEnd = useRef<ReturnType<typeof setTimeout>>();

  function animatableCancelNotice() {
    if (noticeRef.current) {
      noticeRef.current.style.height = `${0}px`;
      noticeRef.current.style.transform = 'scale(0)';
      timerAnimationEnd.current = setTimeout(() => {
        store.dispatch(actionCancelNotice({ id: id as number }));
      }, 250);
    } else {
      cancelNotice({ id: id as number });
    }
  }

  function noticeAutoRemove() {
    if (timerDelayAnimationAutostart.current)
      clearTimeout(timerDelayAnimationAutostart.current);
    if (timerAnimationEnd.current) clearTimeout(timerAnimationEnd.current);

    if (noticeRef.current) {
      noticeRef.current.style.height = `${
        noticeRef.current.offsetHeight || 60
      }px`;
      noticeRef.current.style.transform = 'scale(1)';
    }

    timerDelayAnimationAutostart.current = setTimeout(() => {
      if (typeof animatableCancelNotice === 'function')
        animatableCancelNotice();
    }, 6000);
  }

  function noticeOnClickRemove() {
    if (timerDelayAnimationAutostart.current)
      clearTimeout(timerDelayAnimationAutostart.current);
    if (timerAnimationEnd.current) clearTimeout(timerAnimationEnd.current);
    animatableCancelNotice();
  }

  function noticeRemoveWhenToMany() {
    if (toRemoveAnimatably) {
      if (timerDelayAnimationAutostart.current)
        clearTimeout(timerDelayAnimationAutostart.current);
      if (timerAnimationEnd.current) clearTimeout(timerAnimationEnd.current);
      animatableCancelNotice();
    }
  }

  useEffect(() => {
    noticeAutoRemove();
    return () => {
      if (timerDelayAnimationAutostart.current)
        clearTimeout(timerDelayAnimationAutostart.current);
      if (timerAnimationEnd.current) clearTimeout(timerAnimationEnd.current);
    };
  }, []);

  useEffect(() => {
    noticeRemoveWhenToMany();
    return () => {
      if (timerDelayAnimationAutostart.current)
        clearTimeout(timerDelayAnimationAutostart.current);
      if (timerAnimationEnd.current) clearTimeout(timerAnimationEnd.current);
    };
  }, [toRemoveAnimatably]);

  return (
    <div className={styles.notice__animationSlideInNotice}>
      <div
        ref={noticeRef}
        className={styles.notice__transitionOnCancelNotice}
        data-testid="notices"
      >
        <button onClick={noticeOnClickRemove} className={bgClass}>
          <div className={styles.notice__message}>{message}</div>
        </button>
      </div>
    </div>
  );
};

export default PopupsAndNoticesWrapper;
