import React, { useCallback, useEffect, useState } from 'react';
import Chart from 'components/Chart';
import TitledSection from 'components/TitledSection';
import { UserStats } from 'api/statistics/statsUsersGet';
import { IChartItem } from 'components/Chart/chart';
import StatisticsUser from 'components/StatisticsUser';
import { IUser } from 'api/accounts/usersGet';
import styles from './statisticsSummary.module.css';

interface ChartData {
  total: number;
  items: IChartItem[];
}

interface ChartsData {
  annotated: ChartData;
  validated: ChartData;
  unusable: ChartData;
}

interface StatisticsSummaryProps {
  data: UserStats[];
  users: IUser[] | undefined;
}

const StatisticsSummary: React.FC<StatisticsSummaryProps> = ({
  users,
  data,
}) => {
  const [chartsData, setChartsData] = useState<ChartsData>();
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  useEffect(() => {
    const parsedData = data?.reduce<ChartsData>(
      (result, userStats) => {
        result.annotated.total += userStats.annotated_count;
        result.annotated.items.push({
          value: userStats.annotated_count,
          name: userStats.username,
          id: userStats.user,
        });
        result.validated.total += userStats.validated_count;
        result.validated.items.push({
          value: userStats.validated_count,
          name: userStats.username,
          id: userStats.user,
        });
        result.unusable.total += userStats.unusable_count;
        result.unusable.items.push({
          value: userStats.unusable_count,
          name: userStats.username,
          id: userStats.user,
        });
        return result;
      },
      {
        annotated: { total: 0, items: [] },
        validated: { total: 0, items: [] },
        unusable: { total: 0, items: [] },
      },
    );
    setChartsData(parsedData);
  }, [data, users]);

  const selectUserId = useCallback(
    (id: number): void => {
      setSelectedUserId((value) => (value === id ? null : id));
    },
    [data],
  );

  if (!chartsData) return <div className={styles.message}>Loading...</div>;

  const selectedUserData =
    selectedUserId && data
      ? data.find(({ user }) => user === selectedUserId)
      : null;
  return (
    <>
      <div className={styles.root}>
        <TitledSection
          title={`Files annotated (${chartsData.annotated.total})`}
          className={styles.chartWrapper}
        >
          <Chart
            title="Annotated"
            data={chartsData.annotated.items}
            activeId={selectedUserId}
            setActiveElement={selectUserId}
          />
        </TitledSection>
        <TitledSection
          title={`Files validated (${chartsData.validated.total})`}
          className={styles.chartWrapper}
        >
          <Chart
            title="Validated"
            data={chartsData.validated.items}
            activeId={selectedUserId}
            setActiveElement={selectUserId}
          />
        </TitledSection>
        <TitledSection
          title={`Files unusable (${chartsData.unusable.total})`}
          className={styles.chartWrapper}
        >
          <Chart
            title="Unusable"
            data={chartsData.unusable.items}
            activeId={selectedUserId}
            setActiveElement={selectUserId}
          />
        </TitledSection>
      </div>
      {selectedUserData ? (
        <StatisticsUser
          data={selectedUserData}
          username={selectedUserData.username || 'unknown'}
        />
      ) : null}
    </>
  );
};

export default StatisticsSummary;
