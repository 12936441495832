import { createStore, combineReducers } from 'redux';
import userStorage from 'lib/persistentStorage/storage.user';
import user from './reducers/user/reducer';
import notices from './reducers/notices/reducer';
import popupName from './reducers/popups/reducer';
import loading from './reducers/loading/reducer';
import initialUserState from './reducers/user/initialState';

export const rootReducer = combineReducers({
  user,
  popupName,
  notices,
  loading,
});

function loadUserFromCache() {
  const { success, item } = userStorage.getItem('currentUser');
  const userState = {
    ...initialUserState,
    ...(success ? { data: item } : {}),
  };
  return {
    user: userState as typeof initialUserState,
  };
}

export const store = createStore(rootReducer, loadUserFromCache());
