import React from 'react';
import useCompaniesOptions from 'hooks/useCompaniesOptions';
import SelectFieldWithSearch from 'components/Forms/StyledSelectFieldWithSearch';
import { SelectFieldWithSearchProps } from 'components/Forms/StyledSelectFieldWithSearch/input';

export type CompaniesSelectProps = Omit<SelectFieldWithSearchProps, 'options'>;

const CompaniesSelect: React.FC<CompaniesSelectProps> = (props) => {
  const { options } = useCompaniesOptions();
  return <SelectFieldWithSearch options={options} {...props} />;
};

export default CompaniesSelect;
