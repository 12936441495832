import ButtonPrimary from 'components/ButtonPrimary';
import ButtonSecondary from 'components/ButtonSecondary';
import Icon from 'components/Icon';
import PopupWrapper, { PopupButton } from 'components/Popups';
import useAudiodb from 'hooks/useAudiodb';
import React from 'react';
import styles from './exportPopup.module.css';

const iconAttributes = {
  width: 15,
  height: 15,
};

const ExportPopup: React.FC<{
  name: string;
  id: number;
  reload: () => void;
}> = ({ name, id, reload }) => {
  const { packageImportIt, loading } = useAudiodb();

  return (
    <>
      <PopupButton
        unique={`export-pckg-${name}`}
        open
        title={`Export ${name}`}
        className={styles.popup__open}
      >
        <Icon name="export" attributes={iconAttributes} />
      </PopupButton>
      <PopupWrapper unique={`export-pckg-${name}`}>
        {({ cancelPopup }) => (
          <div className={styles.popup}>
            <div className={styles.popup__message}>
              You are about to export this package:
            </div>
            <div className={styles.popup__name}>{name}</div>
            <div className={styles.popup__actions}>
              <ButtonPrimary
                {...{ disabled: loading }}
                onClick={async () => {
                  await packageImportIt({
                    packageId: id,
                    packageData: { id, name },
                  });
                  reload();
                  cancelPopup();
                }}
              >
                {loading ? 'Sending...' : 'Proceed'}
              </ButtonPrimary>
              <ButtonSecondary onClick={cancelPopup}>Cancel</ButtonSecondary>
            </div>
          </div>
        )}
      </PopupWrapper>
    </>
  );
};

export default ExportPopup;
