import { useContext } from 'react';
import { TFormContext, FormContext } from 'components/Forms';

const useForm = (): TFormContext => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw Error('called useForm outside of FormContext');
  }
  return context;
};

export default useForm;
