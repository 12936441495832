const defaultTtl = 1000 * 60 * 60 * 24 * 30;

interface iItem {
  id: string;
  [key: string]: unknown;
}

interface iStorageItem extends iItem {
  createdAt: number;
}

interface iReport {
  success: boolean;
  message?: string;
  item?: iStorageItem;
}

class StorageAbstract {
  constructor(protected ttl = defaultTtl, protected prefix: string) {}

  createStorageKey(id: string): string {
    return `${this.prefix}_${id}`;
  }

  createStorageItem(item: iItem): iStorageItem {
    return {
      createdAt: Date.now(),
      ...item,
    };
  }

  validateItem(item: iItem): boolean {
    const isItem = item instanceof Object;
    const hasId = isItem && Object.prototype.hasOwnProperty.call(item, 'id');
    return isItem && hasId;
  }

  validateStorageItem(storageItem: iStorageItem): boolean {
    const { createdAt } = storageItem;
    return createdAt + this.ttl >= Date.now();
  }

  setItem(item: iItem): iReport {
    if (!this.validateItem(item)) {
      return {
        success: false,
        message: 'Missing ID',
      };
    }

    try {
      localStorage.setItem(
        this.createStorageKey(item.id),
        JSON.stringify(this.createStorageItem(item)),
      );
      return { success: true };
    } catch (e) {
      return {
        success: false,
        message: JSON.stringify(e),
      };
    }
  }

  getItem(id: string): iReport {
    try {
      const stringifiedOrNull = localStorage.getItem(this.createStorageKey(id));
      const storageItem: iStorageItem = stringifiedOrNull
        ? JSON.parse(stringifiedOrNull)
        : null;
      const isValid = storageItem && this.validateStorageItem(storageItem);

      if (isValid) {
        return {
          success: true,
          item: storageItem,
        };
      }
      this.delItem(id);
      return {
        success: false,
        message: 'Item does not exist',
      };
    } catch (e) {
      return {
        success: false,
        message: JSON.stringify(e),
      };
    }
  }

  delItem(id: string): iReport {
    try {
      localStorage.removeItem(this.createStorageKey(id));
      return {
        success: true,
      };
    } catch (e) {
      return {
        success: false,
        message: JSON.stringify(e),
      };
    }
  }
}

export default StorageAbstract;
