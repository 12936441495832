type tParamsBuilder = (urlBuilder: {
  [key: string]: string | number | boolean;
}) => string;

const paramsBuilder: tParamsBuilder = (urlBuilder) => {
  let url = '';
  Object.entries(urlBuilder).forEach(([paramName, paramValue]) => {
    if (typeof paramValue === 'boolean' || !!paramValue) {
      const delimiter = url ? '&' : '';
      url += `${delimiter}${paramName}=${paramValue}`;
    }
  });

  return url;
};

export default paramsBuilder;
