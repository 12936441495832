import ButtonPrimary from 'components/ButtonPrimary';
import React from 'react';
import styles from './soundRankingSubmit.module.css';

const SoundRankingSubmit: React.FC<{
  voteProgress?: boolean;
  voteMethod?: () => void;
  voteError?: string;
  voteDisabled?: boolean;
}> = ({ voteProgress, voteMethod, voteError, voteDisabled }) => (
  <div className={styles.root}>
    {!voteProgress && !voteError ? (
      <div className={styles.voteFor}>
        <ButtonPrimary
          disabled={voteDisabled}
          onClick={voteMethod}
          title="Vote"
        >
          Vote
        </ButtonPrimary>
      </div>
    ) : null}
    {voteProgress ? (
      <div className={styles.voteFor}>
        <div className={styles.communicate}>Voting...</div>
      </div>
    ) : null}
  </div>
);

export default SoundRankingSubmit;
