import audiodbGet from 'api/audiodb/audiodbGet';
import { IAudiodbPackagesData } from 'api/audiodb/_types';
import Paging from 'components/FilteralbeDataList/paging';
import React from 'react';
import FilterableDataList, {
  DEFAULT_PAGE_SIZE,
  useFilterableDataList,
} from 'components/FilteralbeDataList/filteralbeDataList';
import ExportPopup from './ExportPopup';
import styles from './packagesCreate.module.css';
import FilterInput from 'components/FilteralbeDataList/filter';

const getPackagesBySearchparams = async (url: string) =>
  audiodbGet({ params: url });

const headers = (
  <div className={styles.header}>
    <div className={styles.header__id}>ID</div>
    <div className={styles.header__name}>Name</div>
    <div className={styles.header__actions}>Actions</div>
  </div>
);

const inputAttributes = {
  placeholder: 'Search by name',
};

const PackagesCreate: React.FC = () => (
  <FilterableDataList
    pagination
    fetchData={getPackagesBySearchparams}
    name="packagesCreateList"
  >
    <Filters />
    {headers}
    <List />
    <Pagination />
  </FilterableDataList>
);

const Filters = () => (
  <div className={styles.row}>
    <div className={styles.row__id} />
    <div className={styles.row__name}>
      <FilterInput name="name" type="text" {...inputAttributes} />
    </div>
  </div>
);

const List: React.FC = () => {
  const { data, error, loading, reload } =
    useFilterableDataList<IAudiodbPackagesData>();

  if (loading) return <span>Loading...</span>;
  if (error || !data?.results?.length) return <span>No data found...</span>;

  return (
    <ul aria-label="Packages list">
      {(data as IAudiodbPackagesData)?.results?.map((item) => (
        <li className={styles.row} key={JSON.stringify(item)}>
          <div className={styles.row__id}>{item.id}</div>
          <div className={styles.row__name}>{item.name}</div>
          <div className={styles.row__actions}>
            <ExportPopup name={item.name} id={item.id} reload={reload} />
          </div>
        </li>
      ))}
    </ul>
  );
};

const Pagination = () => {
  const { data, loading } = useFilterableDataList<IAudiodbPackagesData>();

  const pagesAmount = Math.ceil((data?.count || 0) / DEFAULT_PAGE_SIZE) || 0;

  return <Paging loading={loading} max={pagesAmount} />;
};

export default PackagesCreate;
