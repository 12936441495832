import fetchApiNoAuth from 'api/fetchApiNoAuth';
import type { IResponse } from 'api/_types';
import config from 'api/config';

type TUserPayload = {
  login: string;
  password: string;
};

interface iUserReceived {
  login?: string;
  access?: string;
  refresh?: string;
  detail?: string;
}

const jwtCreate = async (
  args: TUserPayload,
): Promise<{
  success: boolean;
  payload: iUserReceived | null;
}> => {
  const response = await fetchApiNoAuth({
    method: 'POST',
    payload: { username: args.login, password: args.password },
    path: config.jwtCreate,
  });

  const { success, payload } = response as IResponse<iUserReceived>;
  const { access, refresh } = payload || {};

  return {
    success: !!(success && access && refresh),
    payload,
  };
};

export default jwtCreate;
