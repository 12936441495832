import useCompaniesOptions, {
  CompanyOptionsHookResult,
} from 'hooks/useCompaniesOptions';

import React, { createContext, useContext } from 'react';

const CompaniesOptionsContext = createContext<
  CompanyOptionsHookResult | undefined
>(undefined);

export const CompaniesOptionsWrapper: React.FC = ({ children }) => {
  const context = useCompaniesOptions();
  return (
    <CompaniesOptionsContext.Provider value={context}>
      {children}
    </CompaniesOptionsContext.Provider>
  );
};

const useCompaniesOptionsFromContext = (): CompanyOptionsHookResult => {
  const context = useContext(CompaniesOptionsContext);
  if (context === undefined) {
    throw Error('called useCompaniesOptionsFromContext outside AreaRefresherContext');
  }
  return context;
};

export default useCompaniesOptionsFromContext;
