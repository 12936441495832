import React from 'react';
import StatisticsDashboard from 'components/StatisticsDashboard';

const Statistics: React.FC = () => (
  <>
    <StatisticsDashboard />
  </>
);

export default Statistics;
