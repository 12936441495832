import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionSetNotice,
  actionCancelNotice,
  TNoticeType,
  TRemoveNotice,
} from 'reduxStore/actions/notices';
import { RootState } from 'reduxStore/_types';

interface IReturnType {
  notices: RootState['notices'];
  setNotice: (name: TNoticeType) => void;
  cancelNotice: (surname: TRemoveNotice) => void;
}

const useNotices = (): IReturnType => {
  const dispatch = useDispatch();
  const notices = useSelector((state: RootState) => state.notices);

  const setNotice = (payload: TNoticeType) => {
    dispatch(actionSetNotice(payload));
  };

  const cancelNotice = (payload: TRemoveNotice) => {
    dispatch(actionCancelNotice(payload));
  };

  return useMemo(
    () => ({
      notices,
      setNotice,
      cancelNotice,
    }),
    [dispatch, notices],
  );
};

export default useNotices;
