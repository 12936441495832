import React, { useState, useCallback, useRef, useEffect } from 'react';

interface IDropdown<T> {
  ref: React.RefObject<T>;
  isOpen: boolean;
  open: () => void;
  close: () => void;
}
const ESC_KEY = 27;

const onEscapeKeyPress =
  (fn: () => void) =>
    ({ keyCode }: KeyboardEvent) =>
      keyCode === ESC_KEY ? fn() : null;

const useDropdown = <T extends HTMLElement>(): IDropdown<T> => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<T>(null);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    const handleGlobalMouseDown = ({ target }: Event) => {
      if (!(target instanceof HTMLElement)) {
        return;
      }
      if (!ref.current || ref.current.contains(target)) {
        return;
      }

      setTimeout(() => close(), 100);
    };
    const handleGlobalKeydown = onEscapeKeyPress(close);

    document.addEventListener('mousedown', handleGlobalMouseDown);
    document.addEventListener('keydown', handleGlobalKeydown);
    return () => {
      document.removeEventListener('mousedown', handleGlobalMouseDown);
      document.removeEventListener('keydown', handleGlobalKeydown);
    };
  }, [close, ref.current]);

  return {
    ref,
    isOpen,
    open,
    close,
  };
};

export default useDropdown;
