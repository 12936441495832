import companiesDELETE from 'api/companies/companiesDEL';
import companiesGet, { ICompaniesData } from 'api/companies/companiesGET';
import companiesPATCH from 'api/companies/companiesPATCH';
import { TFieldValue } from 'components/Forms';
import mergeState from 'lib/utils/mergeState';
import paramsBuilder from 'lib/utils/paramsBuilder';
import { useState, useMemo } from 'react';
import useNotices from './useNotices';

interface iState<Type> {
  loading: boolean;
  error: string;
  data: Type;
}

interface iInitialState {
  loading?: boolean;
  error?: string;
  data?: unknown;
}

const initialState: iInitialState = {
  loading: false,
  error: '',
  data: null,
};

interface iUseCompaniesReturnType {
  companies: iState<ICompaniesData>;
  getCompanies: (
    arg: Record<string, string | number | boolean>
  ) => Promise<void>;
  deleted: iState<boolean>;
  deleteCompany: (companyId: number, userPassword: string) => Promise<void>;
  edited: iState<boolean>;
  editCompany: (
    companyId: number,
    fields: Record<string, TFieldValue>
  ) => Promise<void>;
}

const useCompanies = (): iUseCompaniesReturnType => {
  const { setNotice } = useNotices();

  const [companies, setCompanies] = useState(initialState);
  const [deleted, setDeleted] = useState(initialState);
  const [edited, setEdited] = useState(initialState);

  const getCompanies = async (
    args: Record<string, string | number | boolean>,
  ) => {
    mergeState<iInitialState>(companies, { loading: true }, setCompanies);
    const urlParams = paramsBuilder(args);
    const response = await companiesGet({ params: urlParams });
    const { count, next, previous, results } = response;
    mergeState<iInitialState>(
      companies,
      {
        data: {
          count,
          next,
          previous,
          results,
        },
      },
      setCompanies,
    );
  };

  const deleteCompany = async (companyId: number, userPassword: string) => {
    mergeState<iInitialState>(deleted, { loading: true }, setDeleted);
    const { success, message } = await companiesDELETE({
      companyId,
      userPassword,
    });
    setNotice({ type: success ? 'success' : 'failure', message });
    mergeState<iInitialState>(
      deleted,
      { loading: false, data: success },
      setDeleted,
    );
  };

  const editCompany = async (
    companyId: number,
    fields: Record<string, TFieldValue>,
  ) => {
    mergeState<iInitialState>(edited, { loading: true }, setEdited);
    const { success, message } = await companiesPATCH({ companyId, fields });
    setNotice({ type: success ? 'success' : 'failure', message });
    mergeState<iInitialState>(
      edited,
      { loading: false, data: success },
      setEdited,
    );
  };

  return useMemo<iUseCompaniesReturnType>(
    () => ({
      companies: companies as iState<ICompaniesData>,
      getCompanies,
      deleted: deleted as iState<boolean>,
      deleteCompany,
      edited: edited as iState<boolean>,
      editCompany,
    }),
    [companies, setCompanies],
  );
};

export default useCompanies;
