import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Form from 'components/Forms';
import paramsBuilder from 'lib/utils/paramsBuilder';

type TUrlBuilder = Record<string, string | number | boolean>;

interface IFilterableDataListContext {
  data: unknown;
  error: string;
  loading: boolean;
  reload: () => void;
}

export const DEFAULT_PAGE_SIZE = 10;

const FilterableDataListContext = React.createContext<
  IFilterableDataListContext | undefined
>(undefined);

const UrlBuilderLayer: React.FC<{
  children: React.ReactNode;
  fetchData: (url: string) => Promise<unknown>;
  urlBuilder: TUrlBuilder;
}> = ({ children, fetchData, urlBuilder }) => {
  const [{ data, error, loading }, setState] = useState({
    data: null as unknown,
    error: '',
    loading: true,
  });

  const url = paramsBuilder(urlBuilder);

  const fetchDataToState = async () => {
    setState({ loading: true, error, data });
    const response = await fetchData(url);
    if (response)
      setState({ loading: false, error: '', data: response });
    else
      setState({ loading: false, error: 'Soemthing went wrong.', data: null });
  };

  const timer = useRef<ReturnType<typeof setTimeout>>();
  useEffect(() => {
    clearTimeout(timer.current as ReturnType<typeof setTimeout>);
    setState({ loading: true, error, data });
    timer.current = setTimeout(() => {
      fetchDataToState();
    }, 1000);
  }, [url]);

  const context = useMemo(
    () => ({
      data,
      error,
      loading,
      reload: fetchDataToState,
    }),
    [data, error, loading, setState],
  );

  return (
    <FilterableDataListContext.Provider value={context}>
      {children}
    </FilterableDataListContext.Provider>
  );
};

const FilterableDataList: React.FC<{
  children: React.ReactNode;
  className?: string;
  fetchData: (url: string) => Promise<unknown>;
  pagination: boolean;
  name: string;
}> = ({ children, fetchData, className, pagination, name }) => (
  <Form className={className} data-testid={name}>
    {({ form }) => {
      const { values } = form;
      const urlBuilder = {} as TUrlBuilder;

      const limit = DEFAULT_PAGE_SIZE;
      const offset =
        (typeof values._page === 'number' ? values._page - 1 : 0) *
        DEFAULT_PAGE_SIZE;

      if (pagination) {
        urlBuilder.limit = limit;
        urlBuilder.offset = offset || 0;
      }

      Object.entries(values).forEach((entry) => {
        const [key, value] = entry;
        if (key !== '_page' && key !== '_limit') {
          urlBuilder[key] = value as string | number;
        }
      });

      return (
        <UrlBuilderLayer fetchData={fetchData} urlBuilder={urlBuilder}>
          {children}
        </UrlBuilderLayer>
      );
    }}
  </Form>
);

type Test<T extends unknown> = Omit<IFilterableDataListContext, 'data'> & {
  data: T | null;
};

export const useFilterableDataList = <T extends unknown>(): Test<T> => {
  const context = useContext(FilterableDataListContext);
  if (context === undefined) {
    throw Error(
      'called useFilterableDataList outside FilterableDataListContext',
    );
  }
  return context as Test<T>;
};

export default FilterableDataList;
