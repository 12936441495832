import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import resetPassword from 'api/accountsUsers/resetPassword';
import resetPasswordConfirm from 'api/accountsUsers/resetPasswordConfirm';
import useNotices from './useNotices';
import useLoading from './useLoading';

interface IReturnType {
  submitResetPassword: (email: string) => Promise<boolean>;
  submitCreatePassword: (params: {
    uid: string;
    new_password: string;
    token: string;
  }) => Promise<boolean>;
}

const useForgottenPass = (): IReturnType => {
  const dispatch = useDispatch();
  const noticesManager = useNotices();
  const loadingManager = useLoading();

  const submitResetPassword = async (email: string) => {
    loadingManager.setLoading(true);
    const { detail, success } = await resetPassword({ email });
    noticesManager.setNotice({
      type: success ? 'success' : 'failure',
      message: detail,
    });
    loadingManager.setLoading(false);
    return success;
  };

  const submitCreatePassword = async (params: {
    uid: string;
    new_password: string;
    token: string;
  }) => {
    loadingManager.setLoading(true);
    const { detail, success } = await resetPasswordConfirm(params);
    noticesManager.setNotice({
      type: success ? 'success' : 'failure',
      message: detail || 'Confirmation failed',
    });
    loadingManager.setLoading(false);
    return success;
  };

  return useMemo(
    () => ({
      submitResetPassword,
      submitCreatePassword,
    }),
    [dispatch],
  );
};

export default useForgottenPass;
