import React, { useEffect, useState } from 'react';
import Input from 'components/Forms/StyledInput';
import { FormInputProps, TFieldValue } from 'components/Forms';
import useElementSize from 'hooks/useElementSize';
import useDropdown from 'hooks/useDropdown';
import restyle, { TStylesheet } from 'components/../restyler';
import defaultStyles from './input.module.css';
import { IOption } from 'components/Forms/StyledSelect/input';

const inputSearchCss = {
  message: defaultStyles.inputSearchMessage,
  label__span: defaultStyles.inputSearchSpan,
  input: defaultStyles.inputSearch,
};

export interface SelectFieldWithSearchProps extends FormInputProps {
  label?: string;
  value: TFieldValue;
  name: string;
  options: IOption[];
  styles?: TStylesheet;
}

const SelectFieldWithSearch: React.FC<SelectFieldWithSearchProps> = ({
  setField,
  value,
  label,
  options,
  message,
  touched,
  name,
  styles: overriddenStyles,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleOptions, setVisibleOptions] = useState<IOption[]>([]);
  const styles = restyle(defaultStyles, overriddenStyles);

  useEffect(() => {
    const matchedOptions =
      searchTerm === ''
        ? options
        : options.filter((option) =>
          option.label.toLowerCase().includes(searchTerm.toLowerCase()),
        );

    setVisibleOptions(matchedOptions);
  }, [options, searchTerm]);

  const {
    ref: dropdownRef,
    isOpen,
    open,
    close,
  } = useDropdown<HTMLDivElement>();
  const setSingleField = (
    value: string | number | undefined,
    isChecked: boolean,
  ) => {
    setField(isChecked ? value : '');
    close();
  };
  const { ref, width } = useElementSize<HTMLButtonElement>();

  const selected = options.find(
    ({ value: optionValue }) => optionValue === value,
  );

  return (
    <div className={styles.root}>
      <div className={styles.title}>{label}</div>
      <button
        ref={ref}
        onClick={() => !isOpen && open()}
        className={`${styles.header} ${
          touched && message ? styles.errorBorder : ''
        }`}
        name={name}
        type="button"
      >
        {selected ? (
          <>
            <div>{selected.label}</div>
            <div
              className={styles.button__clear}
              onClickCapture={(e) => {
                e.stopPropagation();
                setSingleField(selected.value, false);
              }}
              role="presentation"
            />
          </>
        ) : null}
      </button>
      {isOpen ? (
        <div
          className={`${styles.toggleable} ${styles.toggleable}`}
          style={{ width: width ? `${width}px` : 'inherit' }}
          ref={dropdownRef}
          data-testid={`${name}Dropdown`}
        >
          <Input
            setField={(art: TFieldValue) => {
              setSearchTerm(`${art}`);
            }}
            autoFocus
            value={searchTerm}
            message=""
            label=""
            type="text"
            placeholder="Search"
            name="search"
            touched={false}
            styles={inputSearchCss}
          />
          <div className={styles.scrollable}>
            {visibleOptions.map((option) => {
              const isChecked = selected?.value === option.value;
              return (
                <button
                  key={option.value}
                  name={option.label}
                  type="button"
                  className={`${styles.option}${
                    isChecked ? ` ${styles.option__selected}` : ''
                  }`}
                  onClick={() => setSingleField(option.value, !isChecked)}
                >
                  {option.label}
                </button>
              );
            })}
          </div>
        </div>
      ) : null}
      <div className={styles.errorMessage}>
        {touched && message ? message : ''}
      </div>
    </div>
  );
};

export default SelectFieldWithSearch;
