import React, { useCallback } from 'react';
import NavigationBar from 'components/NavigationBar';
import Header from 'components/Header';
import { RouteComponentProps, withRouter } from 'react-router';
import styles from './layout.module.css';
import useLocalStorageValue from 'hooks/useLocalStorageValue';

const notDashboardPathnames = [
  '/auth',
  '//auth',
  '//auth/',
  '/auth/',
  '/login',
  '/login/',
  'login',
];

const Layout: React.FC<RouteComponentProps> = ({ children, location }) => {
  const [asideExpanded, setAsideExpanded] = useLocalStorageValue<boolean>(
    'asideExpanded',
    true,
  );

  const toggleAsideExpanded = useCallback(() => {
    setAsideExpanded(!asideExpanded);
  }, [asideExpanded, setAsideExpanded]);

  const isDashboardView = !notDashboardPathnames.includes(location.pathname);

  const classNameExpanded = asideExpanded
    ? styles.root_expanded
    : styles.root_folded;
  const classNameLogged = isDashboardView
    ? styles.root_logged
    : styles.root_notLogged;
  const classNames = `${classNameExpanded} ${classNameLogged}`;

  return (
    <div className={classNames}>
      {isDashboardView ? (
        <header className={styles.header}>
          <Header
            toggleAsideExpanded={toggleAsideExpanded}
            asideExpanded={asideExpanded}
          />
        </header>
      ) : null}
      <div className={styles.content}>
        {isDashboardView ? (
          <aside className={styles.aside}>
            <NavigationBar
              toggleAsideExpanded={toggleAsideExpanded}
              asideExpanded={asideExpanded}
            />
          </aside>
        ) : null}
        <main className={styles.main}>{children}</main>
      </div>
    </div>
  );
};

export default withRouter(Layout);
