import companiesGet from 'api/companies/companiesGET';
import paramsBuilder from 'lib/utils/paramsBuilder';
import { useState, useEffect } from 'react';

export interface CompanyOption {
  value: number;
  label: string;
}

export interface CompanyOptionsHookResult {
  options: CompanyOption[];
  loading: boolean;
}

const useCompaniesOptions = (): CompanyOptionsHookResult => {
  const [options, setOptions] = useState<CompanyOption[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const urlParams = paramsBuilder({ limit: 1000 });
    setLoading(true);
    companiesGet({ params: urlParams })
      .then((res) => {
        if (!res.success || !res.results?.length) {
          return;
        }
        setOptions(
          res.results.map(({ id, name }) => ({ value: id, label: name })),
        );
      })
      .catch(() => setOptions([]))
      .finally(() => setLoading(false));
  }, []);

  return { options, loading };
};

export default useCompaniesOptions;
