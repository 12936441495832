/* eslint-disable no-empty */
import config from './config';
import { IResponse, IRequest, IMethodArguments } from './_types';

/**
 * @param payload optional object,
 * @param headers optional object,
 * @param path optional string
 * @param method optional string
 * @returns {
 *  success: boolean,
 *  status: null (if request failed) or number (status),
 *  payload: null if no payload, object with detail if error, object with data if data.
 * }
 */
const fetchApi = async function <ExpectedData>({
  payload,
  headers = {},
  path,
  method,
}: IMethodArguments): Promise<IResponse<ExpectedData>> {
  const configFetch: IRequest = {
    method: method || 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
  };

  if (method !== 'GET' && payload instanceof Object) {
    configFetch.body = JSON.stringify({ ...payload });
  }

  const toReturn: IResponse<ExpectedData> = {
    success: false,
    status: null,
    payload: null,
  };

  try {
    const response = await fetch(`${config.apiPath}${path}`, configFetch);

    toReturn.success = response.status >= 200 && response.status < 400;
    toReturn.status = response.status;

    try {
      toReturn.payload = await response.json();
    } catch {}
  } catch {}

  return toReturn;
};

export default fetchApi;
