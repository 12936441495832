import React, { useState } from 'react';
import SoundRanking from 'components/SoundRanking';
import TitledSection from 'components/TitledSection';
import useSoundsRanking from 'hooks/useSoundsRanking';
import { ISoundFile } from 'api/ranking/_types';
import SoundRankingSubmit from 'components/SoundRankingSubmit';
import candidatesComparisonPost from 'api/ranking/candidatesComparisonPost';
import useNotices from 'hooks/useNotices';
import styles from './ranking.module.css';

const Ranking: React.FC = () => {
  const { loading, files, error, nextCandidates, markAsListened } =
    useSoundsRanking();
  const [selectedSound, setSelectedSound] = useState<ISoundFile>();
  const [playingId, setPlayingId] = useState<number | null>(null);
  const [voteProgress, setVoteProgress] = useState(false);
  const { setNotice } = useNotices();

  const comparison = async () => {
    if (files[0].id && files[1].id && selectedSound?.id) {
      setVoteProgress(true);
      const response = await candidatesComparisonPost({
        annotation0: files[0].id,
        annotation1: files[1].id,
        result_annotation: selectedSound.id,
      });
      setVoteProgress(false);
      if (!response.success) {
        setNotice({
          message: response.message,
          type: 'failure',
        });
        return;
      }
      setSelectedSound(undefined);
      nextCandidates();
    }
  };

  const allListened = files[0].listened && files[1].listened;
  return (
    <TitledSection title="Which of the following sounds is more aggressive?">
      {loading && <span>Loading...</span>}
      {!loading && !error && (
        <>
          <SoundRanking
            markAsListened={markAsListened}
            markAsPlaying={setPlayingId}
            selectFile={setSelectedSound}
            soundFile={files[0]}
            isDisabled={!!(playingId && playingId !== files[0].id)}
            isSelected={selectedSound?.id === files[0].id}
          />
          <SoundRanking
            markAsListened={markAsListened}
            markAsPlaying={setPlayingId}
            selectFile={setSelectedSound}
            soundFile={files[1]}
            isDisabled={!!(playingId && playingId !== files[1].id)}
            isSelected={selectedSound?.id === files[1].id}
          />
          <SoundRankingSubmit
            voteDisabled={!selectedSound || !allListened}
            voteMethod={comparison}
            voteProgress={voteProgress}
          />
          {selectedSound && !allListened && (
            <div className={styles.errorMessage}>
              You have to fully listen both records before vote.
            </div>
          )}
        </>
      )}
      {!loading && error && <span>No rankings available.</span>}
    </TitledSection>
  );
};

export default Ranking;
