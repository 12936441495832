import config from 'api/config';
import fetchApi from 'api/fetchApi';
import { IPackage } from './_types';

export interface IPackageToPatch {
  name?: string;
  place?: string;
  project_type?: string;
  recording_device?: string;
  device_location?: string;
  description?: string;
}

export interface IPackageReturned extends IPackageToPatch {
  id: number;
  priority: number;
  detail?: string | string[];
}

interface IPackagesPatchReturnType {
  payload?: IPackage | null;
  success: boolean;
  message: string;
}

const packagesPatch = async (
  packageId: number,
  args: IPackageToPatch,
): Promise<IPackagesPatchReturnType> => {
  const { success, status, payload } = await fetchApi<
    IPackage & { detail?: string }
  >({
    method: 'PATCH',
    path: `${config.packages}${packageId}/`,
    payload: args,
  });

  if (success && payload) {
    return {
      success: true,
      payload,
      message: `Package ${payload.name} edited.`,
    };
  }
  if (status === 400 && payload) {
    let message = '';
    Object.entries(payload).forEach((entry) => {
      const [name, val] = entry;
      message += `${name.toUpperCase()} - ${
        val.join ? val.join('') : JSON.stringify(val)
      } `;
    });
    message = message ? `Errors! ${message}` : 'Something went wrong';
    return {
      success: false,
      message,
    };
  }
  return {
    success: false,
    message:
      typeof payload?.detail === 'string'
        ? payload.detail
        : 'Something went wrong',
  };
};

export default packagesPatch;
