import React from 'react';
import styles from '../packagesManage.module.css';

const PackagesRowHeader: React.FC = () => (
  <>
    <div className={styles.row_header}>
      <div className={styles.id} />
      <div className={styles.id}>ID</div>
      <div className={styles.name}>Package name</div>
      <div className={styles.status}>Status</div>
      <div className={styles.skipped}>Skipped</div>
      <div className={styles.priority}>Priority</div>
      <div className={styles.uploadedBy}>Uploaded by</div>
      <div className={styles.company}>Company</div>
      <div className={styles.statistics}>Statistics</div>
      <div className={styles.actions}>Actions</div>
    </div>
  </>
);

export default PackagesRowHeader;
