import fetchApi from 'api/fetchApi';
import { IGroupData } from 'api/groups/_types';
import config from 'api/config';

export type TGroupsGETPayload = {
  params: string;
};

const groupsGET = async (args: TGroupsGETPayload): Promise<IGroupData> => {
  const response = await fetchApi<IGroupData>({
    method: 'GET',
    path: `${config.groups}?${args.params}`,
  });

  const { success, payload } = response;

  return {
    success,
    count: payload?.count || 0,
    next: payload?.next || '',
    previous: payload?.previous || '',
    results: payload?.results || [],
  };
};

export default groupsGET;
