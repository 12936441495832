export const SET_NAME = 'SET_NAME';
export const SET_SURNAME = 'SET_SURNAME';
export const SET_AGE = 'SET_AGE';
export const SET_TOKEN = 'SET_TOKEN';

export const USER_LOADING = 'USER_LOADING';
export const USER_ERROR = 'USER_ERROR';
export const USER_DATA = 'USER_DATA';
export const USER_LOGOUT = 'USER_LOGOUT';

export const actionUserLoading = () => ({
  type: USER_LOADING,
  payload: undefined,
});

export const actionUserError = (e?: string) => ({
  type: USER_ERROR,
  payload: e || undefined,
});

export const actionUserLogout = () => ({
  type: USER_LOGOUT,
  payload: undefined,
});

export type TUserPayload = {
  login: string;
  token: string;
  refresh: string;
  timestamp: number;
  user_id: number;
  is_superuser: boolean;
  is_staff: boolean;
};
export const actionUserData = (payload: TUserPayload) => ({
  type: USER_DATA,
  payload,
});
