import {
  actionUserData,
  actionUserError,
  actionUserLoading,
  TUserPayload,
  USER_LOGOUT,
  USER_DATA,
  USER_ERROR,
  USER_LOADING,
} from '../../actions/user';
import initialState from './initialState';
import { TReducer } from '../../_types';

type TActionsAvailable =
  | ReturnType<typeof actionUserData>
  | ReturnType<typeof actionUserError>
  | ReturnType<typeof actionUserLoading>;
type TState = typeof initialState;

const reducerUserLoading: TReducer<TState, typeof USER_LOADING, undefined> =
  () => ({
    ...initialState,
    loading: true,
    error: '',
  });

const reducerUserLogout: TReducer<TState, typeof USER_LOGOUT, undefined> = () =>
  initialState;

const reducerUserError: TReducer<TState, typeof USER_ERROR, unknown> = (
  state,
  action,
) => ({
  ...initialState,
  loading: false,
  error: action.payload ? JSON.stringify(action.payload) : 'error',
});

const reducerUserData: TReducer<TState, typeof USER_DATA, TUserPayload> = (
  state,
  action,
) => ({
  loading: false,
  error: '',
  data: action.payload,
});

const userReducerMap = new Map()
  .set(USER_LOADING, reducerUserLoading)
  .set(USER_ERROR, reducerUserError)
  .set(USER_DATA, reducerUserData)
  .set(USER_LOGOUT, reducerUserLogout);

const userReducer = (
  state: TState = initialState,
  action: TActionsAvailable,
): TState => {
  const reducer: TReducer<TState, typeof action.type, typeof action.payload> =
    userReducerMap.get(action.type);
  return typeof reducer === 'function' ? reducer(state, action) : state;
};

export default userReducer;
