import { useEffect, useState } from 'react';
import labelsSuggestionsGet from 'api/annotationTool/labelsSuggestionsGet';

const useSuggestions = (value: string, blacklist: string[], whitelist: string[]) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  async function get() {
    setLoading(true);
    const response = await labelsSuggestionsGet(value);
    if (response.success) {
      const labels = response.data.concat(whitelist.filter(l => l.startsWith(value))).filter(l => !blacklist.includes(l));
      if (labels.length <= 10)
        setSuggestions(labels);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (value.length < 2)
      setSuggestions([]);
    else
      get();
  }, [value]);

  return { suggestions, loadingSuggestions: loading };
};

export default useSuggestions;
