import React from 'react';
import CreateUser from 'components/CreateUser';
import UsersList from 'components/UsersList';
import { AreaRefresher } from 'hooks/useAreaRefresher';
import { CompaniesOptionsWrapper } from 'hooks/useCompaniesOptionsFromContext';

const Users: React.FC = () => (
  <AreaRefresher>
    <CompaniesOptionsWrapper>
      <CreateUser />
      <UsersList />
    </CompaniesOptionsWrapper>
  </AreaRefresher>
);

export default Users;
