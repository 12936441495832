import React, { createContext, useCallback, useContext, useState } from 'react';

interface IMassActionContext {
  selected: number[];
  update: (id: number) => void;
  unselectAll: () => void;
  isSelected: (id: number) => boolean;
}

const MassActionsContext = createContext<IMassActionContext | undefined>(
  undefined,
);

export const MassActionsWrapper: React.FC = ({ children }) => {
  const [selected, setSelected] = useState<number[]>([]);
  const update = useCallback(
    (id: number) => {
      if (selected.includes(id)) {
        setSelected((value) => value.filter((el) => el !== id));
        return;
      }
      setSelected((value) => [...value, id]);
    },
    [selected],
  );
  const unselectAll = useCallback(() => setSelected([]), []);
  const isSelected = useCallback(
    (id: number) => selected.includes(id),
    [selected],
  );
  return (
    <MassActionsContext.Provider
      value={{
        selected,
        update,
        unselectAll,
        isSelected,
      }}
    >
      {children}
    </MassActionsContext.Provider>
  );
};

const useMassActions = (): IMassActionContext => {
  const context = useContext(MassActionsContext);
  if (context === undefined) {
    throw Error('called useMassActions outside MassActions');
  }
  return context;
};

export default useMassActions;
