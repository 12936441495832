const initialState = {
  loading: false,
  error: '',
  data: {
    login: null as null | string,
    user_id: null as null | number,
    token: null as null | string,
    refresh: null as null | string,
    timestamp: null as null | number,
    is_staff: false as boolean,
    is_superuser: false as boolean,
  },
};

export default initialState;
