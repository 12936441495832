import React, { useCallback } from 'react';
import {
  getFieldName,
  unusableLabel,
} from 'components/AnnotationRegions/AnnotationRow/annotationRow';
import styles from 'components/AnnotationRegions/annotationRegions.module.css';
import Form from 'components/Forms';
import useSaveAnnotate from 'hooks/useSaveAnnotate';
import useNotices from 'hooks/useNotices';
import { useWavesurfer } from 'components/WavesurferJS/wavesurfer.context';

const AnnotationForm: React.FC = ({ children }) => {
  const { soundDetails, regions, nextSound, ready } = useWavesurfer();
  const soundEntryId = soundDetails?.sound_entry_id;
  const { setNotice } = useNotices();
  const { saveAnnotation } = useSaveAnnotate();

  const onSubmitFailure = useCallback(() => {
    setNotice({
      type: 'failure',
      message: 'Annotations are not filled correctly.',
    });
  }, [setNotice]);
  return ready ? (
    <Form
      onSubmitSuccess={async (data) => {
        if (!soundEntryId) {
          return;
        }
        const unusableFile = Boolean(data.values.unusableFile);
        const response = await saveAnnotation({
          sound_entry: soundEntryId,
          sound_unusable: unusableFile,
          annotations: regions.map((region) => {
            const unusableRegion =
              !!data.values[getFieldName(region, 'unusable')];
            return {
              annotation: !soundDetails?.settings.can_be_modified
                ? region.id
                : undefined,
              start: region.start,
              end: region.end,
              label:
                unusableFile || unusableRegion
                  ? unusableLabel
                  : `${data.values[getFieldName(region, 'label')]}`,
              accepted: !!data.values[getFieldName(region, 'accepted')],
              unusable: unusableRegion,
            };
          }),
        });
        if (response.success) {
          await nextSound();
          return;
        }
        setNotice({
          type: 'failure',
          message: response.message,
        });
      }}
      onSubmitFailure={onSubmitFailure}
      className={styles.regions}
    >
      {children}
    </Form>
  ) : null;
};

export default AnnotationForm;
