import { TFieldValue } from 'components/Forms';
import { ControlledInputProps } from 'components/Forms/ControlledField/input';

const groupCreateStructure: ControlledInputProps[] = [
  {
    name: 'name',
    type: 'text',
    validate: (str: TFieldValue): string =>
      str && (str as string).length > 3 ? '' : 'Group name is too short.',
    label: 'Group name',
    placeholder: 'Enter group name',
  },
];

export default groupCreateStructure;
