import config from 'api/config';
import fetchApi from 'api/fetchApi';

interface Annotation {
  accepted: boolean;
  start: number;
  end: number;
  id: string;
  label: string;
}

export interface AnnotatePayload {
  sound: {
    annotations: Annotation[];
    frequencies: unknown | null;
  };
  settings: {
    can_annotate: boolean;
    can_be_accepted: boolean;
    can_be_modified: boolean;
    can_delete: boolean;
    disable_resize: boolean;
    entries: unknown[];
    limit_resize: boolean;
    title: string;
    type: 'last_validation' | 'annotation' | 'validation';
  };
  sound_entry_id: string;
  sound_entry_file: string;
  package: {
    description: string;
  };
}

type AnnotatePostResponse =
  | {
      success: true;
      data: AnnotatePayload;
    }
  | {
      success: false;
      message: string;
    };

const annotatePost = async (): Promise<AnnotatePostResponse> => {
  try {
    const response = await fetchApi<AnnotatePayload & { detail?: string }>({
      method: 'POST',
      path: `${config.startAnnotate}`,
    });
    if (response.success && response.payload) {
      return {
        success: true,
        data: response.payload,
      };
    } else {
      return {
        success: false,
        message:
          response.payload?.detail || 'Failed to fetch sound to annotate.',
      };
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to fetch sound to annotate.',
    };
  }
};

export default annotatePost;
