import React from 'react';
import styles from 'components/StatisticsDashboard/statisticsDashboard.module.css';
import StatisticsForm from 'components/StatisticsForm';
import UsersStatistics from './usersStatistics';

const AdminDashboard: React.FC = () => (
  <div className={styles.root}>
    <StatisticsForm />
    <UsersStatistics />
  </div>
);

export default AdminDashboard;
