import saveAnnotatePost, {
  IAnnotatorSoundData,
} from 'api/annotationTool/saveAnnotatePost';
import { useState } from 'react';

interface useSaveAnnotateReturn {
  saveAnnotation: (
    payload: IAnnotatorSoundData
  ) => ReturnType<typeof saveAnnotatePost>;
  annotationSaving: boolean;
}

const useSaveAnnotate = (): useSaveAnnotateReturn => {
  const [annotationSaving, setAnnotationSaving] = useState(false);

  const saveAnnotation = async (payload: IAnnotatorSoundData) => {
    setAnnotationSaving(true);
    const result = saveAnnotatePost(payload);
    setAnnotationSaving(false);
    return result;
  };

  return { annotationSaving, saveAnnotation };
};

export default useSaveAnnotate;
