import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

interface IAreaRefresher {
  refreshCount: number;
  forceRefresh: () => void;
}

const AreaRefresherContext = createContext<IAreaRefresher | undefined>(
  undefined,
);

export const AreaRefresher: React.FC = ({ children }) => {
  const [refreshCount, setRefreshCount] = useState(0);
  const forceRefresh = useCallback(
    () => setRefreshCount((value) => value + 1),
    [],
  );
  return (
    <AreaRefresherContext.Provider value={{ refreshCount, forceRefresh }}>
      {children}
    </AreaRefresherContext.Provider>
  );
};

const useAreaRefresher = (): (() => void) => {
  const context = useContext(AreaRefresherContext);
  if (context === undefined) {
    throw Error('called useAreaRefresher outside AreaRefresherContext');
  }
  return context.forceRefresh;
};

export const useAreaRefresherListener = (callback: () => unknown): void => {
  const context = useContext(AreaRefresherContext);
  if (context === undefined) {
    throw Error('called useAreaRefresher outside AreaRefresherContext');
  }
  const { refreshCount } = context;
  useEffect(() => {
    if (refreshCount > 0) {
      callback();
    }
  }, [refreshCount]);
};

export default useAreaRefresher;
