export default {
  apiPath: '/api/v1/',
  jwtVerify: 'accounts/jwt/verify',
  jwtCreate: 'accounts/jwt/create/',
  jwtRefresh: 'accounts/jwt/refresh/',
  resetPass: 'accounts/users/reset_password/',
  resetPasswordConfirm: 'accounts/users/reset_password_confirm/',
  accountsLogout: 'accounts/logout/',
  accountsUsers: 'accounts/users/',
  accountsUserMe: 'accounts/users/me/',
  packages: 'packages/packages/',
  labelsSuggestions: 'packages/labels/',
  labelsBlacklist: 'packages/labels/blacklist',
  labelsWhitelist: 'packages/labels/whitelist',
  startAnnotate: 'packages/annotate/',
  saveAnnotate: 'packages/soundentry/annotate/',
  packagesSetUploaded: 'packages/packages/{id}/set_uploaded/',
  audiodbPackages: 'audiodb/packages/',
  audiodbPackagesImport: 'audiodb/annotations/import/',
  audiodbPackagesImportIt: 'audiodb/packages/{id_package}/import_it/',
  companies: 'accounts/companies/',
  groups: 'groups/groups/',
  sounds: 'packages/sounds/',
  me: 'accounts/users/me/',
  ranking: 'rankings/',
  statsUsers: 'stats/users/',
};
