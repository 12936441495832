import { useEffect, useState } from 'react';
import packagesGET, { IPackagesData } from 'api/packages/packagesGet';
import { ISearchParams } from 'components/GroupCreate/groupCreate';

const usePackageList = () => {
  const [packages, setPackages] = useState<IPackagesData>();
  const [packagesLoading, setLoading] = useState(true);

  const fetchPackages = async (param?: ISearchParams) => {
    const receivedPackages = await packagesGET({
      params: `limit=1000&name=${param?.searchTerm || ''}`,
    });

    if (receivedPackages) {
      setPackages(receivedPackages);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  return { packages, packagesLoading, fetchPackages };
};

export default usePackageList;
