import React from 'react';
import TitledSection from 'components/TitledSection';
import Paging from 'components/FilteralbeDataList/paging';
import usersList, { IUsersData } from 'api/accounts/usersGet';
import { useAreaRefresherListener } from 'hooks/useAreaRefresher';
import Icon from 'components/Icon';
import useForm from 'hooks/useForm';
import FilterableDataList from 'components/FilteralbeDataList';
import {
  DEFAULT_PAGE_SIZE,
  useFilterableDataList,
} from 'components/FilteralbeDataList/filteralbeDataList';
import UserRow from './UserRow';
import usersListStructure from './usersList.structure';
import styles from './usersList.module.css';
import FilterInput from 'components/FilteralbeDataList/filter';

const UsersList: React.FC = () => (
  <TitledSection
    title={
      <div className={styles.row}>
        <div className={`${styles.title} ${styles.row__id}`}>ID</div>
        <div className={`${styles.title} ${styles.row__user}`}>Username</div>
        <div className={`${styles.title} ${styles.row__email}`}>Email</div>
        <div className={`${styles.title} ${styles.row__company}`}>Company</div>
        <div className={`${styles.title} ${styles.row__role}`}>Is staff</div>
        <div className={`${styles.title} ${styles.row__role}`}>Is admin</div>
        <div className={`${styles.title} ${styles.row__status}`}>Status</div>
        <div className={`${styles.title} ${styles.row__actions}`}>Actions</div>
      </div>
    }
  >
    <FilterableDataList
      name="usersList"
      pagination
      fetchData={(url) => usersList({ params: url })}
      className={styles.row}
    >
      <Headers />
      <List />
      <Pagination />
    </FilterableDataList>
  </TitledSection>
);

const Headers: React.FC = () => {
  const { methods } = useForm();
  return (
    <>
      <div className={`${styles.row__id} ${styles.row__cell_form}`} />
      {usersListStructure.map(({ wrapperClass, field }) => (
        <div
          key={field.name}
          className={`${wrapperClass} ${styles.row__cell_form}`}
        >
          <FilterInput {...field} />
        </div>
      ))}
      <div className={`${styles.row__cell_form} ${styles.row__actions}`}>
        <button
          onClick={() => methods.clearForm()}
          className={`${styles.actionButton}`}
          title="clear filters"
        >
          <Icon name="remove-filters" attributes={{ width: 25, height: 25 }} />
        </button>
      </div>
    </>
  );
};

const List: React.FC = () => {
  const { error, loading, data, reload } = useFilterableDataList<IUsersData>();
  useAreaRefresherListener(reload);
  const list = data?.results;
  const total = data?.count;

  if (total === 0)
    return <span className={styles.content}>No data found.</span>;
  if (loading) return <div className={styles.content}>Loading...</div>;
  if (error) return <div className={styles.content}>{error}</div>;

  return (
    <>
      <ul className={styles.content} aria-label="Users list">
        {list?.map((item) => (
          <UserRow key={item.id} {...item} reload={reload} />
        ))}
      </ul>
      <div className={styles.footer}>
        <Paging max={Math.ceil((total || 1) / DEFAULT_PAGE_SIZE)} />
      </div>
    </>
  );
};

const Pagination: React.FC = () => {
  const { data } = useFilterableDataList<IUsersData>();
  const total = data?.count;
  return data ? (
    <div className={styles.footer}>
      <Paging max={Math.ceil((total || 1) / DEFAULT_PAGE_SIZE)} />
    </div>
  ) : null;
};

export default UsersList;
