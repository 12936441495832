import config from 'api/config';
import fetchApi from 'api/fetchApi';
import { IPackage } from './_types';

export interface IPackagesData {
  count?: number;
  next?: string;
  previous?: string;
  results?: IPackage[];
}

export type TPackagesGETPayload = {
  params: string;
};

const packagesGET = async (
  args: TPackagesGETPayload,
): Promise<IPackagesData> => {
  const response = await fetchApi<IPackagesData>({
    method: 'GET',
    path: `${config.packages}?${args.params}`,
  });

  const { payload } = response;

  return {
    count: payload?.count || 0,
    next: payload?.next || '',
    previous: payload?.previous || '',
    results: payload?.results || [],
  };
};

export default packagesGET;
