import packagesGet, { IPackagesData } from 'api/packages/packagesGet';
import React from 'react';
import Paging from 'components/FilteralbeDataList/paging';
import { CompaniesOptionsWrapper } from 'hooks/useCompaniesOptionsFromContext';
import { MassActionsWrapper } from 'hooks/useMassActions';
import MassActions from 'components/PackagesManage/MassActions';
import FilterableDataList, {
  DEFAULT_PAGE_SIZE,
  useFilterableDataList,
} from 'components/FilteralbeDataList/filteralbeDataList';
import styles from './packagesManage.module.css';
import PackagesRow from './PackagesRow/packagesRow';
import PackagesTableHeader from './PackagesTableHeader';
import PackagesRowFilters from './packagesRowFilters';
import TrashPopup from './PackagesTrashPopup';
//import EditPopupExtended from './PackagesEditPopup';
import EditPopup from './PackageEdit';

const fetchPackagesList = async (url: string) =>
  await packagesGet({ params: url });

const PackagesManage: React.FC = () => (
  <div className={styles.root}>
    <CompaniesOptionsWrapper>
      <FilterableDataList
        pagination
        fetchData={fetchPackagesList}
        name="packagesManageList"
      >
        <PackagesRowFilters
          title="Filter not completed packages"
          fieldIsFinishedEqualTo={false}
        />
        <MassActionsWrapper>
          <MassActions />
          <PackagesTableHeader />
          <List />
        </MassActionsWrapper>
        <Pagination />
      </FilterableDataList>
    </CompaniesOptionsWrapper>
  </div>
);

const List: React.FC = () => {
  const { data, error, loading, reload } = useFilterableDataList<IPackagesData>();
  const { results: packagesArray } = data || {};

  if (loading)
    return <span className={styles.message}>Loading...</span>;

  if (error)
    return <span className={styles.message}>Something went wrong.</span>;

  if (!packagesArray)
    return <span className={styles.message}>No data found.</span>;

  if (packagesArray.length === 0)
    return <span className={styles.message}>No packages found...</span>;

  return (
    <ul aria-label="Packages list">
      {packagesArray.map((item) => (
        <PackagesRow {...item} reload={reload} key={item.id}>
          <TrashPopup {...item} reload={reload} />
          <EditPopup props={item} reload={reload} />
          {/*<EditPopupExtended {...item} reload={reload} />*/}
        </PackagesRow>
      ))}
    </ul>
  );
};

const Pagination: React.FC = () => {
  const { loading, data } = useFilterableDataList<IPackagesData>();
  const total = data?.count || 0;
  return (
    <Paging
      max={Math.ceil((total || 1) / DEFAULT_PAGE_SIZE)}
      loading={loading}
    />
  );
};

export default PackagesManage;
