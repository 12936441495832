import React from 'react';

// Navigation imports
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Layout from 'components/Layout';

// Pages
import AnnotationTool from 'pages/AnnotationTool';
import Groups from 'pages/Groups';
import Packages from 'pages/Packages';
import Ranking from 'pages/Ranking';
import Statistics from 'pages/Statistics';
import Users from 'pages/Users';
import Login from 'pages/Login';
import Companies from 'pages/Companies';
import ResetPassword from 'pages/ResetPassword';

// Global stylesheet import (excluded from css-modules mechanism)
import './style/fonts.global.css';
import './style/stylesheet.global.css';
import './style/variables.global.css';
import './style/popups.global.css';

// Redux imports
import { Provider } from 'react-redux';
import { store } from 'reduxStore/store';
import Notices from 'components/Notices';
import Overlay from 'components/Overlay';
import RankingAvailableNotice from 'components/RankingAvailableNotice';
import { Redirect } from 'react-router';
import ErrorBoundary from 'components/ErrorBoundary';
import AuthRoute from './components/AuthRoute';
// import PackagesCreate from 'pages/PackagesCreate';

const App: React.FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Layout>
        <ErrorBoundary>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/auth" component={ResetPassword} />
            <AuthRoute path="/packages" component={Packages} />
            <AuthRoute path="/groups" component={Groups} />
            <AuthRoute path="/users" component={Users} />
            <AuthRoute path="/annotation-tool" component={AnnotationTool} />
            <AuthRoute path="/ranking" component={Ranking} />
            <AuthRoute path="/statistics" component={Statistics} />
            <AuthRoute path="/companies" component={Companies} />
            {/* <AuthRoute path="/create-package" component={PackagesCreate} /> */}
            <Redirect to="/statistics" />
          </Switch>
        </ErrorBoundary>
      </Layout>
    </BrowserRouter>
    <div id="popups-portal">
      {/**
       * Popups (componten/Popups) are rendered into this div
       * through React.createPortal function.
       */}
    </div>
    <Notices />
    <Overlay />
    <RankingAvailableNotice />
  </Provider>
);

export default App;
