import React, { useCallback } from 'react';
import ControlledInput, {
  ControlledInputProps,
} from 'components/Forms/ControlledField/input';
import useForm from 'hooks/useForm';
import { TFieldValue } from 'components/Forms';

const FilterInput: React.FC<ControlledInputProps> = (props) => {
  const { methods } = useForm();
  const setFiled = useCallback((value: TFieldValue) => {
    methods.setManyFields([
      { name: props.name, value: value },
      { name: '_page', value: 1 },
    ]);
  }, []);
  return <ControlledInput {...props} customSetField={setFiled} />;
};

export default FilterInput;
