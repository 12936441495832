import React, {
  MouseEventHandler,
  ReactNode,
  useRef,
  useEffect,
  ButtonHTMLAttributes,
} from 'react';
import ReactDOM from 'react-dom';
import usePopups from 'hooks/usePopups';
import ButtonSecondary from 'components/ButtonSecondary';
import styles from './popups.module.css';

export interface IPopupButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  unique: string;
  open: boolean;
}

type childrenAsFunction = (data: { cancelPopup: () => void }) => ReactNode;
export interface IPopupWrapper {
  unique: string;
  children: childrenAsFunction | ReactNode;
}

const KeyboardChandler: React.FC<{
  cancelPopup: (arg: string) => void;
  popupName: string;
}> = ({ cancelPopup, popupName }) => {
  const onKeyPress = (e: KeyboardEvent) => {
    const { key } = e;
    if (key === 'Escape') {
      cancelPopup(popupName);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [cancelPopup, popupName]);
  return null;
};

export const PopupButton: React.FC<IPopupButton> = ({
  unique,
  open,
  children,
  className = '',
  ...attributes
}) => {
  const { setPopup, cancelPopup } = usePopups();

  return (
    <button
      className={className}
      onClick={() => {
        if (open) setPopup(unique);
        else cancelPopup(unique);
      }}
      type="button"
      {...attributes}
    >
      {children}
    </button>
  );
};

const PopupWrapper: React.FC<IPopupWrapper> = ({ unique, children }) => {
  const { popupName: popupsArr, cancelPopup } = usePopups();

  const popupName = popupsArr.find((name) => name === unique) || '';
  const overlayRef = useRef(null);

  const handleClickBackground: MouseEventHandler<HTMLDivElement> = (e) => {
    const { target } = e;
    if (target === overlayRef.current) {
      cancelPopup(popupName);
    }
  };

  const handleClickButton = () => {
    cancelPopup(popupName);
  };

  return popupName === unique ? (
    <>
      {ReactDOM.createPortal(
        <div
          ref={overlayRef}
          className={styles.overlay}
          onClick={handleClickBackground}
        >
          <KeyboardChandler
            cancelPopup={handleClickButton}
            popupName={popupName}
          />
          <div className={styles.popup}>
            <div className={styles.popup__action}>
              <ButtonSecondary onClick={handleClickButton}>
                &#10006;
              </ButtonSecondary>
            </div>
            {typeof children === 'function'
              ? (children as childrenAsFunction)({
                cancelPopup: handleClickButton,
              })
              : children}
          </div>
        </div>,
        document.getElementById('popups-portal') as HTMLElement,
      )}
    </>
  ) : null;
};

export default PopupWrapper;
