import config from 'api/config';
import fetchApi from 'api/fetchApi';

export interface ICompany {
  name: string;
}

export type TCompaniesPOSTPayload = {
  name: string;
};

interface ICompaniesPOSTReturnType {
  success: boolean;
  message?: string;
  company?: ICompany;
}

const companiesPOST = async (
  args: ICompany,
): Promise<ICompaniesPOSTReturnType> => {
  const response = await fetchApi<ICompany & { detail?: string }>({
    method: 'POST',
    path: `${config.companies}`,
    payload: args,
  });

  const { success, status, payload } = response;

  if (success) {
    return {
      success: true,
      company: payload as ICompany,
    };
  }
  if (status === 400 && payload) {
    let message = '';
    Object.entries(payload).forEach((entry) => {
      const [name, val] = entry;
      message += `${name.toUpperCase()} - ${
        val.join ? val.join('') : JSON.stringify(val)
      } `;
    });
    message = message ? `Errors! ${message}` : 'Something went wrong';
    return {
      success: false,
      message,
    };
  }
  return {
    success: false,
    message:
      typeof payload?.detail === 'string'
        ? payload.detail
        : 'Something went wrong',
  };
};

export default companiesPOST;
