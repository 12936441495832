import { TFieldValue } from 'components/Forms';

type tPasswordValidator = (arg: TFieldValue) => string;

const passwordValidator: tPasswordValidator = (fieldValueOrEmptyString) => {
  if (typeof fieldValueOrEmptyString !== 'string') {
    return 'Incorrect value';
  }

  if (
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$/.test(fieldValueOrEmptyString)
  ) {
    return '';
  }
  return 'Enter 8 - 32 characters length. At least one uppercase, one lowercase, and one digit.';
};

export default passwordValidator;
