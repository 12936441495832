import { useState } from 'react';
import styles from './../annotationRegions.module.css';

import ButtonTertiary from 'components/ButtonTertiary';
import ControlledInput from 'components/Forms/ControlledField';
import { getRegionColor } from 'components/WavesurferJS/wavesurfer.helpers';
import { Region } from 'components/WavesurferJS/wavesurfer.plugins';
import useForm from 'hooks/useForm';
import cx from 'classnames';
import { useWavesurfer } from 'components/WavesurferJS/wavesurfer.context';
import Suggestions from 'components/AnnotationRegions/AnnotationRow/Suggestions';
import Icon from 'components/Icon';

const AnnotationRow = (params: { region: Region, updateRegion: (region: Region, fields: Partial<Region>) => void, removeRegion: (region: Region) => void, blacklist: string[], whitelist: string[] }) => {
  const { methods, form } = useForm();
  const { soundDetails } = useWavesurfer();
  const [isFocused, setFocused] = useState(false);
  const annotationLabelId = getFieldName(params.region, 'label');
  const inputValueRaw = form.values[annotationLabelId];
  const inputValue = inputValueRaw ? `${inputValueRaw}` : '';
  const handleChangeRegionColor = () => {
    params.updateRegion(params.region, { color: getRegionColor() });
  };
  const handleRemoveRegion = () => {
    params.removeRegion(params.region);
    methods.unregisterFields([
      getFieldName(params.region, 'unusable'),
      getFieldName(params.region, 'label'),
      getFieldName(params.region, 'accepted'),
    ]);
  };

  const handlePlayRegion = () => {
    params.region.play(0);
  };
  const isUnusable = Boolean(form.values[getFieldName(params.region, 'unusable')]);
  const isAccepted = Boolean(form.values[getFieldName(params.region, 'accepted')]);
  const label = form.values[getFieldName(params.region, 'label')];
  const isUnusableFile = Boolean(form.values.unusableFile);

  const toggleUnusable = () => {
    methods.setManyFields([
      {
        name: getFieldName(params.region, 'unusable'),
        value: !isUnusable,
      },
      {
        name: getFieldName(params.region, 'accepted'),
        value: false,
      },
      {
        name: getFieldName(params.region, 'label'),
        value: isUnusable ? label : label || unusableLabel,
      },
    ]);
  };
  const toggleAccepted = () => {
    methods.setField({
      name: getFieldName(params.region, 'accepted'),
      value: !isAccepted,
    });
  };
  return (
    <li
      key={params.region.id}
      className={cx(styles.regions__item, {
        [styles.region__unusable]: isUnusable || isUnusableFile,
        [styles.region__accepted]: isAccepted,
      })}
    >
      <div className={styles.regions__range}>
        <div className={styles.regions__rangeStart}>
          {params.region.start.toFixed(2)}
        </div>
        <div className={styles.regions__rangeEnd}>{params.region.end.toFixed(2)}</div>
      </div>
      <div className={styles.regions__input}>
        <ControlledInput
          styles={{ input: styles.input, message: styles.inputMessage }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          disabled={isAccepted || isUnusableFile || isUnusable}
          name={annotationLabelId}
          validate={(value, form) =>
            !value && !form.values[getFieldName(params.region, 'unusable')]
              ? 'Field cannot be empty'
              : ''
          }
          type="text"
          placeholder="Cannot be empty"
        />
        <Suggestions
          inputValue={inputValue}
          active={isFocused}
          blacklist={params.blacklist}
          whitelist={params.whitelist}
          onSelect={(item: string) => {
            methods.setField({ name: annotationLabelId, value: item });
            (document.activeElement as HTMLElement)?.blur?.();
          }}
        />
      </div>
      <div className={styles.regions__actions}>
        <ButtonTertiary
          onClick={handlePlayRegion}
          title="play"
          className={styles.action__button}
        >
          <Icon name="play" attributes={{ width: 25, height: 25 }} />
        </ButtonTertiary>
        {soundDetails?.settings.can_be_accepted && (
          <ButtonTertiary
            onClick={toggleAccepted}
            className={cx(styles.action__button, {
              [styles.action__button__accepted]: isAccepted,
            })}
            disabled={isUnusable || isUnusableFile || !label}
            title={isAccepted ? 'unaccept' : 'accept'}
          >
            <Icon
              name="check"
              attributes={{
                width: 25,
                height: 25,
                strokeWidth: isAccepted ? 2 : 1,
              }}
            />
          </ButtonTertiary>
        )}
        <ButtonTertiary
          disabled={isUnusableFile}
          onClick={toggleUnusable}
          className={cx(styles.action__button, {
            [styles.action__button__unusable]: isUnusable,
          })}
          title={isUnusable ? 'usable' : 'unusable'}
        >
          <Icon
            name="x"
            attributes={{
              width: 25,
              height: 25,
              strokeWidth: isUnusable ? 2 : 1,
            }}
          />
        </ButtonTertiary>
        {soundDetails?.settings.can_delete && (
          <ButtonTertiary
            onClick={handleRemoveRegion}
            title="remove"
            className={styles.action__button}
          >
            <Icon name="trash" attributes={{ width: 25, height: 25 }} />
          </ButtonTertiary>
        )}
        <button
          type="button"
          className={styles.regions__color__wrapper}
          onClick={handleChangeRegionColor}
          title="Color switcher"
        >
          <div
            style={{ background: params.region.color }}
            className={styles.regions__color}
          />
        </button>
      </div>
    </li>
  );
};

export const unusableLabel = '_unusable_';

export const getFieldName = (
  region: { id: string },
  field: 'label' | 'unusable' | 'accepted',
) => `${field}-${region.id}`;

export default AnnotationRow;
