import userStorage from 'lib/persistentStorage/storage.user';
import { actionUserData, actionUserLogout } from 'reduxStore/actions/user';
import { store } from 'reduxStore/store';
import { getUserDataFromToken } from './tokenDecode';

interface iUserCredentials {
  access: string;
  refresh: string;
}

function loginToStoreUser({ access, refresh }: iUserCredentials): boolean {
  const userData = getUserDataFromToken(access, refresh);
  if (userData) {
    userStorage.setItem({
      id: 'currentUser',
      ...userData,
    });
    store.dispatch(actionUserData(userData));
    return true;
  }
  userStorage.delItem('currentUser');
  store.dispatch(actionUserLogout());
  return false;
}

export default loginToStoreUser;
