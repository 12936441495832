export interface RoleConfig {
  isSuperuser: boolean;
  isStaff: boolean;
}
type Role = 'superuser' | 'staff';

export type AllowedRoles = Role[];

const SecuredRoutes: Record<string, AllowedRoles> = {
  '/packages': ['superuser', 'staff'],
  '/create-package': ['superuser', 'staff'],
  '/companies': ['superuser'],
  '/users': ['superuser'],
  '/groups': ['superuser'],
};
const getAllowedRoles = (path: string): AllowedRoles | undefined =>
  SecuredRoutes[path];

export const hasAccessToPath = (path: string, role: RoleConfig): boolean => {
  const allowedRoles = getAllowedRoles(path);
  if (allowedRoles === undefined) {
    return true;
  }
  return hasAccess(allowedRoles, role);
};

export const hasAccess = (
  allowedRoles: AllowedRoles,
  role: RoleConfig,
): boolean => {
  if (allowedRoles.includes('superuser') && role.isSuperuser) {
    return true;
  }

  if (allowedRoles.includes('staff') && role.isStaff) {
    return true;
  }

  return false;
};
