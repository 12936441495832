import ButtonPrimary from 'components/ButtonPrimary';
import Form, { TFormSubmitData } from 'components/Forms';
import ControlledInput from 'components/Forms/ControlledField';
import Header from 'components/LoginForm/Header';
import useForgottenPass from 'hooks/useForgottenPass';
import useNotices from 'hooks/useNotices';
import useUser from 'hooks/useUser';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import passwordValidator from 'validators/password';
import styles from './resetPasswordForm.module.css';

const ResetPasswordForm: React.FC<
  RouteComponentProps & { uid: string; token: string }
> = ({ uid, token, history }) => {
  const { setNotice } = useNotices();
  const { submitCreatePassword } = useForgottenPass();
  const { userLogOut, token: currentlyLoggedIn } = useUser();

  const onSubmitSuccess = async (data: TFormSubmitData) => {
    const { password } = data.values as { password: string };
    const requestPayload = {
      uid,
      token,
      new_password: password,
    };

    const success = await submitCreatePassword(requestPayload);

    if (success) {
      if (currentlyLoggedIn) userLogOut();
      history.replace('/login');
    }
  };

  const onSubmitFailure = () => {
    setNotice({
      type: 'failure',
      message: 'Please fill all fields.',
    });
  };

  return (
    <Form
      data-testid="resetPasswordForm"
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFailure={onSubmitFailure}
      className={styles.root}
    >
      <Header />
      {rules}
      <ControlledInput
        type="password"
        name="password"
        validate={passwordValidator}
        {...{
          placeholder: 'Enter new password',
          label: 'New password:',
        }}
      />
      <ControlledInput
        type="password"
        name="repeat"
        validate={(value, form) => {
          if (!value) {
            return 'Field cannot be empty';
          }
          if (value !== form.values.password) {
            return 'Passwords are not the same.';
          }
          return '';
        }}
        {...{
          placeholder: 'Repeat new password',
          label: 'Repeat new password:',
        }}
      />
      <ButtonPrimary type="submit">Submit password</ButtonPrimary>
    </Form>
  );
};

const rules = (
  <>
    <div className={styles.title}>You are about to create new password</div>
    <div className={styles.rules__title}>Password must contain:</div>
    <ul className={styles.rules__list}>
      <li>8-32 characters</li>
      <li>at least 1 uppercase</li>
      <li>at least 1 lowercase</li>
      <li>at least 1 digit</li>
    </ul>
  </>
);

export default withRouter(ResetPasswordForm);
