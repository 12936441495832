import usersDel from 'api/accounts/usersDel';
import usersPatch from 'api/accounts/usersPatch';
import { TFieldValue } from 'components/Forms';
import { useCallback, useMemo } from 'react';
import useNotices from './useNotices';

interface iHookParams {
  userId: number;
}

interface iToReturn {
  deleteUser: (password: string) => Promise<boolean>;
  editUser: (args: Record<string, TFieldValue>) => Promise<boolean>;
}

const useUserEditDelete = ({ userId }: iHookParams): iToReturn => {
  const { setNotice } = useNotices();

  const deleteUser = useCallback(
    async (userPassword: string) => {
      const { success, message } = await usersDel({ userId, userPassword });
      setNotice({ type: success ? 'success' : 'failure', message });
      return success;
    },
    [userId],
  );

  const editUser = useCallback(
    async (fields: Record<string, TFieldValue>) => {
      const { success, message } = await usersPatch({ userId, fields });
      setNotice({ type: success ? 'success' : 'failure', message });
      return success;
    },
    [userId],
  );

  const memoized = useMemo(
    () => ({
      deleteUser,
      editUser,
    }),
    [deleteUser, editUser],
  );

  return memoized;
};

export default useUserEditDelete;
