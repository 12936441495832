import React, { InputHTMLAttributes } from 'react';
import restyle, { TStylesheet } from 'components/../restyler';
import defaultStyles from './checkbox.module.css';
import { FormInputProps } from '../_types';

export interface CheckboxProps
  extends InputHTMLAttributes<HTMLInputElement>,
    FormInputProps {
  label?: string;
  labelTrue?: string;
  labelFalse?: string;
  styles?: TStylesheet;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    setField,
    label,
    message,
    title,
    checked,
    labelTrue,
    labelFalse,
    readOnly = false,
    disabled = false,
    touched,
    styles: overriddenStyles,
    ...restInputProps
  } = props;
  const styles = restyle(defaultStyles, overriddenStyles);

  const className = checked ? styles.root_checked : styles.root_unchecked;

  const labelWhenTrue = labelTrue || label;
  const labelWhenFalse = labelFalse || label;
  const finalLabel = checked ? labelWhenTrue : labelWhenFalse;

  return (
    <div className={`${className} ${styles.root}`}>
      {title ? <div className={styles.title}>{title}</div> : null}
      <label className={styles.wrapper}>
        <input
          {...restInputProps}
          checked={checked}
          type="checkbox"
          className={styles.input}
          onChange={(e) => {
            const { checked } = e.target;
            setField(checked);
          }}
          readOnly={readOnly}
          disabled={disabled}
          data-error={message && touched ? true : undefined}
        />
        <div className={styles.switch} />
        <div className={styles.label}>{finalLabel}</div>
      </label>
      <span className={styles.message}>{touched && message}</span>
    </div>
  );
};

export default Checkbox;
