import fetchApiNoAuth from 'api/fetchApiNoAuth';
import { store } from 'reduxStore/store';
import config from 'api/config';

interface iRefreshResponse {
  access?: string;
  detail?: string;
}

const jwtRefresh = async (): Promise<{
  success: boolean;
  detail?: string;
  access?: string;
}> => {
  const { refresh } = store.getState().user.data;

  try {
    const { success, payload } = await fetchApiNoAuth<iRefreshResponse>({
      method: 'POST',
      payload: { refresh },
      path: config.jwtRefresh,
    });

    return {
      success,
      ...payload,
    };
  } catch {
    return {
      success: false,
      detail: 'Session expired. Please log in again.',
    };
  }
};

export default jwtRefresh;
