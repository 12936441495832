import React from 'react';
import ControlledInput from 'components/Forms/ControlledField';
import Icon from 'components/Icon';
import PopupWrapper, { PopupButton } from 'components/Popups';
import ButtonPrimary from 'components/ButtonPrimary';
import ButtonSecondary from 'components/ButtonSecondary';
import useUserEditDelete from 'hooks/useUserEditDelete';
import Form from 'components/Forms';
import styles from '../usersList.module.css';

const UserDelete: React.FC<{
  id: number;
  username: string;
  reload: () => void;
}> = ({ id, username, reload }) => {
  const { deleteUser } = useUserEditDelete({ userId: id });

  return (
    <>
      <PopupButton
        className={styles.actionButton}
        unique={`popup-delete-user-${id}`}
        title="Delete user"
        open
      >
        <Icon name="trash" attributes={{ width: 30, height: 30 }} />
      </PopupButton>

      <PopupWrapper unique={`popup-delete-user-${id}`}>
        {({ cancelPopup }) => (
          <Form
            data-testid="deleteUserConfirmationForm"
            onSubmitSuccess={async (formCtx) => {
              const { password } = formCtx.values;
              const success = await deleteUser(password as string);
              if (success) {
                formCtx.methods.clearForm();
                cancelPopup();
                reload();
              }
            }}
          >
            <div className="popup__content">
              <div className="popup__info">You are about to delete user: </div>
              <div className="popup__info_stressed">{username}</div>
              <div className="popup__info">To proceed, enter password:</div>
              <ControlledInput
                name="password"
                type="password"
                validate={(val) =>
                  typeof val === 'string' && val.length
                    ? ''
                    : 'Field is required'
                }
                {...{
                  placeholder: 'Password',
                }}
              />
              <div className="popup__actions">
                <ButtonPrimary type="submit">Proceed</ButtonPrimary>
                <ButtonSecondary onClick={cancelPopup}>Cancel</ButtonSecondary>
              </div>
            </div>
          </Form>
        )}
      </PopupWrapper>
    </>
  );
};

export default UserDelete;
