import React from 'react';
import Form, { TFormSubmitData } from 'components/Forms';
import ButtonPrimary from 'components/ButtonPrimary';
import useUser from 'hooks/useUser';
import ControlledInput from 'components/Forms/ControlledField';
import useNotices from 'hooks/useNotices';
import Header from './Header';
import ForgotPass from './ForgotPass';
import loginFormStructure from './loginForm.structure';
import styles from './loginForm.module.css';

const FormParent: React.FC = () => {
  const { userLogIn } = useUser();
  const { setNotice } = useNotices();

  const onSubmitSuccess = async (data: TFormSubmitData) => {
    const { login, password } = data.values as {
      login: string;
      password: string;
    };
    userLogIn({ login, password });
  };

  const onSubmitFailure = () => {
    setNotice({
      type: 'failure',
      message: 'Please fill all fields.',
    });
  };

  return (
    <Form
      data-testid="loginForm"
      onSubmitSuccess={onSubmitSuccess}
      onSubmitFailure={onSubmitFailure}
      className={styles.root}
    >
      <Header />
      {loginFormStructure.map((field) => (
        <ControlledInput key={field.name} {...field} />
      ))}
      <ForgotPass />
      <ButtonPrimary type="submit">Sign in</ButtonPrimary>
    </Form>
  );
};

export default FormParent;
