import React, { useCallback, useEffect } from 'react';
import useNotices from 'hooks/useNotices';
import useUser from 'hooks/useUser';
import rankingCandidatesGet from 'api/ranking/rankingCandidatesGet';

const RankingAvailableNotice: React.FC = () => {
  const { setNotice } = useNotices();
  const { login } = useUser();

  const notifyAboutRanking = useCallback(async () => {
    const receivedSound = await rankingCandidatesGet();
    if (
      receivedSound.success &&
      receivedSound.payload?.annotation0 &&
      receivedSound.payload?.annotation1
    ) {
      setNotice({
        message: 'There are rankings comparisons available!',
        type: 'success',
      });
    }
  }, []);

  useEffect(() => {
    if (login) {
      notifyAboutRanking();
    }
  }, [login]);

  return null;
};

export default RankingAvailableNotice;
