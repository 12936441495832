/* eslint-disable no-empty */
import logoutStoredUser from 'lib/userHelpers/logoutHelper';
import { actionSetNotice } from 'reduxStore/actions/notices';
import { store } from 'reduxStore/store';
import config from './config';
import { IResponse, IRequest, IMethodArguments } from './_types';

export function handleUnauthorized(message: string) {
  logoutStoredUser();
  store.dispatch(
    actionSetNotice({
      type: 'message',
      message: message || 'Session expired. Please log in again.',
    }),
  );
}

/**
 * @param payload optional object,
 * @param headers optional object,
 * @param path optional string
 * @param method optional string
 * @returns {
 *  success: boolean,
 *  status: null (if request failed) or number (status),
 *  payload: null if no payload, object with detail if error, object with data if data.
 * }
 */
const fetchApi = async function <ExpectedData>({
  payload,
  headers = {},
  path,
  method,
  isForm = false,
}: IMethodArguments): Promise<IResponse<ExpectedData>> {
  const toReturn: IResponse<ExpectedData> = {
    success: false,
    status: null,
    payload: null,
  };

  const configFetch: IRequest = {
    method: method || 'GET',
    headers: {
      Accept: 'application/json',
      ...(isForm ? {} : { 'Content-Type': 'application/json' }),
      Authorization: `JWT ${store.getState().user.data.token || ''}`,
      ...headers,
    },
  };

  if (method !== 'GET' && payload instanceof Object) {
    configFetch.body = JSON.stringify({ ...payload });
  }

  if (method !== 'GET' && isForm) {
    configFetch.body = payload as FormData;
  }

  try {
    const response = await fetch(`${config.apiPath}${path}`, configFetch);

    toReturn.success = response.status >= 200 && response.status < 400;
    toReturn.status = response.status;

    try {
      toReturn.payload = await response.json();
    } catch {}

    if (response.status === 401) {
      const detail = (toReturn?.payload as null | { detail: string })?.detail;
      handleUnauthorized(detail || '');
    }
  } catch {}

  return toReturn;
};

export default fetchApi;
