import fetchApiNoAuth from 'api/fetchApiNoAuth';
import config from 'api/config';

type TMethodArguments = {
  new_password: string;
  uid: string;
  token: string;
};

interface IErrorResponse {
  detail: string;
}

const resetPasswordConfirm = async (
  args: TMethodArguments,
): Promise<{ success: boolean; detail: string }> => {
  const { success, status, payload } = await fetchApiNoAuth({
    method: 'POST',
    payload: args,
    path: config.resetPasswordConfirm,
  });

  const toReturn = { success: false, detail: 'Something went wrong.' };

  if (success && status === 204) {
    toReturn.success = true;
    toReturn.detail = 'Password has been created.';
  } else {
    toReturn.success = false;
    toReturn.detail = '';
    Object.entries((payload as IErrorResponse) || {}).forEach((entry) => {
      const [fieldName, fieldValue] = entry;
      toReturn.detail += `${fieldName}: ${fieldValue} `;
    });
  }
  return toReturn;
};

export default resetPasswordConfirm;
