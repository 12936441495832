import React, { ErrorInfo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import styles from './errorBoundary.module.css';

interface ErrorBoundaryProps extends RouteComponentProps {
  errorMessage?: ErrorBoundary;
}
interface ErrorBoundaryState {
  hasError?: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  detachListener: undefined | (() => void);

  componentDidMount(): void {
    this.detachListener = this.props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount(): void {
    this.detachListener && this.detachListener();
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return (
        <div className={styles.message}>
          {this.props.errorMessage ||
            'Something went wrong. Try again or contact support.'}
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
