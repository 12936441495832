import Icon from 'components/Icon';
import React from 'react';
import { NavLink } from 'react-router-dom';
import useUser from 'hooks/useUser';
import { hasAccessToPath } from 'lib/security';
import styles from './navigationBar.module.css';

const paths = [
  { path: '/statistics', label: 'Statistics', icon: 'statistics' },
  { path: '/ranking', label: 'Ranking', icon: 'ranking' },
  { path: '/annotation-tool', label: 'Annotation Tool', icon: 'annotations' },
  { path: '/users', label: 'Users', icon: 'users' },
  { path: '/groups', label: 'Groups', icon: 'groups' },
  { path: '/packages', label: 'Packages', icon: 'packages' },
  { path: '/companies', label: 'Companies', icon: 'company' },
];
type iconType =
  | 'statistics'
  | 'annotations'
  | 'ranking'
  | 'users'
  | 'groups'
  | 'packages';

const icoAttributes = {
  width: 25,
  height: 25,
  stroke: '#fff',
};
const NavigationBar: React.FC<{
  toggleAsideExpanded: () => void;
  asideExpanded: boolean;
}> = ({ asideExpanded }) => {
  const className = asideExpanded ? styles.rootExpanded : styles.rootFolded;
  const { role } = useUser();

  return (
    <nav className={`${className} ${styles.root}`} aria-label="Main navigation">
      <ul className={styles.list} role="navigation">
        {paths.map((item) =>
          hasAccessToPath(item.path, role) ? (
            <li className={styles.list__item} key={item.path}>
              <NavLink
                activeClassName={styles.list__itemAnchor_active}
                to={item.path}
                className={styles.list__itemAnchor}
              >
                <div className={styles.list__itemIcon}>
                  <Icon
                    attributes={icoAttributes}
                    name={item.icon as iconType}
                  />
                </div>
                <span className={styles.list__itemLabel}>{item.label}</span>
              </NavLink>
            </li>
          ) : null,
        )}
      </ul>
    </nav>
  );
};

export default NavigationBar;
