import React from 'react';
import { TFieldValue } from 'components/Forms';
import useCompanyName from 'hooks/useCompanyName';
import useCompaniesOptionsFromContext from 'hooks/useCompaniesOptionsFromContext';

interface EditedRowProps {
  fieldName: string;
  fieldValue: TFieldValue;
}

const EditedRow: React.FC<EditedRowProps> = ({ fieldName, fieldValue }) => {
  const { options: companiesOptions } = useCompaniesOptionsFromContext();
  const isCompany = fieldName === 'company';
  const companyName = useCompanyName(companiesOptions, fieldValue);
  const value = isCompany ? companyName : JSON.stringify(fieldValue);
  return (
    <div key={fieldName}>
      {fieldName}:{value}
    </div>
  );
};

export default EditedRow;
