import React, { JSXElementConstructor } from 'react';
import CompaniesSelect from 'components/Forms/CompaniesSelect';
import Field from '../ControllerForField';
import { TFieldValidator, TFieldValue } from '../_types';
import Checkbox from '../StyledCheckbox';
import Input from '../StyledInput';
import Select from '../StyledSelect';
import MultiselectField from '../StyledMultiselectField';
import { Omit } from 'react-redux';
import SelectFieldWithSearch from 'components/Forms/StyledSelectFieldWithSearch';
import SimpleCheckbox from 'components/Forms/SimpleCheckbox/simpleCheckbox';

type ComponentInputTypeProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
> = Omit<React.ComponentProps<T>, 'setField' | 'message' | 'touched' | 'value'>;

type InputProps = {
  type: 'text' | 'password' | 'date' | 'hidden';
} & ComponentInputTypeProps<typeof Input>;

type CheckboxProps = { type: 'checkbox' } & ComponentInputTypeProps<
  typeof Checkbox
>;
type SimpleCheckboxProps = {
  type: 'simple_checkbox';
} & ComponentInputTypeProps<typeof SimpleCheckbox>;

type SelectProps = { type: 'select' } & ComponentInputTypeProps<typeof Select>;

type SelectWithSearchProps = {
  type: 'select_with_search';
} & ComponentInputTypeProps<typeof SelectFieldWithSearch>;

type MultiselectProps = { type: 'multiselect' } & ComponentInputTypeProps<
  typeof MultiselectField
>;
type CompaniesProps = { type: 'companies_select' } & ComponentInputTypeProps<
  typeof CompaniesSelect
>;

type InputType =
  | 'text'
  | 'checkbox'
  | 'simple_checkbox'
  | 'select'
  | 'select_with_search'
  | 'password'
  | 'date'
  | 'hidden'
  | 'companies_select'
  | 'multiselect';

export type ControlledInputProps = {
  type: InputType;
  name: string;
  validate?: TFieldValidator;
  initialTouched?: boolean;
  initialValue?: TFieldValue;
  customSetField?: (value: TFieldValue) => void;
} & (
  | InputProps
  | CheckboxProps
  | SimpleCheckboxProps
  | SelectProps
  | SelectWithSearchProps
  | MultiselectProps
  | CompaniesProps
);

const ControlledInput: React.FC<ControlledInputProps> = (props) => {
  const {
    name,
    validate,
    initialTouched,
    initialValue,
    customSetField,
    ...inputProps
  } = props;
  return (
    <Field
      name={name}
      validate={validate}
      type={props.type}
      initialTouched={initialTouched}
      initialValue={initialValue}
    >
      {({ name, value, touched, setField: defaultSetField, errorMessage }) => {
        const setField = customSetField ?? defaultSetField;
        switch (inputProps.type) {
          case 'text':
          case 'date':
          case 'hidden':
          case 'password':
            return (
              <Input
                {...inputProps}
                type={inputProps.type}
                name={name}
                value={parseValueToInput(value)}
                touched={touched}
                setField={setField}
                message={errorMessage}
              />
            );
          case 'checkbox':
            return (
              <Checkbox
                {...inputProps}
                name={name}
                checked={!!value}
                touched={touched}
                setField={setField}
                message={errorMessage}
              />
            );
          case 'simple_checkbox':
            return (
              <SimpleCheckbox
                {...inputProps}
                name={name}
                checked={!!value}
                touched={touched}
                setField={setField}
                message={errorMessage}
              />
            );
          case 'select':
            return (
              <Select
                {...inputProps}
                name={name}
                value={parseValueToInput(value)}
                touched={touched}
                setField={setField}
                message={errorMessage}
              />
            );
          case 'select_with_search':
            return (
              <SelectFieldWithSearch
                {...inputProps}
                value={value}
                name={name}
                touched={touched}
                setField={setField}
                message={errorMessage}
              />
            );
          case 'multiselect':
            return (
              <MultiselectField
                {...inputProps}
                value={value}
                name={name}
                touched={touched}
                setField={setField}
                message={errorMessage}
              />
            );
          case 'companies_select':
            return (
              <CompaniesSelect
                {...inputProps}
                value={value}
                name={name}
                touched={touched}
                setField={setField}
                message={errorMessage}
              />
            );
          default:
            throw new Error('Wrong input type');
        }
      }}
    </Field>
  );
};

const parseValueToInput = (value: TFieldValue): number | string | undefined => {
  if (!value) {
    return '';
  }
  if (value === true) {
    return 1;
  }
  return value;
};

export default ControlledInput;
