import { useCallback, useEffect, useState } from 'react';
import annotatePost, { AnnotatePayload } from 'api/annotationTool/annotatePost';
import useNotices from 'hooks/useNotices';

interface useAnnotatorSoundResult {
  soundDetails: AnnotatePayload | undefined;
  soundLoading: boolean;
  nextSound: () => void;
  reload: () => void;
}

const useAnnotatorSound = (): useAnnotatorSoundResult => {
  const [soundDetails, setSoundDetails] = useState<AnnotatePayload>();
  const [soundLoading, setSoundLoading] = useState(true);
  const { setNotice } = useNotices();

  const nextSound = async () => {
    setSoundLoading(true);
    const response = await annotatePost();
    if (response.success) {
      setSoundDetails(response.data);
    } else {
      setSoundDetails(undefined);
      setNotice({
        type: 'failure',
        message: response.message,
      });
    }
    setSoundLoading(false);
  };

  const reload = useCallback(() => {
    setSoundDetails((value) => (value ? { ...value } : undefined));
  }, []);

  useEffect(() => {
    nextSound();
  }, []);

  return { soundDetails, nextSound, soundLoading, reload };
};

export default useAnnotatorSound;
