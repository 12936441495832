import { TFieldValue } from 'components/Forms';

type tPasswordValidator = (arg: TFieldValue) => string;

const usernameValidator: tPasswordValidator = (value) => {
  if (typeof value !== 'string') {
    return 'Incorrect value';
  }
  if (!/^[a-zA-Z0-9@+-_.]*$/.test(value)) {
    return 'Username may contain only letters, numbers, and @ . + - _ characters';
  }

  if (value.length <= 3) {
    return 'Username must contain at least 4 characters';
  }

  return '';
};

export default usernameValidator;
