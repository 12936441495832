import React from 'react';
import TitledSection from 'components/TitledSection';
import Paging from 'components/FilteralbeDataList/paging';
import companiesList, { ICompaniesData } from 'api/companies/companiesGET';
import { useAreaRefresherListener } from 'hooks/useAreaRefresher';
import Icon from 'components/Icon';
import useForm from 'hooks/useForm';
import FilterableDataList, {
  DEFAULT_PAGE_SIZE,
  useFilterableDataList,
} from 'components/FilteralbeDataList/filteralbeDataList';
import CompanyRow from './CompanyRow';
import usersListStructure from './companiesList.structure';
import styles from './companiesList.module.css';
import FilterInput from 'components/FilteralbeDataList/filter';

const CompaniesList: React.FC = () => (
  <TitledSection
    title={
      <div className={styles.row}>
        <div className={`${styles.title} ${styles.row__name}`}>
          Company name
        </div>
        <div className={`${styles.title} ${styles.row__actions}`}>Actions</div>
      </div>
    }
  >
    <FilterableDataList
      name="companiesList"
      pagination
      fetchData={(url) => companiesList({ params: url })}
      className={styles.row}
    >
      <Headers />
      <List />
      <Pagination />
    </FilterableDataList>
  </TitledSection>
);

const Headers: React.FC = () => {
  const { methods } = useForm();
  return (
    <>
      {usersListStructure.map((field) => (
        <div key={field.name} className={styles.row__cell_form}>
          <FilterInput {...field} />
        </div>
      ))}
      <div className={`${styles.row__cell_form} ${styles.row__actions}`}>
        <button
          onClick={() => methods.clearForm()}
          className={`${styles.actionButton}`}
          title="clear filters"
        >
          <Icon name="remove-filters" attributes={{ width: 25, height: 25 }} />
        </button>
      </div>
    </>
  );
};

const List: React.FC = () => {
  const { data, loading, error, reload } =
    useFilterableDataList<ICompaniesData>();
  const list = data?.results;
  const total = data?.count;
  useAreaRefresherListener(reload);

  if (total === 0) return <div className={styles.content}>No data found.</div>;
  if (loading) return <div className={styles.content}>Loading...</div>;
  if (error) return <div className={styles.content}>{error}</div>;

  return (
    <ul className={styles.content} aria-label="Companies list">
      {list?.map((item) => (
        <CompanyRow key={item.name} {...item} reload={reload} />
      ))}
    </ul>
  );
};

const Pagination: React.FC = () => {
  const { data } = useFilterableDataList<ICompaniesData>();
  const total = data?.count;
  return data ? (
    <div className={styles.footer}>
      <Paging max={Math.ceil((total || 1) / DEFAULT_PAGE_SIZE)} />
    </div>
  ) : null;
};

export default CompaniesList;
