import React, { ReactNode } from 'react';
import styles from './titledSection.module.css';
import { TStylesheet } from '../../restyler';

type TComponentProps = {
  children: React.ReactNode;
  title: ReactNode;
  styles?: TStylesheet;
  className?: string;
};

const TitledSection: React.FC<TComponentProps> = (props) => {
  const { children, title, className } = props;
  return (
    <section className={className || styles.root}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.content}>{children}</div>
    </section>
  );
};

export default TitledSection;
