import React from 'react';
import TitledSection from 'components/TitledSection';
import Paging from 'components/FilteralbeDataList/paging';
import { IGroup } from 'api/groups/_types';
import groupsGET from 'api/groups/groupsGET';
import { useAreaRefresherListener } from 'hooks/useAreaRefresher';
import Icon from 'components/Icon';
import useForm from 'hooks/useForm';
import FilterableDataList, {
  DEFAULT_PAGE_SIZE,
  useFilterableDataList,
} from 'components/FilteralbeDataList/filteralbeDataList';
import GroupDetails from 'components/GroupsList/GroupDetails';
import groupsListStructure from './groupsList.structure';
import styles from './groupsList.module.css';
import FilterInput from 'components/FilteralbeDataList/filter';

const GroupsList: React.FC = () => (
  <TitledSection
    title={
      <div className={styles.row}>
        <div className={`${styles.title} ${styles.row__id}`}>ID</div>
        <div className={`${styles.title} ${styles.row__name}`}>Group name</div>
        <div className={`${styles.title} ${styles.row__actions}`}>Actions</div>
      </div>
    }
  >
    <FilterableDataList
      name="groupsList"
      pagination
      fetchData={(url) => groupsGET({ params: url })}
      className={styles.row}
    >
      <Headers />
      <List />
      <Pagination />
    </FilterableDataList>
  </TitledSection>
);

const Headers: React.FC = () => {
  const { methods } = useForm();
  return (
    <>
      <div className={`${styles.row__id} ${styles.row__cell_form}`} />
      {groupsListStructure.map(({ field, wrapperClass }) => (
        <div
          key={field.name}
          className={`${wrapperClass} ${styles.row__cell_form}`}
        >
          <FilterInput {...field} />
        </div>
      ))}
      <div className={`${styles.row__cell_form} ${styles.row__actions}`}>
        <button
          onClick={() => methods.clearForm()}
          className={`${styles.actionButton}`}
          title="clear filters"
        >
          <Icon name="remove-filters" attributes={{ width: 25, height: 25 }} />
        </button>
      </div>
    </>
  );
};

const List: React.FC = () => {
  const { data, error, loading, reload } =
    useFilterableDataList<{ count: number; results: Array<IGroup> }>();
  useAreaRefresherListener(reload);
  const list = data?.results;
  const total = data?.count;
  if (total === 0) return <div className={styles.content}>No data found.</div>;
  if (loading) return <div className={styles.content}>Loading...</div>;
  if (error) return <div className={styles.content}>{error}</div>;

  return (
    <>
      <ul className={styles.content} aria-label="Groups list">
        {list?.map((item) => (
          <ListRow key={item.id} group={item} />
        ))}
      </ul>
    </>
  );
};

const ListRow: React.FC<{ group: IGroup }> = ({ group }) => {
  const { id, name } = group;
  return (
    <li className={styles.listItem}>
      <div className={styles.row}>
        <div className={`${styles.row__id}`}>{id}</div>
        <div className={`${styles.row__name}`}>{name}</div>
        <div className={`${styles.row__actions}`}>
          <GroupDetails group={group} />
        </div>
      </div>
    </li>
  );
};

const Pagination: React.FC = () => {
  const { data } =
    useFilterableDataList<{ count: number; results: Array<IGroup> }>();
  const total = data?.count;
  return data ? (
    <div className={styles.footer}>
      <Paging max={Math.ceil((total || 1) / DEFAULT_PAGE_SIZE)} />
    </div>
  ) : null;
};
export default GroupsList;
