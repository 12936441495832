import React from 'react';
import statsUsersGet, { UserStats } from 'api/statistics/statsUsersGet';
import useUser from 'hooks/useUser';
import { hasAccess } from 'lib/security';
import FilterableDataList from 'components/FilteralbeDataList';
import UserDashboard from 'components/StatisticsDashboard/userDashboard';
import AdminDashboard from 'components/StatisticsDashboard/adminDashboard';
import useNotices from 'hooks/useNotices';

const StatisticsDashboard: React.FC = () => {
  const { role } = useUser();
  const showAggregatedStats = hasAccess(['superuser', 'staff'], role);
  const { setNotice } = useNotices();

  const fetchData = async (query: string): Promise<UserStats[]> => {
    const response = await statsUsersGet({ query });
    if (response.success) {
      return response.data;
    }
    setNotice({ type: 'failure', message: response.message });
    return [];
  };

  if (showAggregatedStats) {
    return (
      <FilterableDataList
        pagination={false}
        fetchData={fetchData}
        name="statisticsDashboard"
      >
        <AdminDashboard />
      </FilterableDataList>
    );
  }

  return (
    <FilterableDataList
      pagination={false}
      fetchData={fetchData}
      name="statisticsDashboard"
    >
      <UserDashboard />
    </FilterableDataList>
  );
};

export default StatisticsDashboard;
