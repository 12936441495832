import React, { ButtonHTMLAttributes } from 'react';
import restyle, { TStylesheet } from 'components/../restyler';
import defaultStyles from './buttonPrimary.module.css';
import cx from 'classnames';

export type TButtonProps = {
  styles?: TStylesheet;
  children?: React.ReactNode;
} & ButtonHTMLAttributes<HTMLButtonElement>;

type TButtonPrimaryComponent = React.FC<TButtonProps>;

const ButtonPrimary: TButtonPrimaryComponent = (props: TButtonProps) => {
  const {
    onClick = () => null,
    type = 'button',
    children,
    styles: overriddenStyles,
    className,
    ...rest
  } = props;

  const styles = restyle(defaultStyles, overriddenStyles);

  return (
    <button
      {...rest}
      type={type}
      className={cx(styles.root, className)}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default ButtonPrimary;
