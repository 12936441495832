import config from 'api/config';
import fetchApi from 'api/fetchApi';
import { IPackageEditableFields, IPackage } from './_types';

type tPackagesPost = (args: IPackageEditableFields) => Promise<{
  payload?: IPackage | null;
  success: boolean;
  message: string;
}>;

const packagesPost: tPackagesPost = async (args) => {
  const { success, payload, status } = await fetchApi<
    IPackage & { detail?: string }
  >({
    method: 'POST',
    path: `${config.packages}`,
    payload: args,
  });

  if (success && payload) {
    return {
      success: true,
      payload,
      message: `Package ${payload.name} created.`,
    };
  }
  if (status === 400 && payload) {
    let message = '';
    Object.entries(payload).forEach((entry) => {
      const [name, val] = entry;
      message += `${name.toUpperCase()} - ${
        val.join ? val.join('') : JSON.stringify(val)
      } `;
    });
    message = message ? `Errors! ${message}` : 'Something went wrong';
    return {
      success: false,
      message,
    };
  }
  return {
    success: false,
    message:
      typeof payload?.detail === 'string'
        ? payload.detail
        : 'Something went wrong',
  };
};

export default packagesPost;
