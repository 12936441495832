import { initialState } from './initialState';
import { TReducer } from '../../_types';
import {
  SET_NOTICE,
  CANCEL_NOTICE,
  TNoticeType,
  TRemoveNotice,
  actionSetNotice,
  actionCancelNotice,
} from '../../actions/notices';

type TActionsAvailable =
  | ReturnType<typeof actionSetNotice>
  | ReturnType<typeof actionCancelNotice>;
type TState = Array<TNoticeType>;

const reducerSetNotice: TReducer<TState, typeof SET_NOTICE, TNoticeType> = (
  state,
  action,
) => {
  const noticeToAdd = {
    ...action.payload,
    id: Date.now(),
  };

  return [...state, noticeToAdd].map((notice, id, self) => {
    if (self.length > 5 && id < self.length - 5) {
      return {
        ...notice,
        toRemoveAnimatably: true,
      };
    }
    return notice;
  });
};

const reducerCancelNotice: TReducer<
  TState,
  typeof CANCEL_NOTICE,
  TRemoveNotice
> = (state, action) =>
  state.filter((noticeInQueue) => noticeInQueue.id !== action.payload.id);

const userReducer = new Map()
  .set(CANCEL_NOTICE, reducerCancelNotice)
  .set(SET_NOTICE, reducerSetNotice);

const counterBuilder = (
  state: TState = initialState,
  action: TActionsAvailable,
): TState => {
  const reducer: TReducer<TState, typeof action.type, typeof action.payload> =
    userReducer.get(action.type);
  return typeof reducer === 'function' ? reducer(state, action) : state;
};

export default counterBuilder;
