import config from 'api/config';
import fetchApi from 'api/fetchApi';
import { IPackage } from './_types';

type tPackagesGetById = (args: {
  packageId: number;
}) => Promise<IPackage | null>;

const packagesGetById: tPackagesGetById = async (args) => {
  const { success, payload } = await fetchApi<IPackage>({
    method: 'GET',
    path: `${config.packages}${args.packageId}/`,
  });

  if (success) return payload;
  return null;
};

export default packagesGetById;
