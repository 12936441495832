import React from 'react';
import { Redirect, Route } from 'react-router';
import useUser from 'hooks/useUser';
import { hasAccessToPath } from 'lib/security';

const AuthRoute: React.FC<{
  path: string;
  component: React.FC;
}> = ({ path, component }) => {
  const { token, role } = useUser();
  if (!token) {
    return <Redirect to="/login" />;
  }

  if (!hasAccessToPath(path, role)) {
    return <Redirect to="/statistics" />;
  }

  return <Route path={path} component={component} />;
};

export default AuthRoute;
