import { TActionCreator } from '../_types';

export const SET_LOADING = 'SET_LOADING';

export const actionSetLoading: TActionCreator<typeof SET_LOADING, boolean> = (
  payload,
) => ({
  type: SET_LOADING,
  payload,
});
