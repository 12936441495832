import React from 'react';
import UserDelete from 'components/CompaniesList/CompanyDel';
import CompanyEdit from 'components/CompaniesList/CompanyEdit';
import { ICompany } from 'api/companies/companiesGET';
import styles from '../companiesList.module.css';

const ListRow: React.FC<ICompany & { reload: () => void }> = ({
  name,
  id,
  reload,
}) => (
  <li className={styles.listItem}>
    <div className={styles.row}>
      <div className={`${styles.row__name}`}>{name}</div>
      <div className={`${styles.row__actions}`}>
        <CompanyEdit name={name} id={id} reload={reload} />
        <UserDelete id={id} name={name} reload={reload} />
      </div>
    </div>
  </li>
);

export default ListRow;
