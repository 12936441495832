import React, { useEffect } from 'react';
import LoginForm from 'components/LoginForm';
import { RouteComponentProps, withRouter } from 'react-router';
import useUser from 'hooks/useUser';

const Login: React.FC<RouteComponentProps> = (props) => {
  const { token } = useUser();

  useEffect(() => {
    if (token) {
      props.history.replace('/statistics');
    }
  }, [token]);

  return <LoginForm />;
};

export default withRouter(Login);
