import jwtRefresh from 'api/accounts/jwtRefresh';
import refreshStoredUser from 'lib/userHelpers/refreshHelper';
import { handleUnauthorized } from 'api/fetchApi';

const extendSession = async () => {
  const isAuth = await jwtRefresh();
  if (!isAuth.success || !isAuth.access) {
    handleUnauthorized(isAuth.detail || '');
    return false;
  }
  const decodingSuccess = refreshStoredUser({ access: isAuth.access });
  if (!decodingSuccess) {
    handleUnauthorized('Invalid JWT token. Please log in again.');
    return false;
  }

  return true;
};

export default extendSession;
