import config from 'api/config';
import fetchApi from 'api/fetchApi';
import { IAudiodbPackage } from './_types';

type tPackagesPost = (args: {
  packageId: IAudiodbPackage['id'];
  packageData: IAudiodbPackage;
}) => Promise<SuccessResponse | ErrorResponse>;

interface SuccessResponse {
  success: true;
}

interface ErrorResponse {
  success: false;
  message: string;
}

const audiodbPackageImportIt: tPackagesPost = async (args) => {
  const urlStr = config.audiodbPackagesImportIt.replace(
    /\{(.+?)\}/g,
    () => `${args.packageId}`,
  );

  const { success, payload, status } = await fetchApi<
    IAudiodbPackage & { detail?: string }
  >({
    method: 'POST',
    path: urlStr,
    payload: args.packageData,
  });

  if (success) {
    return {
      success: true,
    };
  }
  if ((status || 400) > 399 && payload) {
    let message = '';
    Object.entries(payload).forEach((entry) => {
      const [name, val] = entry;
      message += `${name.toUpperCase()} - ${
        val.join ? val.join('') : JSON.stringify(val)
      } `;
    });
    message = message ? `Errors! ${message}` : 'Something went wrong';
    return {
      success: false,
      message,
    };
  }
  return {
    success: false,
    message:
      typeof payload?.detail === 'string'
        ? payload.detail
        : 'Something went wrong',
  };
};

export default audiodbPackageImportIt;
