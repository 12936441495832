/* eslint-disable @typescript-eslint/no-empty-function */
import { FormEvent, useMemo, useReducer } from 'react';
import {
  reducerRegisterField,
  reducerSetClear,
  reducerSetField,
  reducerSetManyFields,
  reducerStartSubmit,
  reducerStopSubmit,
  reducerUnregisterManyFields,
  setSubmit,
} from './form.reducers';
import {
  TFormComponent,
  TFormComponentProps,
  TFormContext,
  TFormReducer,
  TRegisterField,
  TSetField,
  TSetManyFields,
  TUnregisterManyFields,
} from './_types';
import {
  CLEAR_FORM,
  REGISTER_FIELD,
  SET_FIELD,
  SET_MANY_FIELDS,
  SET_SUBMIT,
  START_SUBMIT,
  STOP_SUBMIT,
  UNREGISTER_FIELDS,
} from './form.actions';
import FormContext from './form.context';
import styles from './form.module.css';

const reducer: TFormReducer = (state, action) => {
  switch (action.type) {
    case REGISTER_FIELD:
      return reducerRegisterField(state, action.payload);
    case SET_FIELD:
      return reducerSetField(state, action.payload);
    case SET_SUBMIT:
      return setSubmit(state);
    case CLEAR_FORM:
      return reducerSetClear();
    case SET_MANY_FIELDS: {
      return reducerSetManyFields(state, action.payload);
    }
    case UNREGISTER_FIELDS: {
      return reducerUnregisterManyFields(state, action.payload);
    }
    case START_SUBMIT: {
      return reducerStartSubmit(state);
    }
    case STOP_SUBMIT: {
      return reducerStopSubmit(state);
    }
    default: {
      return state;
    }
  }
};

const Form: TFormComponent = ({
  children,
  className = styles.root,
  onSubmitSuccess = () => { },
  onSubmitFailure = () => { },
  noValidateOnSubmit = false,
  ...formProps
}: TFormComponentProps) => {
  const [form, dispatchFormAction] = useReducer(reducer, reducerSetClear());

  const clearForm = () => {
    dispatchFormAction({
      type: CLEAR_FORM,
    });
  };

  const registerField: TRegisterField = ({
    name,
    value,
    touched,
    validate,
    initialValue,
  }) => {
    dispatchFormAction({
      type: REGISTER_FIELD,
      payload: {
        name,
        value,
        touched,
        validate,
        initialValue,
      },
    });
  };

  const unregisterFields: TUnregisterManyFields = (fields) => {
    dispatchFormAction({ type: UNREGISTER_FIELDS, payload: fields });
  };

  const setField: TSetField = ({ name, value }) => {
    dispatchFormAction({
      type: SET_FIELD,
      payload: {
        name,
        value,
      },
    });
  };

  const setManyFields: TSetManyFields = (fields) => {
    dispatchFormAction({
      type: SET_MANY_FIELDS,
      payload: fields,
    });
  };

  const formDataAndMethods = {
    form,
    methods: {
      registerField,
      setField,
      clearForm,
      setManyFields,
      unregisterFields,
    },
  };

  const handleOnSubmit = async (e?: FormEvent | undefined) => {
    if (form.isSubmitting) {
      return;
    }
    dispatchFormAction({
      type: START_SUBMIT,
    });
    e?.preventDefault();

    if (!noValidateOnSubmit) {
      dispatchFormAction({
        type: SET_SUBMIT,
      });
    }

    if (Object.values(form.valid).every(v => !v)) {
      await onSubmitSuccess({ ...form, methods: formDataAndMethods.methods });
    } else {
      onSubmitFailure({ ...form, methods: formDataAndMethods.methods });
    }
    dispatchFormAction({
      type: STOP_SUBMIT,
    });
  };

  const formDataAndMethodsToPass: TFormContext = useMemo(
    () => ({
      form,
      methods: {
        ...formDataAndMethods.methods,
        handleOnSubmit,
      },
    }),
    [form, dispatchFormAction],
  );

  return (
    <form {...formProps} onSubmit={handleOnSubmit} className={className}>
      <FormContext.Provider value={formDataAndMethodsToPass}>
        {typeof children === 'function'
          ? (children as (arg: TFormContext) => JSX.Element)(
            formDataAndMethodsToPass,
          )
          : children}
      </FormContext.Provider>
    </form>
  );
};

export default Form;
