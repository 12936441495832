import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CANCEL_POPUP, SET_POPUP } from 'reduxStore/actions/popups';
import { RootState } from '../reduxStore/_types';

type tUsePopups = {
  popupName: string[];
  setPopup: (arg: unknown) => void;
  cancelPopup: (arg: string) => void;
};
const usePopups: () => tUsePopups = () => {
  const popupName = useSelector((state: RootState) => state.popupName);
  const dispatch = useDispatch();

  const setPopup = (payload: unknown) => {
    dispatch({
      type: SET_POPUP,
      payload,
    });
  };

  const cancelPopup = (payload: string) => {
    dispatch({
      type: CANCEL_POPUP,
      payload,
    });
  };

  return useMemo(
    () => ({
      popupName,
      setPopup,
      cancelPopup,
    }),
    [popupName, dispatch],
  );
};

export default usePopups;
