import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetLoading } from 'reduxStore/actions/loading';
import { RootState } from '../reduxStore/_types';

interface IReturnType {
  loading: RootState['loading'];
  setLoading: (payl: boolean) => void;
}

const useLoading = (): IReturnType => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.loading);

  const setLoading = (payload: boolean) => {
    dispatch(actionSetLoading(payload));
  };

  return useMemo(
    () => ({
      loading,
      setLoading,
    }),
    [dispatch, loading],
  );
};

export default useLoading;
