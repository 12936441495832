import userStorage from 'lib/persistentStorage/storage.user';
import { actionUserLogout } from 'reduxStore/actions/user';
import { store } from 'reduxStore/store';

function logoutStoredUser(): void {
  userStorage.delItem('currentUser');
  store.dispatch(actionUserLogout());
}

export default logoutStoredUser;
