import groupsCreate from 'api/groupsCreate';
import ButtonPrimary from 'components/ButtonPrimary';
import Form, { TFormSubmitData } from 'components/Forms';
import ControlledInput from 'components/Forms/ControlledField';
import TitledSection from 'components/TitledSection';
import useLoading from 'hooks/useLoading';
import useNotices from 'hooks/useNotices';
import usePackageList from 'hooks/usePackagesList';
import useUsersList from 'hooks/useUsersList';
import React from 'react';
import useAreaRefresher from 'hooks/useAreaRefresher';
import required from 'validators/required';
import styles from './groupCreate.module.css';
import groupCreateStructure from './groupCreate.structure';

export interface ISearchParams {
  searchTerm: string;
}

const GroupCreate: React.FC<{
  multiselectListUser: Array<{ label: string; value: string }>;
  multiselectListPackages: Array<{ label: string; value: string }> | null;
  loading: boolean;
  loadingPackages: boolean;
  onSubmit: (arg: TFormSubmitData) => void;
}> = ({
  multiselectListUser,
  multiselectListPackages,
  loading,
  loadingPackages,
  onSubmit,
}) => (
  <TitledSection title="Create group">
    <Form
      onSubmitSuccess={onSubmit}
      className={styles.root}
      data-testid="createGroupForm"
    >
      {({ form }) => (
        <>
          <div className={styles.field}>
            {groupCreateStructure.map((field) => (
              <ControlledInput key={field.name} {...field} />
            ))}
          </div>
          <div className={styles.field}>
            {loading || !multiselectListUser ? (
              'Field loading...'
            ) : (
              <ControlledInput
                name="users"
                type="multiselect"
                options={multiselectListUser}
                validate={required}
                label="Select User(s)"
              />
            )}
          </div>
          <div className={styles.field}>
            {loadingPackages || !multiselectListPackages ? (
              'Field loading...'
            ) : (
              <ControlledInput
                name="packages"
                type="multiselect"
                options={multiselectListPackages}
                validate={required}
                label="Select Package(s)"
              />
            )}
          </div>
          <div className={styles.actionField}>
            <ButtonPrimary type="submit" disabled={form.isSubmitting}>
              Create group
            </ButtonPrimary>
          </div>
        </>
      )}
    </Form>
  </TitledSection>
);

const GroupCreateDataLayer: React.FC = () => {
  const { users, usersLoading } = useUsersList();
  const { packages, packagesLoading } = usePackageList();
  const forceRefresh = useAreaRefresher();

  const packageList = packages?.results;

  const loadingManager = useLoading();
  const noticesManager = useNotices();

  const multiselectListUser =
    users?.map((item) => ({
      label: item.username,
      value: `${item.id}`,
    })) || null;

  const multiselectListPackages =
    packageList?.map((item) => ({
      label: item.name,
      value: `${item.id}`,
    })) || null;

  const onSubmit = async (formDataAndMethods: TFormSubmitData) => {
    loadingManager.setLoading(true);
    const { values, methods } = formDataAndMethods;
    const { name, packages, users } = values;

    const toSend = {
      name: name as string,
      packages: packages
        ? JSON.parse(packages as string).map(
          (field: { label: string; value: string }) => +field.value,
        )
        : [],
      users: users
        ? JSON.parse(users as string).map(
          (field: { label: string; value: string }) => +field.value,
        )
        : [],
    };

    const response = await groupsCreate(toSend);
    if (response.success) {
      const { payload: group } = response;
      forceRefresh();
      methods.clearForm();
      noticesManager.setNotice({
        type: 'success',
        message: `Group created. Name: ${group.name}. Group ID: ${group.id}.`,
      });
    } else {
      noticesManager.setNotice({
        type: 'failure',
        message: response.message || 'Group not created.',
      });
    }

    loadingManager.setLoading(false);
  };
  if (!multiselectListUser) return null;

  return (
    <GroupCreate
      multiselectListUser={multiselectListUser}
      multiselectListPackages={multiselectListPackages}
      loading={usersLoading}
      loadingPackages={packagesLoading}
      onSubmit={onSubmit}
    />
  );
};

export default GroupCreateDataLayer;
