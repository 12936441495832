import fetchApi from 'api/fetchApi';
import config from 'api/config';
import { ICandidates, ICandidatesData } from 'api/ranking/_types';

const rankingCandidatesGet = async (): Promise<ICandidatesData> => {
  const response = await fetchApi<ICandidates>({
    method: 'GET',
    path: `${config.ranking}candidates/`,
  });

  const { success, payload } = response;

  return {
    success,
    payload,
  };
};

export default rankingCandidatesGet;
