import React, { useEffect, useState } from 'react';
import Checkbox from 'components/Forms/StyledCheckbox';
import Input from 'components/Forms/StyledInput';
import useElementSize from 'hooks/useElementSize';
import useDropdown from 'hooks/useDropdown';
import { FormInputProps, TFieldValue } from 'components/Forms';
import { IOption } from 'components/Forms/StyledSelect/input';
import styles from './multiselectField.module.css';

const inputSearchCss = {
  message: styles.inputSearchMessage,
  label__span: styles.inputSearchSpan,
  input: styles.inputSearch,
};

const checkboxCss = {
  wrapper: styles.checkboxWrapper,
};

export interface MultiselectProps extends FormInputProps {
  label?: string;
  value: TFieldValue;
  options: IOption[];
  name: string;
}

const MultiselectField: React.FC<MultiselectProps> = ({
  setField,
  value,
  label,
  options,
  message,
  touched,
  name,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [visibleOptions, setVisibleOptions] = useState<IOption[]>([]);

  const parsable = typeof value === 'string' && value.length ? value : '[]';

  const parsed = JSON.parse(parsable);
  const allFieldsChecked =
    options?.length === parsed.length && options?.length !== 0;

  const setAllFields = (isChecked: TFieldValue) => {
    const newValue = isChecked ? JSON.stringify(options) : '';
    setField(newValue);
  };

  useEffect(() => {
    const matchedOptions =
      searchTerm === ''
        ? options
        : options?.filter((option) =>
          option.label.toLowerCase().includes(searchTerm.toLowerCase()),
        );

    setVisibleOptions(matchedOptions || []);
  }, [options, searchTerm]);

  const setSingleField = (valueIncoming: IOption, isChecked: boolean) => {
    const hasState = !!value;
    let state = hasState ? JSON.parse(value as string) : [];
    if (isChecked) {
      state.push(valueIncoming);
    } else {
      state = state.filter(
        (item: IOption) => item.value !== valueIncoming.value,
      );
    }
    const newState = state.length ? JSON.stringify(state) : '';
    setField(newState);
  };
  const { ref, width } = useElementSize<HTMLDivElement>();
  const { ref: dropdownRef, isOpen, open } = useDropdown<HTMLDivElement>();

  return (
    <div className={styles.root} ref={ref}>
      <div className={styles.title}>{label}</div>
      <button
        name={name}
        onClick={() => !isOpen && open()}
        className={`${styles.header} ${
          touched && message ? styles.errorBorder : ''
        }`}
        type="button"
      >
        {parsed.slice(0, 3).map((item: IOption) => (
          <div
            className={styles.header__button}
            key={item.value}
            role="presentation"
            onClickCapture={(e) => {
              e.stopPropagation();
              setSingleField(item, false);
            }}
          >
            {item.label}
          </div>
        ))}
        {parsed.length > 3 ? (
          <span className={styles.header__info}>
            {`...${parsed.length - 3} more`}
          </span>
        ) : null}
      </button>
      {isOpen ? (
        <div
          className={styles.togglable}
          style={{ width: width ? `${width}px` : 'inherit' }}
          ref={dropdownRef}
          data-testid={`${name}Dropdown`}
        >
          <Checkbox
            name="all"
            setField={setAllFields}
            label="Select all"
            message=""
            title=""
            checked={allFieldsChecked}
            styles={checkboxCss}
          />
          <Input
            setField={(art: TFieldValue) => {
              setSearchTerm(`${art}`);
            }}
            value={searchTerm}
            message=""
            label=""
            type="text"
            placeholder="Search"
            name="search"
            touched={false}
            autoFocus
            styles={inputSearchCss}
          />
          <div className={styles.scrollable}>
            {visibleOptions.map((option) => {
              const isChecked = parsed.find(
                (item: IOption) => item.value === option.value,
              );
              return (
                <Checkbox
                  key={JSON.stringify(option)}
                  setField={(arg: TFieldValue) => {
                    setSingleField(option, !!arg);
                  }}
                  styles={checkboxCss}
                  name={option.label}
                  label={option.label}
                  message=""
                  title=""
                  checked={!!isChecked}
                />
              );
            })}
          </div>
        </div>
      ) : null}
      <div className={styles.errorMessage}>
        {touched && message ? message : ''}
      </div>
    </div>
  );
};

MultiselectField.defaultProps = {
  options: [],
};

export default MultiselectField;
