import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'reduxStore/_types';
import apiJwtCreate from 'api/accounts/jwtCreate';
import loginToStoreUser from 'lib/userHelpers/loginHelper';
import logoutStoredUser from 'lib/userHelpers/logoutHelper';
import { RoleConfig } from 'lib/security';
import useLoading from './useLoading';
import useNotices from './useNotices';
import logoutPost from 'api/accounts/logoutPost';

interface iReturnType {
  login: RootState['user']['data']['login'];
  userId: RootState['user']['data']['user_id'];
  token: RootState['user']['data']['token'];
  refresh: RootState['user']['data']['refresh'];
  timestamp: RootState['user']['data']['timestamp'];
  error: RootState['user']['error'];
  loading: RootState['user']['loading'];
  userLogIn: (args: { login: string; password: string }) => void;
  userLogOut: () => void;
  role: RoleConfig;
}

const useUser = (): iReturnType => {
  // user in redux
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  // helper hooks:
  const noticesManager = useNotices();
  const loadingManager = useLoading();

  const userLogIn = async (payl: { login: string; password: string }) => {
    loadingManager.setLoading(true);

    const { success, payload } = await apiJwtCreate({ ...payl });

    let type: 'failure' | 'success' = 'failure';
    let message = payload?.detail || 'Something went wrong.';

    if (success && payload?.access && payload?.refresh) {
      type = 'success';
      message = 'You are logged in!';

      const decodingSuccess = loginToStoreUser({
        access: payload.access as string,
        refresh: payload.refresh as string,
      });

      if (!decodingSuccess) {
        type = 'failure';
        message = 'Invalid JWT token. You are not logged in.';
      }
    }

    noticesManager.setNotice({ type, message });
    loadingManager.setLoading(false);
  };

  const userLogOut = () => {
    logoutPost().then((res) => {
      if (res.success) {
        noticesManager.setNotice({
          type: 'message',
          message: `${
            user.data.login ? `${user.data.login} - y` : 'Y'
          }ou are logged out.`,
        });
      }
      logoutStoredUser();
    });
  };

  return useMemo(
    () => ({
      userLogIn,
      userLogOut,
      loading: user.loading,
      error: user.error,
      login: user.data.login,
      userId: user.data.user_id,
      token: user.data.token,
      refresh: user.data.refresh,
      timestamp: user.data.timestamp,
      role: {
        isSuperuser: user.data.is_superuser,
        isStaff: user.data.is_staff,
      },
    }),
    [dispatch, user],
  );
};

export default useUser;
