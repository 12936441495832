import { useEffect } from 'react';
import styles from './annotationRegions.module.css';
import AnnotationRow from './AnnotationRow';
import AnnotationSubmitButton from './AnnotationSubmitButton';
import useForm from 'hooks/useForm';
import { useWavesurfer } from 'components/WavesurferJS/wavesurfer.context';
import {
  getFieldName,
  unusableLabel,
} from 'components/AnnotationRegions/AnnotationRow/annotationRow';
import { TFieldValue } from 'components/Forms';

const AnnotationRegions = (params: { blacklist: string[], whitelist: string[] }) => {
  const { regions, removeRegion, updateRegion, soundDetails } = useWavesurfer();
  const { methods, form } = useForm();

  useEffect(() => {
    const initialValues =
      soundDetails?.sound.annotations.reduce<
        { name: string; value: TFieldValue }[]
      >((result, item) => {
        return [
          ...result,
          { name: getFieldName(item, 'label'), value: item.label },
          {
            name: getFieldName(item, 'unusable'),
            value: item.label === unusableLabel,
          },
        ];
      }, []) || [];
    methods.clearForm();
    methods.setManyFields(initialValues);
  }, [soundDetails]);
  const { handleOnSubmit } = methods;

  return (
    <>
      {regions.length || form.values.unusableFile ? (
        <>
          {form.values.unusableFile ? (
            <div className={styles.unusable__file__info}>Unusable file</div>
          ) : null}
          <ul className={styles.regions__list}>
            <li className={styles.regions__item}>
              <div className={styles.regions__range}>
                <div className={styles.regions__rangeStart}>Start</div>
                <div className={styles.regions__rangeEnd}>End</div>
              </div>
              <div className={styles.regions__annotation}>Annotation</div>
              <div className={styles.regions__actions}>Actions</div>
            </li>
            {regions.map((region) => (
              <AnnotationRow
                key={region.id}
                region={region}
                updateRegion={updateRegion}
                removeRegion={removeRegion}
                blacklist={params.blacklist}
                whitelist={params.whitelist}
              />
            ))}
          </ul>
          <div className={styles.finishbar}>
            <AnnotationSubmitButton handleOnSubmit={handleOnSubmit} />
          </div>
        </>
      ) : (
        <div className={styles.regions__item}>
          Add regions or mark file as unusable
        </div>
      )}
    </>
  );
};

export default AnnotationRegions;
