import fetchApi from 'api/fetchApi';
import config from 'api/config';

export interface UserStats {
  user: number;
  company: number | null;
  username: string;
  annotated_count: number;
  validated_count: number;
  unusable_count: number;
  annotated_time: number;
  validated_time: number;
  unusable_time: number;
}
interface SuccessResponse {
  success: true;
  data: UserStats[];
}

interface ErrorResponse {
  success: false;
  message: string;
}

interface ActionParams {
  query: string;
}

const rankingCandidatesGet = async ({
  query,
}: ActionParams): Promise<SuccessResponse | ErrorResponse> => {
  const response = await fetchApi<UserStats[]>({
    method: 'GET',
    path: `${config.statsUsers}?${query}`,
  });

  const { payload, success } = response;

  if (success && payload) {
    return {
      success: true,
      data: payload,
    };
  }

  return {
    success: false,
    message: 'Failed to fetch statistics',
  };
};

export default rankingCandidatesGet;
