import React, { useCallback } from 'react';
import Form, { TFormSubmitData } from 'components/Forms';
import ButtonPrimary from 'components/ButtonPrimary';
import TitledSection from 'components/TitledSection';
import ControlledInput from 'components/Forms/ControlledField';
import companyCreate, { ICompany } from 'api/companies/companiesPOST';
import useNotices from 'hooks/useNotices';
import useLoading from 'hooks/useLoading';
import useAreaRefresher from 'hooks/useAreaRefresher';
import createUserStructure from './createCompany.structure';
import styles from './createCompany.module.css';

const CreateUser: React.FC = () => {
  const notices = useNotices();
  const loading = useLoading();
  const forceRefresh = useAreaRefresher();

  const onSubmit = async (formData: TFormSubmitData) => {
    const { values, methods } = formData;
    loading.setLoading(true);
    const response = await companyCreate(values as unknown as ICompany);
    if (response.success) {
      forceRefresh();
      notices.setNotice({
        type: 'success',
        message: `Company created${
          response.company?.name ? `: ${response.company.name}` : '.'
        }`,
      });
      methods.clearForm();
    } else {
      notices.setNotice({
        type: 'failure',
        message: response.message || 'Something went wrong.',
      });
    }
    loading.setLoading(false);
  };

  const onSubmitFailure = () => {
    notices.setNotice({
      type: 'failure',
      message: 'Form is not filled correctly.',
    });
  };

  return (
    <TitledSection title="Create company">
      <Form
        data-testid="createCompanyForm"
        onSubmitFailure={useCallback(onSubmitFailure, [])}
        onSubmitSuccess={useCallback(onSubmit, [])}
        className={styles.form}
      >
        {({ form }) => (
          <>
            <div className={styles.formContent}>
              {createUserStructure.map((field) => (
                <ControlledInput key={field.name} {...field} />
              ))}
            </div>
            <div className={styles.submit}>
              <ButtonPrimary type="submit" disabled={form.isSubmitting}>
                Create company
              </ButtonPrimary>
            </div>
          </>
        )}
      </Form>
    </TitledSection>
  );
};

export default CreateUser;
