import { TActionCreator } from '../_types';

export const SET_POPUP = 'SET_POPUP';
export const CANCEL_POPUP = 'CANCEL_POPUP';

export const actionSetPopup: TActionCreator<typeof SET_POPUP, string> = (
  payload,
) => ({
  type: SET_POPUP,
  payload,
});

export const actionCancelPopup: TActionCreator<typeof CANCEL_POPUP, string> = (
  payload,
) => ({
  type: CANCEL_POPUP,
  payload,
});
