import React from 'react';
import { IPackage } from 'api/packages/_types';
import ButtonPrimary from 'components/ButtonPrimary';
import PopupWrapper, { PopupButton } from 'components/Popups';
import ButtonSecondary from 'components/ButtonSecondary';
import Icon from 'components/Icon';
import usePackages from 'hooks/usePackages';
import styles from '../packagesManage.module.css';

const actionIconAttributes = {
  width: 25,
  height: 25,
};

const PackagesRow: React.FC<
  IPackage & {
    reload: () => void;
  }
> = ({ name, id, reload }) => {
  const { deletePackage, deleted } = usePackages();
  const { loading, data } = deleted;

  return loading || data ? null : (
    <>
      <PopupButton
        title="Delete package"
        className={styles.popup__open}
        unique={`remove-package-${id}`}
        open
      >
        <Icon name="trash" attributes={actionIconAttributes} />
      </PopupButton>
      <PopupWrapper unique={`remove-package-${id}`}>
        {({ cancelPopup }) => (
          <div className={styles.popup}>
            <div className={styles.popup__message}>
              You are about to remove this package:
            </div>
            <div className={styles.popup__name}>{name}</div>
            <div className={styles.popup__actions}>
              <ButtonPrimary
                onClick={async () => {
                  await deletePackage(id);
                  cancelPopup();
                  reload();
                }}
              >
                Proceed
              </ButtonPrimary>
              <ButtonSecondary onClick={cancelPopup}>Cancel</ButtonSecondary>
            </div>
          </div>
        )}
      </PopupWrapper>
    </>
  );
};

export default PackagesRow;
