import config from 'api/config';
import fetchApi from 'api/fetchApi';

export interface AnnotatePayload {
  sound: {
    annotations: unknown[];
    frequencies: unknown | null;
  };
  settings: {
    can_annotate: boolean;
    can_be_accepted: boolean;
    can_be_modified: boolean;
    can_delete: boolean;
    disable_resize: boolean;
    entries: unknown[];
    limit_resize: boolean;
    title: string;
    type: 'last_validation' | 'annotated' | 'unusable';
  };
  sound_entry_id: string;
  sound_entry_file: string;
}

type LabelsSuggestions = string[];

type Response =
  | {
      success: true;
      data: LabelsSuggestions;
    }
  | {
      success: false;
      message: string;
    };

const labelsSuggestionsGet = async (label: string): Promise<Response> => {
  try {
    const response = await fetchApi<LabelsSuggestions & { detail?: string }>({
      method: 'GET',
      path: `${config.labelsSuggestions}?label=${label}`,
    });
    if (response.success && response.payload) {
      return {
        success: true,
        data: response.payload,
      };
    } else {
      return {
        success: false,
        message: response.payload?.detail || 'Failed to fetch suggestions.',
      };
    }
  } catch (e) {
    return {
      success: false,
      message: 'Failed to fetch suggestions.',
    };
  }
};

export default labelsSuggestionsGet;
