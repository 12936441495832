import { initialState } from './initialState';
import { TReducer } from '../../_types';
import {
  SET_POPUP,
  CANCEL_POPUP,
  actionSetPopup,
  actionCancelPopup,
} from '../../actions/popups';

type TActionsAvailable =
  | ReturnType<typeof actionSetPopup>
  | ReturnType<typeof actionCancelPopup>;
type TState = string[];

const reducerSetPopup: TReducer<TState, typeof SET_POPUP, string> = (
  state,
  action,
) => {
  const isIncludedAlready = state.includes(action.payload);
  if (!isIncludedAlready) return [...state, action.payload];
  return state;
};

const reducerCancelPopup: TReducer<TState, typeof CANCEL_POPUP, string> = (
  state,
  action,
) => [...state].filter((item) => item !== action.payload);

const userReducer = new Map()
  .set(CANCEL_POPUP, reducerCancelPopup)
  .set(SET_POPUP, reducerSetPopup);

const reducerPopups = (
  state: TState = initialState,
  action: TActionsAvailable,
): TState => {
  const reducer: TReducer<TState, typeof action.type, typeof action.payload> =
    userReducer.get(action.type);
  return typeof reducer === 'function' ? reducer(state, action) : state;
};

export default reducerPopups;
