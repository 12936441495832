import config from 'api/config';
import fetchApi from 'api/fetchApi';

type TLogoutPOSTReturnType =
  | {
      success: true;
    }
  | {
      success: false;
      message: string;
    };

const logoutPost = async (): Promise<TLogoutPOSTReturnType> => {
  const response = await fetchApi<{ detail?: string }>({
    method: 'POST',
    path: `${config.accountsLogout}`,
    payload: {},
  });

  const { success, payload } = response;

  if (success) {
    return {
      success: true,
    };
  }
  return {
    success: false,
    message:
      typeof payload?.detail === 'string'
        ? payload.detail
        : 'Something went wrong',
  };
};

export default logoutPost;
