import styles from './usersList.module.css';
import { ControlledInputProps } from 'components/Forms/ControlledField/input';

interface UsersListStructureInterface {
  wrapperClass: string;
  field: ControlledInputProps;
}

const usersListStructure: UsersListStructureInterface[] = [
  {
    wrapperClass: styles.row__user,
    field: {
      name: 'username',
      type: 'text',
      placeholder: 'Username',
    },
  },
  {
    wrapperClass: styles.row__email,
    field: {
      name: 'email',
      type: 'text',
      placeholder: 'Email',
    },
  },
  {
    wrapperClass: styles.row__company,
    field: {
      name: 'company',
      type: 'hidden',
    },
  },
  {
    wrapperClass: styles.row__role,
    field: {
      name: 'is_staff',
      type: 'select',
      options: [
        { value: '', label: '' },
        { value: 'true', label: 'Staff' },
        { value: 'false', label: 'No staff' },
      ],
    },
  },
  {
    wrapperClass: styles.row__role,
    field: {
      name: 'is_superuser',
      type: 'select',
      options: [
        { value: '', label: '' },
        { value: 'true', label: 'Admin' },
        { value: 'false', label: 'No admin' },
      ],
    },
  },
  {
    wrapperClass: styles.row__status,
    field: {
      name: 'is_active',
      type: 'select',
      options: [
        { value: '', label: '' },
        { value: 'false', label: 'Inactive' },
        { value: 'true', label: 'Active' },
      ],
    },
  },
];

export default usersListStructure;
