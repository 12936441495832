import { TFieldValue } from 'components/Forms';
import { ControlledInputProps } from 'components/Forms/ControlledField/input';

const loginFormStructure: ControlledInputProps[] = [
  {
    name: 'login',
    validate: (str: TFieldValue): string =>
      str && (str as string).length > 3 ? '' : 'Login too short',
    type: 'text',
    placeholder: 'Username',
    label: 'Username',
  },
  {
    name: 'password',
    validate: (str: TFieldValue): string =>
      str && (str as string).length > 3 ? '' : 'Password too short',
    type: 'password',
    placeholder: 'Password',
    label: 'Password',
  },
];

export default loginFormStructure;
