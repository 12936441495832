type tEmailValidator = (
  arg: string | undefined | number | boolean | null
) => string;

const emailValidator: tEmailValidator = (fieldValueOrEmptyString) => {
  if (!fieldValueOrEmptyString) {
    return 'This field cannot be empty.';
  }

  if (typeof fieldValueOrEmptyString !== 'string') {
    return 'This field has to be a valid email address';
  }

  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      fieldValueOrEmptyString,
    )
  ) {
    return '';
  }

  return 'Correct email address has form: anything@domain.com';
};

export default emailValidator;
