import React from 'react';
import { IUser } from 'api/accounts/usersGet';
import UserDelete from 'components/UsersList/UserDel';
import UserEdit from 'components/UsersList/UserEdit';
import useCompaniesOptionsFromContext from 'hooks/useCompaniesOptionsFromContext';
import useCompanyName from 'hooks/useCompanyName';
import styles from '../usersList.module.css';

const ListRow: React.FC<IUser & { reload: () => void }> = ({
  id,
  username,
  email,
  company,
  is_superuser,
  is_staff,
  is_active,
  reload,
}) => {
  const { options: companyOptions } = useCompaniesOptionsFromContext();
  const companyName = useCompanyName(companyOptions, company);

  return (
    <li className={styles.listItem}>
      <div className={styles.row}>
        <div className={`${styles.row__id}`}>{id}</div>
        <div className={`${styles.row__user}`}>{username}</div>
        <div className={`${styles.row__email}`}>{email}</div>
        <div className={`${styles.row__company}`}>{companyName || '-'}</div>
        <div className={`${styles.row__role}`}>{is_staff ? 'staff' : '-'}</div>
        <div className={`${styles.row__role}`}>
          {is_superuser ? 'admin' : '-'}
        </div>
        <div className={`${styles.row__status}`}>
          {is_active ? 'active' : 'inactive'}
        </div>
        <div className={`${styles.row__actions}`}>
          <UserEdit
            id={id}
            username={username}
            email={email}
            is_staff={is_staff}
            is_superuser={is_superuser}
            is_active={is_active}
            company={company}
            reload={reload}
          />
          <UserDelete id={id} username={username} reload={reload} />
        </div>
      </div>
    </li>
  );
};

export default ListRow;
