import fetchApi from 'api/fetchApi';
import { ISoundFile } from 'api/ranking/_types';

const soundFileGet = async (soundId: number): Promise<ISoundFile | null> => {
  const response = await fetchApi<ISoundFile>({
    method: 'GET',
    path: `packages/annotation/${soundId}/`,
  });

  return response.payload;
};

export default soundFileGet;
