import React from 'react';
import { Sector } from 'recharts';

type tPayl = {
  cx: number;
  cy: number;
  midAngle: number;
  outerRadius: number;
  innerRadius: number;
  startAngle: number;
  endAngle: number;
  percent: number;
  index: number;
  value: number;
  fill: string;
  payload: { name: string };
};

const RenderActiveShape: React.FC<tPayl> = ({
  cx,
  cy,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
  fill,
  payload,
  percent,
  value,
}) => (
  <g>
    <text x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>
      {payload.name}
    </text>
    <text x={cx} y={cy} dy={15} textAnchor="middle" fill="#999" fontSize={12}>
      {`${value} (${(percent * 100).toFixed(2)}%)`}
    </text>
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius + 5}
      startAngle={startAngle}
      endAngle={endAngle}
      fill={fill}
    />
    <Sector
      cx={cx}
      cy={cy}
      startAngle={startAngle}
      endAngle={endAngle}
      innerRadius={outerRadius + 10}
      outerRadius={outerRadius + 15}
      fill={fill}
    />
  </g>
);

export default RenderActiveShape;
