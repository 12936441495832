import React, { ButtonHTMLAttributes, useContext } from 'react';
import styles from './tabs.module.css';
import { changeTabsContext, tabsContext } from './tabsContext';

interface iProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  id: string;
  title: string;
}

const Button: React.FC<iProps> = (props) => {
  const { id, title, ...restButtonProps } = props;
  const state = useContext(tabsContext);
  const setState = useContext(changeTabsContext);
  if (setState === undefined) {
    throw Error('called Tab Button outside Tabs');
  }

  const className =
    state === id ? styles.button_active : styles.button_inactive;

  return (
    <button
      {...restButtonProps}
      className={className}
      onClick={() => {
        setState(id);
      }}
    >
      {title}
    </button>
  );
};

export default Button;
