import ButtonTertiary from 'components/ButtonTertiary';
import { TFormContext } from 'components/Forms';
import ControlledInput from 'components/Forms/ControlledField';
import formContext from 'components/Forms/form.context';
import TitledSection from 'components/TitledSection';
import React, { useContext } from 'react';
import useUser from 'hooks/useUser';
import { hasAccess } from 'lib/security';
import styles from './statisticsForm.module.css';
import { calculateResetDate } from './statisticsForm.structure';

const ResetDate = () => {
  const formDateAndMethods = useContext(formContext);

  const onClickHandler = () => {
    (formDateAndMethods as TFormContext).methods.setManyFields([
      { name: 'start_date', value: calculateResetDate()[0] },
      { name: 'end_date', value: calculateResetDate()[1] },
    ]);
  };

  return (
    <div className={styles.button}>
      <ButtonTertiary onClick={onClickHandler}>Reset dates</ButtonTertiary>
    </div>
  );
};

const StatisticsForm: React.FC = () => {
  const { role } = useUser();
  return (
    <TitledSection title="Filters" className={styles.wrapper}>
      <div className={styles.root}>
        <div className={styles.dates_wrapper}>
          <div className={styles.field_date}>
            <ControlledInput
              name="start_date"
              initialValue={calculateResetDate()[0]}
              type="date"
              label="Date from"
            />
          </div>
          <div className={styles.field_date}>
            <ControlledInput
              name="end_date"
              initialValue={calculateResetDate()[1]}
              type="date"
              label="Date to"
            />
          </div>
          <ResetDate />
        </div>
        <div>
          {hasAccess(['superuser'], role) ? (
            <div className={styles.field_select}>
              <ControlledInput
                name="company"
                type="companies_select"
                label="Company"
              />
            </div>
          ) : null}
        </div>
      </div>
    </TitledSection>
  );
};

export default StatisticsForm;
